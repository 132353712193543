import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Box, InputLabel, useTheme } from '@mui/material';

export default function TimePickerComponent({label,name,value,onChange}) {
  const theme=useTheme()
  const [values, setValue] = React.useState(value ? dayjs(value) : dayjs() );
  React.useEffect(()=>{
    onChange(name,values.format('YYYY-MM-DD HH:mm'));
  },[values])

  return (
    <Box sx={{ mt: 2, width: '100%' }}>
          <InputLabel
        sx={{  fontWeight: 'bold', fontSize: '15px', color: theme?.details?.formLabelColor, mt: 1 }}
        htmlFor={name}
      >
        {label}
      </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
      <DemoContainer components={['TimePicker', 'TimePicker']}>
        <TimePicker
        //   label={label}
          name={name}
          value={values}
          onChange={(newValue) => setValue(newValue)}
          fullWidth
        />
      </DemoContainer>
    </LocalizationProvider>

    </Box>

  );
}
