import React, { useEffect, useState } from "react";
import { Box, FormControl, FormControlLabel, InputLabel, Radio, RadioGroup, useTheme } from "@mui/material";

function RadioComponent({ field, setField }) {
    const theme=useTheme()
  // State to hold the selected radio value
  const [selectedValue, setSelectedValue] = useState("");

  // Initialize selected radio based on "[C]" and update field data
  useEffect(() => {
    const initialSelected = field?.fieldsLanguages[2]?.data
      ?.split("\n")
      .find((item) => item.includes("[C]"))
      ?.replace("[C]", ""); // Remove "[C]" for display

    if (initialSelected) {
      setSelectedValue(initialSelected);
      setField(field?.fieldsLanguages[0]?.data, initialSelected);
    }
  }, [field, setField]);

  // Handle radio change
  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    setField(field?.fieldsLanguages[0]?.data, value);
  };

  return (
    <Box sx={{ mt: 1 }}>
      <InputLabel
        sx={{
          fontWeight: "bold",
          fontSize: "15px",
          color: theme?.details?.formLabelColor,
        }}
      >
        {field?.fieldsLanguages[0]?.data}
      </InputLabel>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name={field?.fieldsLanguages[0]?.data}
          value={selectedValue}
          onChange={handleRadioChange}
        >
          {field?.fieldsLanguages[2]?.data
            ?.split("\n")
            ?.map((item, index) => {
              const displayItem = item.replace("[C]", "");

              return (
                <FormControlLabel
                  key={index}
                  value={displayItem}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: selectedValue === displayItem ? theme?.details?.formLabelColor : "#969BA0",
                        "&.Mui-checked": {
                          color: theme?.details?.formRadioColor,
                        },
                      }}
                    />
                  }
                  label={
                    <span
                      style={{
                        color:theme?.details?.formLabelColor,
                        fontSize: "16px",
                      }}
                    >
                      {displayItem}
                    </span>
                  }
                />
              );
            })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default RadioComponent;
