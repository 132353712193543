import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import success from "../../assets/sucessEvent.png";
import backicon from "../../assets/backwidget.png";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useNavigate } from 'react-router-dom';

const PaymentSuccessEvent = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      textAlign: 'center',
      p: { xs: 1, sm: 2, md: 3 }, // Responsive padding
    }}>
      {/* Centered Content */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1, // Center vertically in the available space
      }}>
        <Box sx={{
          width: { xs: '200px', sm: '250px', md: '300px' }, // Responsive width
          height: { xs: '150px', sm: '200px', md: '250px' }, // Responsive height
          mb: 2, // Margin bottom for spacing
        }}>
          <img src={success} alt="success-img" style={{ width: '100%', height: '100%' }} />
        </Box>
        <Typography sx={{
          color: "#92FC8B",
          fontSize: { xs: '20px', sm: '28px', md: '36px' }, // More responsive scaling
          fontWeight: '600', // Use numeric value for semiBold
          mb: 1,
        }}>
          Your payment is successful
        </Typography>
        <Typography sx={{
          color: "#969BA0",
          fontSize: { xs: '10px', sm: '11px', md: '12px' }, // Responsive font size
          mb: 3,
        }}>
          Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. Molestie mattis adipiscing sit condimentum.
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' }, // Stack text on smaller screens
          gap: '1rem',
          alignItems: 'center',
        }}>
          <Typography sx={{
            color: "#000000",
            fontSize: { xs: '14px', sm: '16px' }, // Responsive font size
            fontWeight: '500', // Medium weight
          }}>
            Your Reservation Number is
          </Typography>
          <Typography sx={{
            color: "#92FC8B",
            fontSize: { xs: '28px', sm: '36px', md: '40px' }, // Responsive font size
            fontWeight: '500',
          }}>
            1234567
          </Typography>
        </Box>
      </Box>

      {/* Buttons Section at the Bottom */}
      <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'column', md: 'row' }, // Responsive flex direction
        justifyContent: 'space-between',
        position: 'fixed', // Fixed at the bottom
        alignItems: 'center',
        bottom: 0,
        left: 0,
        right: 0,
        p: { xs: 1, sm: 2 }, // Responsive padding
      
        boxShadow: 1, // Optional shadow for better separation from content above
      }}
    >
      <Button
        variant="contained"
        onClick={() => navigate(-1)}
        sx={{
          backgroundColor: "#969BA0",
          color: "white",
          fontSize: { xs: '10px', sm: '12px' }, // Responsive font size
          display: 'flex',
          alignItems: 'center',
          flexGrow: { xs: 1, md: 0 }, // Allow button to grow on small screens
          mb: { xs: 1, md: 0 }, // Margin bottom for smaller screens
        }}
      >
        <img src={backicon} alt="back-img" style={{ marginRight: '8px' }} />
        Previous
      </Button>

      <Button
        startIcon={<FileDownloadIcon />}
        sx={{
          backgroundColor: "#1A615E",
          color: "white",
          fontSize: { xs: '10px', sm: '12px' }, // Responsive font size
          flexGrow: { xs: 1, md: 0 }, // Allow button to grow on small screens
          mb: { xs: 1, md: 0 }, // Margin bottom for smaller screens
        }}
        onClick={() => navigate('/paymentStatusfail')}
      >
        Download Invoice
      </Button>

      <Button
        sx={{
          backgroundColor: "#F37120",
          color: "white",
          fontSize: { xs: '10px', sm: '12px' }, // Responsive font size
          flexGrow: { xs: 1, md: 0 }, // Allow button to grow on small screens
          mb: { xs: 1, md: 0 }, // Margin bottom for smaller screens
        }}
      >
        New Reservation
      </Button>
    </Box>
    </Box>
  );
}

export default PaymentSuccessEvent;

