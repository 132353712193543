import { Box, Button, Card, Typography } from '@mui/material';
import React from 'react';
import pending from "../../assets/pending.png";
import backicon from "../../assets/backwidget.png";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PaymentPending = () => {
  const{key,resType,widgetSetupId}=useSelector((store)=>store?.widgetSettings)
    const navigate = useNavigate();
    const{languageWords}=useSelector((store)=>store?.addGuest)
    const send = (frameHeight, frameWidth, top, right, bottom, left, floating, redirectUrl) => {
        window.parent.postMessage({
            reservationIframeHeight: frameHeight,
            reservationIframeWidth: frameWidth,
            reservationIframePositionTop: top,
            reservationIframePositionRight: right,
            reservationIframePositionBottom: bottom,
            reservationIframePositionLeft: left,
            reservationIframePositionFloating: floating,
            redirectUrl: redirectUrl
        }, '*');
    };
    //if(!widgetSetupId){
    //  return <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',height:"100vh",color:"grey",fontSize:'2rem'}}>Dont have access to this page.</Box>
    //}
  
    return (
      <Box sx={{
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'space-between',
        // alignItems: 'center',
        // minHeight: '90vh',
        // backgroundColor: '#fff', // Light background color
        // border:"1pxx solid lightgrey",
        // m:3
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        backgroundColor: 'white',
        p: { xs: 1, sm: 2, md: 3 }, // Responsive padding
      }}


      >
        {/* Main content */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}>
          <Box sx={{
            width: { xs: '60px', sm: '100px', md: '100px' }, // Responsive width
            height: { xs: '60px', sm: '100px', md: '100px' }, // Responsive height
            mb: 2,
          }}>
            <img src={pending} alt="failed-img" style={{ width: '100%', height: '100%' }} />
          </Box>
  
          <Typography sx={{
            color: "#F37120",
            fontSize: { xs: '24px', sm: '30px', md: '36px' }, // Responsive font size
            fontWeight: 600,
            textAlign: 'center',
            mb: 1,
          }}>
           {languageWords?.PaymentPendingMessage}
          </Typography>
  
          <Typography sx={{
            color: "#969BA0",
            fontSize: { xs: '12px', sm: '14px', md: '16px' }, // Responsive font size
            textAlign: 'center',
            maxWidth: '600px', // Limit text width for better readability
            mb: 3,
          }}>
             {languageWords?.PaymentPendingInfo}
          </Typography>

        </Box>
  
        {/* Bottom buttons */}
        <Box sx={{
          display: 'flex',
          justifyContent: { xs: 'center', sm: 'space-between' },
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          width: '100%',
          maxWidth: '1000px', // Keep buttons aligned within the main container's width
          mb: 2, // Margin from the bottom of the viewport
          px: 3,
        }}>
          <Button
            variant="contained"
                    onClick={() => send("0px", "0px", "", "0px", "0px", "", "fixed", "/")}
            sx={{
              backgroundColor: "#969BA0",
              color: "white",
              fontSize: "12px",
              display: 'flex',
              alignItems: 'center',
              mb: { xs: 2, sm: 0 }, // Margin for vertical stacking on small screens
            }}
          >
            <img src={backicon} alt="back-img" style={{ marginRight: '8px' }} />
           {languageWords?.GoBack}
          </Button>
  
          <Button
            sx={{
              backgroundColor: "#F37120",
              color: "white",
              fontSize: "12px",
            }}
            
          >
            {languageWords?.StartAgain}
          </Button>
        </Box>
      </Box>
    );
};

export default  PaymentPending;

