import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Grid2 from "@mui/material/Grid2";
import TextInput from "../../components/Input/TextInput";
import CountrySelect from "../../components/SelectComponent/SelectCountry";
import { MuiPhoneComponent } from "../../components/Input/MuiPhoneComponent";
import YourBookingCard from "./YourBookingCard";
import PaymentCard from "./PaymentCard";
import { getGuestDetails } from "../../api/HorecaWidget.service";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddFields,
  setGuestDetailsData,
  setGuestFields,
  setReservationLang,
} from "../../store/slice/Addguest.slice";
import dayjs from 'dayjs';
import { Formik, Form, Field } from "formik";
import TextAreaComponent from "../../components/Input/TextAreaComponent";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
import FormGroup from "@mui/material/FormGroup";

import Checkbox from "@mui/material/Checkbox";
import DatePickerComponent from "../../components/SelectComponent/DatePicker";
import TimePickerComponent from "../../components/SelectComponent/TimePickerComponent";
import CheckboxListComponent from "../../components/SelectComponent/CheckBoxListComponent";
import RadioComponent from "../../components/SelectComponent/RadioComponent";
import { Link, useNavigate } from "react-router-dom";
import NumberInputComponent from "../../components/Input/NumberInput";

const YourDetails = ({
  values,
  handleChange,
  errors,
  touched,
  setFieldTouched,
  setField,
}) => {
 const navigate=useNavigate()
  const theme=useTheme()
  const dispatch = useDispatch();
  const { WidgetSetting, key, defaultLanguage } = useSelector(
    (store) => store?.widgetSettings
  );
  const {
    reservType,
    GuestDetailsData,
    guestFields,
    addFields,
    reservationLang,
    languageWords,
    extraFieldsData,

  } = useSelector((store) => store.addGuest);
  useEffect(() => {
    const guestFields = GuestDetailsData?.map((field) => ({
      idfield: field?.tag,
      data: values[field?.guestLanguages[0]?.data] || "", // Get the corresponding form value or empty string
    }));

    const updatedFields = extraFieldsData?.map((field, i) => {
      let fieldValue = values[field?.type === 'link' ? 'Link' :  field?.type == 'textarea' ? field?.fieldsLanguages[0]?.data?.replace(".", "") : field?.fieldsLanguages[0]?.data] || "";
    
      // Format time and date based on the field type
      if (field.type === 'time' && fieldValue) {
        fieldValue = dayjs(fieldValue).format('HH:mm:ss'); // Format to hh:mm:ss
      } else if (field.type === 'date' && fieldValue) {
        fieldValue = dayjs(fieldValue).format('DD-MM-YYYY'); // Format to dd-mm-yyyy
      }
    
      return {
        idfields: field?.idfields,
        name: field?.fieldsLanguages[0]?.data,
        data: fieldValue ,
      };
    });
    dispatch(setAddFields(updatedFields));
    dispatch(setGuestFields(guestFields));
  }, [values]);

  // useEffect(() => {
  //   // Save the current location before navigating away
  //   const saveCurrentPage = () => {
  //     sessionStorage.setItem("lastVisitedPage", window.location.pathname);
  //   };

  //   window.addEventListener("beforeunload", saveCurrentPage);

  //   // Check for a previously saved page in session storage
  //   const lastVisitedPage = sessionStorage.getItem("lastVisitedPage");
  //   if (lastVisitedPage && lastVisitedPage !== "/") {
  //     navigate(lastVisitedPage); // Redirect to the last visited page
  //     sessionStorage.removeItem("lastVisitedPage"); // Clear the session storage after redirection
  //   }

  //   return () => {
  //     window.removeEventListener("beforeunload", saveCurrentPage);
  //   };
  // }, [navigate]);
  
  return (
    <Form >
      <Box sx={{ mt: 5, p: 2 }}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ md: 8, sm: 12, xs: 12 }}>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: theme?.details?.formHeaderColor,
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {languageWords["ContactData"]}
              </Typography>
            </Box>
            {GuestDetailsData?.map((field, i) => {
              switch (field?.tag) {
                case "{salutation}":
                  return (

                    <Box sx={{ mt: 1 }}>
                    <InputLabel
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: theme?.details?.formLabelColor,
                      }}
                    >
                      {field?.guestLanguages[0]?.data}
                    </InputLabel>
                    <FormControl>
                    <RadioGroup
  row
  aria-labelledby="demo-row-radio-buttons-group-label"
  name={field?.guestLanguages[0]?.data}
  value={values[field?.guestLanguages[0]?.data]}
  onChange={(e) =>
    setField(field?.guestLanguages[0]?.data, e.target.value)
  }
>
  {field?.guestLanguages[1]?.data
    ?.replace(/\\"/g, '') // Remove escaped double quotes
    ?.split("\n") // Split by newline characters
    ?.map(item => item.trim()) // Trim each item to remove extra spaces
    ?.filter(item => item !== "") // Filter out any empty strings
    ?.map((item) => (
      <FormControlLabel
        key={item} // Ensure each radio button has a unique key
        value={item}
        control={
          <Radio
            size="small"
            sx={{
              "&.Mui-checked": {
                color: theme?.palette?.themeColor, // Style when checked
              },
            }}
          />
        }
        label={
          <span
            style={{
              color: "#969BA0", // Conditional styling for label
              fontSize: "16px",
            }}
          >
            {item}
          </span>
        }
      />
    ))}
</RadioGroup>


                    </FormControl>
                  </Box>
                  );
                case "{land}":
                  return (
                    <Box sx={{ mt: 2 }} key={i}>
                      <CountrySelect
                        style={{ width: "100%" }}
                        label={field?.guestLanguages[0]?.data}
                        name={field?.guestLanguages[0]?.data}
                        onChange={setField}
                        value={values[field?.guestLanguages[0]?.data]}
                      />
                    </Box>
                  );

                case "date":
                  return (
                    <Grid2 size={{ xs: 12, md: 6 }} key={i} mt={1}>
                      <DatePickerComponent
                        label={field?.info}
                        name={field?.guestLanguages[0]?.data}

                        // value={field?.guestLanguages[0]?.data}
                      />
                 
                    </Grid2>
                  );

                case "{email}":
                  return (
                    <Grid2 size={{ xs: 12 }} key={i} mt={1}>
                      <TextInput
                        label={field?.guestLanguages[0]?.data}
                        name={field?.guestLanguages[0]?.data}
                        value={values[field?.guestLanguages[0]?.data]}
                        // onChange={handleChange}
                        onChange={setField}
                        style={{width:"100%"}}
                        error={
                          errors[field?.guestLanguages[0]?.data] 
                        } // Only show error when touched
                        helperText={
                          touched[field?.guestLanguages[0]?.data] &&
                          errors[field?.guestLanguages[0]?.data]
                        } // Show error message if field is touched and there is an error
                      />
                      {/* {errors[field?.guestLanguages[0]?.data] && (
                        <FormHelperText
                          sx={{ color: "red", fontSize: "8px" }}
                        >{`${field?.guestLanguages[0]?.data} is ${
                          errors[field?.guestLanguages[0]?.data]
                        }!`}</FormHelperText>
                      )} */}
                    </Grid2>
                  );
                case "{phone}":
                  return (
                    <Box sx={{ mt: 2 }} key={i}>
                      <MuiPhoneComponent
                        style={{ width: "100%" }}
                        label={field?.guestLanguages[0]?.data}
                        name={field?.guestLanguages[0]?.data}
                        value={values[field?.guestLanguages[0]?.data]}
                        onChange={
                          setField // Update the value in Formik
                        }
                        error={
                          errors[field?.guestLanguages[0]?.data]
                         
                        } // Show error only if field is touched
                        helperText={
                          touched[field?.guestLanguages[0]?.data] &&
                          errors[field?.guestLanguages[0]?.data]
                        } // Show helperText on error
                      />
                      {/* {errors[field?.guestLanguages[0]?.data] && (
                        <FormHelperText sx={{ color: "red", fontSize: "8px" }}>
                          {`${field?.guestLanguages[0]?.data} is ${
                            errors[field?.guestLanguages[0]?.data]
                          }!`}
                        </FormHelperText>
                      )} */}
                    </Box>
                  );
                default:
                  return (
                    <Grid2 size={{ xs: 12}} key={i} mt={1}>
                      <TextInput
                        label={field?.guestLanguages[0]?.data}
                        name={field?.guestLanguages[0]?.data}
                        value={values[field?.guestLanguages[0]?.data]}
                        onChange={setField}
                        error={
                          errors[field?.guestLanguages[0]?.data] 
                       } // Only show error when touched
                        helperText={
                          touched[field?.guestLanguages[0]?.data] &&
                          errors[field?.guestLanguages[0]?.data]
                        } // Show error message if field is touched and there is an error
                      />
                      {/* {errors[field?.guestLanguages[0]?.data] && (
                        <FormHelperText
                          sx={{ color: "red", fontSize: "8px" }}
                        >{`${field?.guestLanguages[0]?.data} is ${
                          errors[field?.guestLanguages[0]?.data]
                        }!`}</FormHelperText>
                      )} */}
                    </Grid2>
                  );
              }
            })}
            {extraFieldsData?.length > 0 && (
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: theme?.details?.formHeaderColor,
                    fontSize: "20px",
                    fontWeight: "bold",
                    mt: 2,
                    mb:2
                  }}
                >
                  {languageWords["ExtraInformation"]}
                </Typography>
                {extraFieldsData?.map((field, i) => {
                  switch (field?.type) {
                    case "textarea":
                      return (
                        <Grid2 size={{ xs: 12, md: 12 }} key={i}>
                          <TextAreaComponent
                            label={field?.fieldsLanguages[0]?.data}
                            name={field?.fieldsLanguages[0]?.data?.replace(".", "")}
                            setField={setField}
                            value={values[field?.fieldsLanguages[0]?.data]}
                          />
                        </Grid2>
                      );
                    case 'number':
                      return (
                        <Grid2 size={{ xs: 12, md: 12 }} key={i}>
                          <NumberInputComponent
                            label={field?.fieldsLanguages[0]?.data}
                            name={field?.fieldsLanguages[0]?.data}
                            setField={setField}
                            value={values[field?.fieldsLanguages[0]?.data] || 0} // Default value to 0 if undefined
                          />
                        </Grid2>
                      );


                    case "date":
                      return (
                        <Grid2 size={{ xs: 12, md: 12 }} key={i} mt={1}>
                          <DatePickerComponent
                            label={field?.fieldsLanguages[0]?.data}
                            name={field?.fieldsLanguages[0]?.data}
                            value={values[field?.fieldsLanguages[0]?.data]}
                            onChange={setField}
                            // value={field?.guestLanguages[0]?.data}
                          />
                        </Grid2>
                      );
                    case 'time':
                      return(
                        <Grid2 sm={12} md={12} xs={12}>
                          <TimePickerComponent
                            label={field?.fieldsLanguages[0]?.data}
                            name={field?.fieldsLanguages[0]?.data}
                            value={values[field?.fieldsLanguages[0]?.data]}
                            onChange={setField}
                            // value={field?.guestLanguages[0]?.data}
                          />
                        </Grid2>

                      )
                    case "tel":
                      return (
                        <Grid2 size={{ xs: 12, md: 12,mt:2,mb:2 }}>
                          <MuiPhoneComponent
                            style={{ width: "100%" }}
                            label={field?.fieldsLanguages[0]?.data}
                            name={field?.fieldsLanguages[0]?.data}
                            value={values[field?.fieldsLanguages[0]?.data]}
                            onChange={
                              setField // Update the value in Formik
                            }
                            helperText={
                              touched[field?.fieldsLanguages[0]?.data] &&
                              errors[field?.fieldsLanguages[0]?.data]
                            } // Show helperText on error
                          />
                          {errors[field?.fieldsLanguages[0]?.data] && (
                            <FormHelperText
                              sx={{ color: "red", fontSize: "8px" }}
                            >
                              {`${field?.fieldsLanguages[0]?.data} is ${
                                errors[field?.fieldsLanguages[0]?.data]
                              }!`}
                            </FormHelperText>
                          )}
                        </Grid2>
                      );
                    case "radio":
                      return (
                       <RadioComponent  field={field} setField={setField}/>
                      );
                    case 'select':
                      return(
                        <Grid2 size={{md:12,sm:12}} sx={{mt:2}} key={i}>
                                    <InputLabel
                              sx={{
                                fontWeight: "bold",
                                fontSize: "15px",
                                color: theme?.details?.formLabelColor,
                                mb:1
                              }}
                            >
                              {field?.fieldsLanguages[0]?.data}
                            </InputLabel>
                          <FormControl sx={{width:"100%"}}>
                  
                            <Select
                              native
                              label={field?.fieldsLanguages[0]?.data}
                              value={values[field?.fieldsLanguages[0]?.data]}
                              onChange={(e) =>
                                setField(
                                  field?.fieldsLanguages[0]?.data,
                                  e.target.value
                                )
                              }
                            >
                              {field?.fieldsLanguages[2]?.data
                               ?.split("\n")
                               ?.map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid2>
                      )
                    case 'termsandcondition':
                      return(
                        <Box sx={{ mt: 2 }} key={i}>
                          <FormControlLabel
                            control={
                              <Checkbox size="small" checked={values[field?.fieldsLanguages[0]?.data]} onChange={(e) => setField(field?.fieldsLanguages[0]?.data, e.target.checked)} 
                              sx={{
                              
                                '&.Mui-checked': {
                                  color: theme?.details?.formCheckBoxColor, // Checked color
                                }
                              }}
                              />
                            }
                            label={
                              <a style={{
                                color: theme?.details?.formLinkColor,
                          '&:hover': {
                            color: theme?.details?.formLinkColorHover,
                          },
                              }}
                              target="_blank" rel="noopener noreferrer"
                               href={field?.options}>{ field?.fieldsLanguages[0]?.data}
                              </a>
                             
                              
                            }
                          />
                        </Box>
                     
                      )
                    case 'checkbox':
                      return(
                        <Box sx={{ mt: 2 }} key={i}>
                          <FormControlLabel
                            control={
                              <Checkbox  size="small" 
                              checked={values[field?.fieldsLanguages[0]?.data]} 
                              onChange={(e) => setField(field?.fieldsLanguages[0]?.data, e.target.checked)} 
                              sx={{
                                color: "gray", // Unchecked color
                                '&.Mui-checked': {
                                  color: theme?.details?.formCheckBoxColor, // Checked color
                                }
                              }}
                              />
                            }
                            label={
                              field?.fieldsLanguages[0]?.data
                              
                            }
                        
                          />
                        </Box>
                     
                      )
                    case 'file':
                    case 'checkboxList':
                      return(
                      //   <Grid2 size={{md:12}}key={i} sx={{mt:2}}>
                      //   <InputLabel
                      //     sx={{
                      //       fontWeight: "bold",
                      //       fontSize: "15px",
                      //       color: "#000000",
                      //       mb:1
                      //     }}
                      //   >
                      //     {field?.fieldsLanguages[0]?.data}
                      //   </InputLabel>
                      //   <FormControl component="fieldset">
                      //     <FormGroup
                      //       name={field?.fieldsLanguages[0]?.data}
                      //       value={field?.fieldsLanguages[0]?.data}
                      //       onChange={(e) => setField(field?.fieldsLanguages[0]?.data, e.target.value)}
                      //     >
                      //       {field?.fieldsLanguages[2]?.data
                      //         ?.split("\n")
                      //         ?.map((item, index) =>
                      //         {
                      //           const isChecked=item?.includes("[C]")
                      //           const displayItem = item.replace(" [C]", "");
                      //           return  (
                      //             <FormControlLabel
                      //               key={index}
                      //               control={<Checkbox size="small" checked={isChecked}/>}
                      //               label={
                      //                 <span
                      //                   style={{
                      //                     color: "#969BA0", // Conditional styling for label
                      //                     fontSize: "16px",
                      //                   }}
                      //                 >
                      //                   {displayItem}
                      //                 </span>
                      //               }
                      //             />
                      //           )

                      //         } 
                            
                      //         )}
                      //     </FormGroup>
                      //   </FormControl>
                      // </Grid2>
                      <CheckboxListComponent field={field} setField={setField} style={{
                        '&.Mui-checked': {
                          color: theme?.details?.formCheckBoxColor, // Checked color
                        }
                      }}/>
                      
                        
                      )
                     case 'link':
                      return(
                    //     <Grid2 size={{ xs: 12, md: 12 }} key={i} mt={1}>
                    //     <TextInput
                    //   label={field?.fieldsLanguages[0]?.data}
                    //   name={'Link'}
                    //   value={values['Link']}
                    //   onChange={setField}
                    //   error={
                    //     errors['Link']
                    //   } // Only show error when touched
                    //   helperText={
                    //     touched['Link'] &&
                    //     errors['Link']
                    //   } // Show error message if field is touched and there is an error
                    // />
                    // {/* {errors['Link'] && (
                    //   <FormHelperText
                    //     sx={{ color: "red", fontSize: "8px" }}
                    //   >{`${'Link'} is ${
                    //     errors['Link']
                    //   }!`}</FormHelperText>
                    // )} */}
                    //   </Grid2>
                    <Box sx={{mt:2}}>
                      <a
                        href={field?.fieldsLanguages[0]?.data}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          
                          color: theme?.details?.formLinkColor,
                          '&:hover': {
                            color: theme?.details?.formLinkColorHover,
                          },
                          fontSize: "15px",
                          fontWeight: "bold",
                          mt: 2,
                        }}
                      >
                        {field?.fieldsLanguages[0]?.data}
                      </a>
                    </Box>

                      )
                   
                    default:
                      return (
                        <Grid2 size={{ xs: 12, md: 12 }} key={i} mt={1}>
                          <TextInput
                        label={field?.fieldsLanguages[0]?.data}
                        name={field?.fieldsLanguages[0]?.data}
                        value={values[field?.fieldsLanguages[0]?.data]}
                        onChange={setField}
                        error={
                          errors[field?.fieldsLanguages[0]?.data] 
                        } // Only show error when touched
                        helperText={
                          touched[field?.fieldsLanguages[0]?.data] &&
                          errors[field?.fieldsLanguages[0]?.data]
                        } // Show error message if field is touched and there is an error
                      />
                      {/* {errors[field?.fieldsLanguages[0]?.data] && (
                        <FormHelperText
                          sx={{ color: "red", fontSize: "8px" }}
                        >{`${field?.fieldsLanguages[0]?.data} is ${
                          errors[field?.fieldsLanguages[0]?.data]
                        }!`}</FormHelperText>
                      )} */}
                        </Grid2>
                      );
                  }
                })}
              </Box>
            )}
          </Grid2>

          <Grid2 size={{ md: 4, sm: 12, xs: 12 }}>
            <Box>
              <YourBookingCard />
              {reservType?.totalCost > 0 && <PaymentCard />}
            </Box>
          </Grid2>
        </Grid2>

        {/* Submit button is handled on the parent stepper */}
      </Box>
    </Form>
  );
};

export default YourDetails;
