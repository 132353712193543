import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Popover,
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from '@mui/material';
import { updateReservationType } from '../../store/slice/Addguest.slice';

const DateComponent = ({ Ref, isOpen, handleClose, isLoading }) => {
  const { reservType } = useSelector((store) => store?.addGuest);
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState(null); // To store the selected date

  const handleDateSelect = (date) => {
    setSelectedDate(date); // Set the selected date
    dispatch(updateReservationType(date)); // Dispatch the action if needed
  };

  const dates = ['October 23, 2024', 'October 24, 2024', 'October 25, 2024'];

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ width: 400, bgcolor: 'background.paper' }}>
        <Typography variant="body2" sx={{ p: 2, textAlign: 'center' }}>
          Select a Date
        </Typography>
        <Divider />
        <nav aria-label="select date">
          <List>
            {dates.map((date) => (
              <ListItem key={date} disablePadding>
                <ListItemButton
                  onClick={() => handleDateSelect(date)}
                  sx={{
                    bgcolor: selectedDate === date ? 'lightgreen' : 'transparent', // Highlight selected date
                   
                    borderRadius: '4px',
                    color:selectedDate === date ? '#fff' : 'black'
                  }}
                >
                  <ListItemText primary={date} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>
    </Popover>
  );
};

export default DateComponent;
