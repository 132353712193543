import React, { useState } from 'react';
import { Box, IconButton, Typography, Grid, Popover } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import dayjs from 'dayjs';
import 'dayjs/locale/en';  // Ensure locale is available for formatting
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedDate } from '../../store/slice/Addguest.slice';
import { useTranslation } from 'react-i18next';

const Calendar = ({ isOpen, handleClose, Ref }) => {
  const { t } = useTranslation();
  const { blockedDates } = useSelector((store) => store?.widgetSettings);
  const { selectedDates } = blockedDates ?? '';
  const today = dayjs(); // Get the current date
  const [currentDate, setCurrentDate] = useState(today); // State to track the current month
  const [selectedDate, setSelectedDate] = useState(null); // State to track the selected date
  const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const daysInMonth = currentDate.daysInMonth();
  const firstDayOfMonth = currentDate.startOf('month').day(); // Day of the week

  const dispatch = useDispatch();

  // Check if the date is a past date
  const isPastDay = (day) => currentDate.date(day).isBefore(today, 'day');
  // Check if the date is blocked (from selectedDates)
  const isBlockedDate = (day) => selectedDates?.includes(currentDate.date(day).format('YYYY-MM-DD'));
  // Check if the date is today
  const isToday = (day) => today.isSame(currentDate.date(day), 'day');
  // Check if the date is selected
  const isSelected = (day) => selectedDate && selectedDate.isSame(currentDate.date(day), 'day');

  const handlePrevMonth = () => {
    setCurrentDate(currentDate.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setCurrentDate(currentDate.add(1, 'month'));
  };

  const handleDateSelect = (day) => {
    if (!isPastDay(day)) {
      const newSelectedDate = currentDate.date(day);
      setSelectedDate(newSelectedDate); // Set the selected date
      const formattedDate = newSelectedDate.format('DD-MM-YYYY');
      dispatch(updateSelectedDate(formattedDate)); // Dispatch selected date
      handleClose();
    }
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ width: { md: '430px', sm: "100%", xs: "100%" }, mx: { sm: "1rem" }, textAlign: 'center', backgroundColor: "#fff", px: 2, paddingBottom: 2 }}>
        {/* Header with arrows and year/month */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
          <IconButton onClick={handlePrevMonth}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {currentDate.format('YYYY')}
          </Typography>
          <IconButton onClick={handleNextMonth}>
            <ArrowForward />
          </IconButton>
        </Box>

        {/* Display Year and Month */}
        <Typography sx={{ marginBottom: 1, color: "#FFCC00", fontSize: "24px", fontWeight: 'bold' }}>
          {t(`months.${currentDate.format('MMMM')}`)}
        </Typography>

        {/* Days of the week */}
        <Grid container spacing={1} mt={3}>
          {daysOfWeek.map((day, index) => (
            <Grid item xs={1.71} key={index}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{day}</Typography>
            </Grid>
          ))}
        </Grid>

        {/* Dates Grid */}
        <Grid container spacing={1} sx={{ marginTop: 1 }}>
          {/* Empty cells for days before the first day of the month */}
          {[...Array(firstDayOfMonth)].map((_, index) => (
            <Grid item xs={1.71} key={index} />
          ))}

          {/* Render all days of the month */}
          {[...Array(daysInMonth)].map((_, dayIndex) => {
            const day = dayIndex + 1;
            const isPast = isPastDay(day);
            const isSelectedDay = isSelected(day);
            const isActive = !isPast;

            return (
              <Grid item xs={1.71} key={dayIndex}>
                <Box
                  onClick={() => isActive && handleDateSelect(day)} // Only allow selection if active (not past)
                  sx={{
                    border: '2px dotted',
                    borderColor: isPast ? '#969BA0' : '#FFCC00',
                    color: isSelectedDay ? 'white' : isPast ? '#969BA0' : '#3E4954',
                    backgroundColor: isSelectedDay ? '#FFCC00' : isPast ? '#FDFDFD' : '#FFFDF5',
                    cursor: isActive ? 'pointer' : 'default',
                    borderRadius: '8px',
                    padding: 1,
                    textAlign: 'center',
                    transition: 'background-color 0.2s ease',
                    width: '40px',
                    height: '40px',
                    fontWeight: isActive ? 'bold' : 'normal',
                    lineHeight: '1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="body2" sx={{ color: isSelectedDay ? "#fff" : isActive ? '#3E4954' : "#969BA0", fontSize: "22px" }}>
                    {day}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Popover>
  );
};

export default Calendar;
