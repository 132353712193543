import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

const ReservationCancelResponse = () => {
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm')); // Check if the screen is small or extra-small

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", height: "100vh" }}>
            <Paper
                elevation={1}
                sx={{
                    backgroundColor: "#fff",
                    m: 2,
                    width: isSmallScreen ? '90%' : '80%', // Full width on small screens
                    display: 'flex',
                    justifyContent: 'center',
                    height: '80vh',
                    padding: 3, // Add padding for better spacing
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <CheckCircleIcon sx={{ width: 150, height: 150, color: '#1A615E' }} />
                    <Typography 
                        sx={{ 
                            color: "#1A615E", 
                            fontWeight: 'semiBold', 
                            fontSize: { xs: '24px', sm: '30px', md: '36px' }, // Responsive font sizes
                            lineHeight: '2rem',
                            mt: 3,
                            mb: 1 
                        }}
                    >
                       Reservation Cancelled Sucessfully
                    </Typography>
                    <Box sx={{ mt: 4 }}>
                        <Button variant="contained" color="primary" size="large" onClick={() => navigate(-1)}>
                            Back to Home
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

export default ReservationCancelResponse;