import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';

const PaymentCard = () => {
    const theme=useTheme()
    const {totalCount,selectedDate,reservType,time,reservGroup,adult,child,kid,baby,toddler,reservationGroupData,selectedExtraProduct,transactionDetails,languageWords
    } = useSelector((store) => store.addGuest);
    const{widgetConfig}=useSelector((store) => store?.widgetSettings)
    console.log("config",widgetConfig)
    const VAT_PERCENT_PRODUCTS=reservType?.vat;
    const VAT_TOTAL_PRODUCTS=(reservType?.totalCost-(reservType?.totalCost/(1+(VAT_PERCENT_PRODUCTS/100))));

    const VAT_TOTAL_EXTRA=selectedExtraProduct?.reduce((acc,product)=>{
      return acc+ ((product?.price-(product?.price/(1+(product?.vat/100)))))
    },0);
    const VAT_TOTAL_TRANSACTION_COST=((transactionDetails?.pricePerReservation-(transactionDetails?.pricePerReservation/(1+(transactionDetails?.vat/100))))*1)
    const TOTAL_VAT=(VAT_TOTAL_PRODUCTS+VAT_TOTAL_EXTRA+VAT_TOTAL_TRANSACTION_COST).toFixed(2);
    const totalSum = selectedExtraProduct?.reduce((acc, product) => {
      return acc + (product.quantity * product.price);
  }, 0);
  const TOTAL_INCLUSIVE_VAT=reservType?.totalCost + totalSum + transactionDetails?.pricePerReservation
  return (
    <Box sx={{
        width:"100%",
    minHeight:"206px",
    border: `1px dashed ${theme?.details?.detailsBorderColor}`,
    backgroundColor: theme?.details?.detailsBgColor,
    borderRadius: "16px",
    mt:1,
    p: 2,}}
    >
        <Typography sx={{ color: theme?.details?.detailsTransactionColor, fontWeight: "medium", fontSize: {md:"24px",sm:"20px",xs:'16px'} }}>{languageWords?.Payment
}</Typography>
        <Box>
        {transactionDetails?.pricePerReservation > 0 && <Box sx={{display:"flex",justifyContent:"space-between",alignItems:'center',mt:2,mb:2}}>
                <Typography sx={{color:theme?.details?.detailsTransactionColor,fontSize:'14px',fontWeight:"600"}}>{transactionDetails?.languages[0]?.data}</Typography>
                  <Typography sx={{ color:theme?.details?.detailsPriceColor, fontSize: '14px', fontWeight: "600" }}>€{parseFloat(transactionDetails?.pricePerReservation).toFixed(2)}</Typography>
            </Box>}
            {/* <Box sx={{display:"flex",justifyContent:"space-between",alignItems:'center',mt:2,mb:2}}>
                <Typography sx={{color:"#000000",fontSize:'14px',fontWeight:"600"}}>{languageWords?.Total}</Typography>
                  <Typography sx={{ color: "#000000", fontSize: '14px', fontWeight: "600" }}>€{parseFloat((reservType?.totalCost) + totalSum + (transactionDetails ? transactionDetails?.pricePerReservation :0) ).toFixed(2)}</Typography>
            </Box> */}
            {TOTAL_VAT > 0 && <Box  sx={{display:"flex",justifyContent:"space-between",alignItems:'center',mb:2}}>
            <Typography sx={{color:theme?.details?.detailsVatColor,fontSize:'14px',fontWeight:"600"}}>{languageWords?.VAT} </Typography>
                  <Typography sx={{ color: theme?.details?.detailsPriceColor, fontSize: '14px', fontWeight: "600" }}>€{parseFloat(TOTAL_VAT).toFixed(2)}</Typography>
            </Box>}
            {TOTAL_INCLUSIVE_VAT > 0 && !isNaN(TOTAL_INCLUSIVE_VAT) && <Box sx={{borderTop:'1px solid #969BA0',display:'flex',justifyContent:'space-between',pt:2}}>
                <Typography sx={{fontSize:'18px',color:theme?.details?.detailsTotalColor,fontWeight:"medium"}}>{languageWords['TotalIncludingVAT']}</Typography>
                  {/* <Typography sx={{ fontSize: '18px', color: theme?.palette?.themeColor, fontWeight: "medium" }}>€{parseFloat((reservType?.totalCost) + totalSum + (transactionDetails ? transactionDetails?.pricePerReservation :0) +  (reservType ? totalVatPercent : 0)).toFixed(2) || 0}</Typography> */}
                  <Typography sx={{ color:theme?.details?.detailsPriceColor, fontSize: '14px', fontWeight: "600" }}>€{parseFloat(TOTAL_INCLUSIVE_VAT).toFixed(2)}</Typography>
            </Box>}
        </Box>

       
      </Box>
  )
}

export default PaymentCard
