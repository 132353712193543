import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, InputLabel, useTheme } from '@mui/material';

export default function DatePickerComponent({ value, label, name, onChange, error, helperText }) {
  const theme=useTheme()
  const [values, setValues] = React.useState(value ? dayjs(value) : dayjs()); // Initialize with dayjs if value exists, else set current date

  React.useEffect(() => {
    if (values && dayjs.isDayjs(values)) { // Only update if values is a valid dayjs object
      onChange(name, values);
    }
  }, [values]);

  return (
    <Box sx={{ mt: 1, width: '100%' }}>
      <InputLabel
        sx={{ mb: 2, fontWeight: 'bold', fontSize: '15px', color: theme?.details?.formLabelColor, mt: 1 }}
        htmlFor={name}
      >
        {label}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            name={name}
            value={values}
            onChange={(newValue) => {
              setValues(newValue ? dayjs(newValue) : null); // Convert to dayjs if newValue is valid
            }}
            fullWidth
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
}
