import React, { useEffect, useState } from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, useTheme } from "@mui/material";

function CheckboxListComponent({ field, setField,style }) {
  const theme=useTheme()
  // State to hold selected items
  const [selectedItems, setSelectedItems] = useState([]);

  // Initialize pre-checked items on mount based on "[C]"
  useEffect(() => {
    const initialSelectedItems = field?.fieldsLanguages[2]?.data
      ?.split("\n")
      .filter((item) => item.includes("[C]"))
      .map((item) => item.replace(" [C]", "")); // Remove "[C]" for display
    setSelectedItems(initialSelectedItems || []);

    // Update the external field data with initial selected items
    setField(field?.fieldsLanguages[0]?.data, initialSelectedItems || []);
  }, [field, setField]);

  // Handle checkbox change
  const handleCheckboxChange = (item) => {
    const displayItem = item.replace(" [C]", "");
    const updatedSelectedItems = selectedItems.includes(displayItem)
      ? selectedItems.filter((selected) => selected !== displayItem)
      : [...selectedItems, displayItem];

    setSelectedItems(updatedSelectedItems);
    setField(field?.fieldsLanguages[0]?.data, updatedSelectedItems);
  };

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12}>
        <InputLabel
          sx={{
            fontWeight: "bold",
            fontSize: "15px",
            color: theme?.details?.formLabelColor,
            mb: 1,
          }}
        >
          {field?.fieldsLanguages[0]?.data}
        </InputLabel>
        <FormControl component="fieldset">
          <FormGroup>
            {field?.fieldsLanguages[2]?.data
              ?.split("\n")
              ?.map((item, index) => {
                const isChecked = selectedItems.includes(item.replace(" [C]", ""));
                const displayItem = item.replace("[C]", "");

                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        size="small"
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(item)}
                        sx={style}
                      />
                    }
                    label={
                      <span
                        style={{
                          color: theme?.details?.formLabelColor,
                          fontSize: "16px",
                        }}
                      >
                        {displayItem}
                      </span>
                    }
                  />
                );
              })}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default CheckboxListComponent;
