import { Box, Popover, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFreeTablesData, updateTime } from "../../store/slice/Addguest.slice";
import { getFreeTables } from "../../api/HorecaWidget.service";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const TimeComponent = ({ isOpen, handleClose, Ref }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { reservType, selectedDate, totalCount, defaultLanguage, time } = useSelector((store) => store?.addGuest);
  const { WidgetSetting, key } = useSelector((store) => store?.widgetSettings);
  const widgetMinTimeBeforeArrival = WidgetSetting?.find((item) => item?.tag == 'widgetMinTimeBeforeArrival');
  const ReservationReqMin = WidgetSetting?.find((item) => item?.tag == 'reservationRequest');

  const [selectedTime, setSelectedTime] = useState('');
   const freeTablesData = [
    { id: 1, time: '12:00:00' },
    { id: 2, time: '13:00:00' },
    { id: 3, time: '14:00:00' },
    { id: 4, time: '15:00:00' },
    { id: 5, time: '16:00:00' },
    { id: 6, time: '17:00:00' }
  ];

  const getTimeDetails = async () => {
    setIsLoading(true);
    const parsedDate = dayjs(selectedDate, 'DD-MM-YYYY');
    const formattedDate = parsedDate?.format('YYYY-MM-DD');
    const payload = {
      idreservationtype: parseInt(reservType.idreservationtype) ?? 0,
      duration: parseInt(reservType?.duration) ?? 0,
      durationgroups: parseInt(reservType?.durationgroups) ?? 0,
      bookableupto: (reservType && reservType?.bookableupto?.HasValue && reservType?.bookableupto > 0) ? parseInt(reservType?.bookableupto.Value) : parseInt(reservType.duration) ?? 0,
      date: formattedDate,
      totalpersons: parseInt(totalCount),
      key: key,
      addTime: parseInt(widgetMinTimeBeforeArrival?.data),
      lang: defaultLanguage,
      reservationRequestMin: !(reservType && reservType?.bookableupto?.HasValue && reservType?.bookableupto > 0) ? parseInt(ReservationReqMin?.data) : 0
    };

    try {
      const response = await getFreeTables(payload);
      dispatch(setFreeTablesData(response));
      setIsLoading(false);
    } catch (error) {
      console.log("error fetching get time details:", error?.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(reservType).length > 0) {
      getTimeDetails();
    }
  }, [reservType]);

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
          <Box sx={{ width: "484px", height: '602px', backgroundColor: '#FFFFFF', p: 2 }} className="times">
        <Box sx={{ display: 'flex', justifyContent: "flex-start", alignItems: 'center', my: 2 }}>
          <Typography sx={{ color: '#000000', fontSize: "14px", fontWeight: 'bold' }}>{t('main.Stime')}</Typography>
        </Box>

        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: 'wrap',
          gap: "1rem",
        }}>
          {isLoading ? (
            // Render Skeletons while loading
            Array.from(new Array(5)).map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: "137px",
                  height: '51px',
                  border: "1px solid #C9C9C9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                }}
              >
                <Skeleton variant="text" width={80} height={30} />
              </Box>
            ))
          ) : (
            // Render actual time options
            freeTablesData?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  width: "137px",
                  height: '51px',
                  border: "1px solid ",
                  borderColor: time == item?.time ? "#DE3287" : "#C9C9C9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  borderRadius: "5px",
                  backgroundColor: time == item?.time ? "#DE3287" : "transparent"
                }}
                onClick={() => {
                  dispatch(updateTime(item?.time));
                  handleClose();
                }}
              >
                    <Typography sx={{ color: time == item?.time ? "#FEF5D8" : "#9F9F9F" }}>{item?.time.substring(0, 5)}</Typography>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </Popover>
  );
};

export default TimeComponent;

