import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  feedBackDetails: null,
  addFields:[],
  isAllowPost:true,
  postAnoumous:false,
  basicLanguages:{}

};
export const addGuestSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    updateFeedbackDetails: (state, action) => {
      state.feedBackDetails = action.payload;
    },
    setAddFields: (state, action) => {
        state.addFields = action.payload;
    },
    setAllowPost: (state, action) => {
      state.isAllowPost = action.payload;
    },
    setPostAnoumous: (state, action) => {
      state.postAnoumous = action.payload;
    },
    setBasicLanguages: (state, action) => {
        state.basicLanguages = action.payload;
    },
    // Add more reducer functions as needed...
  },
});

// Action creators are generated for each case reducer function
export const {
    updateFeedbackDetails,
    setAddFields,
    setAllowPost,
    setPostAnoumous,
    setBasicLanguages
} = addGuestSlice.actions;

export default addGuestSlice.reducer;