import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Paymentfailure from './Paymentfailure';
import PaymentSuccessActivity from '../ActivitiesWidget/PaymentSuccessActivity';


const ConditionalRedirect = () => {
  const location = useLocation();  // Get the current location (URL)
  const navigate = useNavigate();  // Use the navigate hook for programmatic navigation

    const queryParams = new URLSearchParams(location.search);

    const secretkey = queryParams.get('secretkey');
    const key = queryParams.get('key');

    if (secretkey || key) {
        return <PaymentSuccessActivity />
       } // Redirect to PaymentSuccessActivity
   
       else {
       return(
        <></>
       )
      }
};

export default ConditionalRedirect;
