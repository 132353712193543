import { Box, Popover, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { useState } from "react";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from "dayjs";
import TodayIcon from '@mui/icons-material/Today';

const DateComponent = ({ isOpen, handleClose, Ref }) => {
  const [selectedTime, setSelectedTime] = useState('');

  // Example data with table times
  const freeTablesData = [
    { id: 1, time: '30-09-24 to 30-12-24' },
    { id: 2, time: '1-10-24 to 30-12-24' },
    { id: 3, time: '5-10-24 to 30-12-24' },

  ];

  // Format time using dayjs (if needed)
  const formatTime = (timeString) => dayjs(timeString, 'HH:mm:ss').format('HH:mm'); // Format to "HH:mm"

  // Handle selecting a time
  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    handleClose(); // Close the popover after selection (optional)
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ width: 200, padding: 0 }}> {/* Remove padding here */}
        {/* Time List */}
        <List sx={{ padding: 0 }}> {/* Remove padding for List */}
          {freeTablesData?.map((table) => (
            <ListItem
              key={table.id}
              button
              onClick={() => handleTimeSelect(table.time)}  // Handle time selection
              selected={selectedTime === table.time}        // Highlight selected time
              sx={{
                color: selectedTime === table.time ? '#13B8B6' : 'grey',   // Change text color based on selection
                borderLeft: selectedTime === table.time ? '1px solid #13B8B6' : 'none',
                borderRight: selectedTime === table.time ? '1px solid #13B8B6' : 'none',
                borderBottom: '1px solid lightgrey',                           // Add grey bottom border
                padding: '10px 0',   
                                                    // Adjust padding for ListItem
                display: 'flex',                                             // Use flexbox
                justifyContent: 'center',                                   // Center horizontally
                alignItems: 'center',                                       // Center vertically
                '&:hover': { color: '#13B8B6' },                             // Hover state for all items
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ListItemIcon sx={{ minWidth: '0', mr: 1 }}>
                  <TodayIcon sx={{ color: selectedTime === table.time ? '#13B8B6' : 'grey' }} />
                </ListItemIcon>
                <ListItemText  secondary={table.time} />
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </Popover>
  );
};

export default DateComponent;
