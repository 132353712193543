import { Box, Typography, Grid } from '@mui/material';
import React from 'react';
import QuestionAnswerComponent from './QuestionAnswerComponent';
import TextAreaComponent from '../../components/Input/TextAreaComponent';
import { useSelector } from 'react-redux';
import { setAddFields } from '../../store/slice/feedback.slice';

const FeedbackDetails = (...props) => {
  const {feedBackDetails,addFields,basicLanguages}=useSelector((store)=>store?.feedback)

  return (
    <Box sx={{ padding: { xs: 2, md: 4 },width:"100%" }}> {/* Responsive padding */}
      <Box sx={{ mb: 4 }}> {/* Margin bottom for spacing */}
        <Typography sx={{ color: "grey", fontSize: { xs: "24px", md: "28px" }, fontWeight: "medium" }}>
         {basicLanguages?.HeaderSurvey}
        </Typography>
        <Typography sx={{ color: "#969BA0", fontSize: { xs: "14px", md: "15px" }, fontWeight: "regular", mt: 2 }}>
         {basicLanguages['lerem ipsum']}
        </Typography>
      </Box>
      {
        feedBackDetails?.enqueteList == 0 || !feedBackDetails?.enqueteList ?
         <Box>
           <Typography sx={{ color: "grey", fontSize: { xs: "14px", md: "15px" }, fontWeight: "regular", mt: 2,textAlign:"center" }}>
             No feedback sections were found in the survey.
           </Typography>
         </Box>
         :
         <Grid container spacing={2} sx={{ width: "100%" }}> {/* Use Grid for responsiveness */}
         {feedBackDetails?.enqueteList?.map((item, index) => ( // Dynamic rendering of feedback sections
           <Grid item xs={12} key={index}> {/* Full width on extra-small screens */}
             <Grid 
               container 
               spacing={2} 
               alignItems="stretch" 
               sx={{ width: "100%" }} // Allow components to stretch properly
             >
               <Grid 
                 item 
                 xs={12} 
                 md={6} 
                 sx={{ display: "flex", flexDirection: "column" }}
               >
                 <QuestionAnswerComponent
                   data={item}
                   style={{ flexGrow: 1 }} // Ensure it grows to fill the available space
                 />
               </Grid>
               
               <Grid 
                 item 
                 xs={12} 
                 md={6} 
                 sx={{ display: "flex", flexDirection: "column",mt:2}}
               >
                 <TextAreaComponent
                   style={{ flexGrow: 1,height:"200px" }} // Ensure it grows to fill the available space
                   placeText="Type here ..."
                   name={item?.name?.replace(/\s+/g, '')}
                   setField={setAddFields}
                   required={item?.required}
                 />
               </Grid>
             </Grid>
           </Grid>
         ))}
       </Grid>
       
      }
    
    </Box>
  );
};

export default FeedbackDetails;

