import 'react-international-phone/style.css';
import './textinput.css';
import { Box, InputAdornment, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useCallback } from 'react';
import { defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';


const languageToCountryMap = {
  EN: 'us',
  NL: 'nl',
  FR: 'fr',
  DE: 'de',
  ES: 'es',
  IT: 'it',
  // Add more languages and their corresponding country codes as needed
};

export const MuiPhoneComponent = ({
  value,
  onChange,
  style = {},
  error,
  helperText,
  label,
  name,
  ...restProps
}) => {
  const theme=useTheme()
  const{defaultLanguage}=useSelector((store)=>store?.addGuest)
  const defaultCountry = languageToCountryMap[defaultLanguage] || 'nl';
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: defaultCountry,
    value,
    countries: defaultCountries,
  });


  const debouncedOnChange = useCallback(
    debounce((name, value) => {
      onChange(name, value);
    }, 300),
    [onChange]
  );

  useEffect(() => {
    debouncedOnChange(name, inputValue);
    return () => {
      debouncedOnChange.cancel();
    };
  }, [inputValue, name, debouncedOnChange]);

  const countryOptions = useMemo(
    () =>
      defaultCountries.map((c) => {
        const countryData = parseCountry(c);
        return (
          <MenuItem key={countryData.iso2} value={countryData.iso2}>
            <FlagImage iso2={countryData.iso2} style={{ marginRight: '8px' }} />
            <Typography marginRight="8px">{countryData.name}</Typography>
            <Typography color="gray">+{countryData.dialCode}</Typography>
          </MenuItem>
        );
      }),
    []
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ mb: 2, fontWeight: 'bold', fontSize: '15px', color: theme?.details?.formLabelColor }}>
        {label}
      </Typography>
      <TextField
        id={name}
        autoComplete='off'
        variant="outlined"
        placeholder="Phone number"
        value={inputValue}
        onChange={handlePhoneValueChange}
        name={name}
        type="tel"
        inputRef={inputRef}
        error={Boolean(error)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: '2px', marginLeft: '-8px', width: '60px' }}>
              <Select
                MenuProps={{
                  style: { height: '400px', width: '360px', top: '20px', left: '-34px' },
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                }}
                sx={{
                  width: 'max-content',
                  fieldset: { display: 'none ' },
                  '&.Mui-focused fieldset': { display: 'none !important' },
                  '.MuiSelect-select': { padding: '8px', paddingRight: '24px !important' },
                  svg: { right: 0 },
                }}
                value={country.iso2}
                onChange={(e) => setCountry(e.target.value)}
                renderValue={(value) => <FlagImage iso2={value} style={{ display: 'flex' }} />}
              >
                {countryOptions}
              </Select>
            </InputAdornment>
          ),
        }}
      
        {...restProps}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            height: '40px',
            borderRadius: '8px',
            backgroundColor: '#FDFDFD',
            '& fieldset': { borderColor: error ? '#f44336' : '#EBEBEB' },
            '&:hover fieldset': { borderColor: '#EBEBEB' },
            '&.Mui-focused fieldset': { borderColor: '#0B5753' },
            '& input': { outline: 'none' },
          },
          ...style,
        }}
      />
    </Box>
  );
};
