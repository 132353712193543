import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Popover, Box, Typography, Button, Grid, Skeleton, Grid2 } from '@mui/material';
import restype from "../../assets/lesson.png";
import { updateReservationType } from '../../store/slice/Addguest.slice';

const LessonComponent = ({ Ref, isOpen, handleClose, isLoading }) => {
  const {  reservType } = useSelector((store) => store?.addGuest);
  const dispatch = useDispatch();
  const reservationTypeDatas=[
    {
        id:1,
        day:'Every Wednesday',
        name: 'Beginner',
        time:'10:00 TO 11:00AM',
        startDate:' 20-06-2024',
        endDate:'20-10-2024',
        image: restype,
        totalCost: 50,
        info: 'Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec.',
      },
      {
        id:2,
        name: 'Intermediate',
        day:'Every Thursday',
        time:'10:00 TO 11:00AM',
        startDate:' 20-06-2024',
        endDate:'20-10-2024',
        image: restype,
        totalCost: 50,
        info: 'Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec.',
      },
      {
        id:3,
        day:'Every Saturday',
        time:'10:00 TO 11:00AM',
        name: 'Expert',
        startDate:' 20-06-2024',
        endDate:'20-10-2024',
        image: restype,
        totalCost: 50,
        info: 'Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec.',
      },
    
  ]

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          width: { xs: '100%', sm: '100%', md: '440px' }, // Full width for xs and sm, fixed width for md and above
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
        }}
      >

           {
            reservationTypeDatas.map((item, i) => (
              <Box
                key={i}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                  gap: '0.5rem',
                  backgroundColor: reservType?.name === item?.name ? '#13B8B6' : '#EBEBEB',
                  border: '1px solid #EBEBEB',
                  p: 1,
                  borderRadius: '8px',
                  cursor: 'pointer',
                  width: '100%',
                }}
                onClick={() => {
                  dispatch(updateReservationType(item));
                  handleClose();
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box
                      sx={{
                        width: '100%',
                        height: { xs: 'auto', md: '110px' },
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={restype} // Ensure to use the correct source here
                        alt="res-type-img"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Box sx={{ p: 1, width: '100%' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography
                          sx={{
                            color: reservType?.name === item?.name ? '#FEF5D8' : '#000000',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}
                        >
                          {item?.name}
                        </Typography>
                        {/* {item?.totalCost > 0 && (
                          <Typography
                            sx={{
                              color: reservType?.name === item?.name ? '#FEF5D8' : '#E50914',
                              fontSize: '14px',
                              fontWeight: '600',
                            }}
                          >
                            €{item?.totalCost}
                          </Typography>
                        )} */}
                      </Box>
                      <Box sx={{display:'flex', gap:"1rem",alignItems:'center',mt:1}}>
                        <Typography
                          sx={{
                            fontSize: '10px',
                            color: reservType?.name === item?.name ? "#fff" : '#000000',
                            fontWeight:'medium'
                          }}
                        >
                          {item?.day}
                        </Typography>
                        <Typography
                             sx={{
                                fontSize: '10px',
                                color: reservType?.name === item?.name ? "#fff" : '#000000',
                                fontWeight:'medium'
                              }}
                        >
                          {item?.time}
                        </Typography>
                      </Box>
                      <Box sx={{display:'flex', gap:"1rem",alignItems:'center',mt:1}}>
                        <Typography
                          sx={{
                            fontSize: '8px',
                            color: reservType?.name === item?.name ? "#fff" : '#000000',
                            fontWeight:'regular'
                          }}
                        >
                        Start From  {item?.startDate}
                        </Typography>
                        <Typography
                             sx={{
                                fontSize: '8px',
                                color: reservType?.name === item?.name ? "#fff" : '#000000',
                                fontWeight:'regular'
                              }}
                        >
                         End On {item?.endDate}
                        </Typography>
                      </Box>
                      </Box>
                      <Grid2 container spacing={2}>
                        <Grid2 size={{md:8}}>
                        <Typography
                        sx={{
                          fontSize: '8px',
                          color: reservType?.name === item?.name ? '#FEF5D8' : '#969BA0',
                          mt: 1,
                          ml:1
                        }}
                      >
                        {item?.info}
                      </Typography>
                        </Grid2>
                        <Grid2 size={{md:4}}>
                            
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            backgroundColor: reservType?.name === item?.name ? '#fff' : '#13B8B6',
                            color: reservType?.name === item?.name ? '#0B5753' : '#fff',
                            width: '60px',
                            height: '30px',
                            fontSize: '10px',
                            borderRadius: '7px',
                          }}
                        >
                          Choose
                        </Button>
                      </Box>
                   
                        </Grid2>

                      </Grid2>
            
              
                  </Grid>
                </Grid>
              </Box>
            ))
                        }
         
      </Box>
    </Popover>
  );
};

export default LessonComponent;
