import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Popover, Box, Typography, Button, Grid, Skeleton } from '@mui/material';
import restype from "../../assets/restype.png";
import { updateReservationType } from '../../store/slice/Addguest.slice';

const ReservationType = ({ Ref, isOpen, handleClose, isLoading }) => {
  const {  reservType } = useSelector((store) => store?.addGuest);
  const dispatch = useDispatch();
  const reservationTypeDatas=[
    {
        id:1,
        name: 'Dinner',
        image: restype,
        totalCost: 150,
        info: 'Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. Molestie mattis adipiscing sit condimentum. Pellentesque donec suscipit amet rhoncus eget ac purus. Nec diam mauris et aliquet sit commodo sagittis..',
      },
      {
        id:2,
        name: 'Lunch',
        image: restype,
        totalCost: 150,
        info: 'Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. Molestie mattis adipiscing sit condimentum. Pellentesque donec suscipit amet rhoncus eget ac purus. Nec diam mauris et aliquet sit commodo sagittis..',
      },
      {
        id:3,
        name: 'Breakfest',
        image: restype,
        totalCost: 150,
        info: 'Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc consectetur eget erat nec. Molestie mattis adipiscing sit condimentum. Pellentesque donec suscipit amet rhoncus eget ac purus. Nec diam mauris et aliquet sit commodo sagittis..',
      },
    
  ]

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          width: { xs: '100%', sm: '100%', md: '440px' }, // Full width for xs and sm, fixed width for md and above
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
        }}
      >

          {
            reservationTypeDatas.map((item, i) => (
              <Box
                key={i}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                  gap: '0.5rem',
                  backgroundColor: reservType?.name === item?.name ? '#FFCC00' : '#EBEBEB',
                  border: '1px solid #EBEBEB',
                  p: 1,
                  borderRadius: '8px',
                  cursor: 'pointer',
                  width: '100%',
                }}
                onClick={() => {
                  dispatch(updateReservationType(item));
                  handleClose();
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box
                      sx={{
                        width: '100%',
                        height: { xs: 'auto', md: '110px' },
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={restype} // Ensure to use the correct source here
                        alt="res-type-img"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Box sx={{ p: 1, width: '100%' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography
                          sx={{
                            color: reservType?.name === item?.name ? '#FEF5D8' : '#000000',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}
                        >
                          {item?.name}
                        </Typography>
                        {item?.totalCost > 0 && (
                          <Typography
                            sx={{
                              color: reservType?.name === item?.name ? '#FEF5D8' : '#E50914',
                              fontSize: '14px',
                              fontWeight: '600',
                            }}
                          >
                            €{item?.totalCost}
                          </Typography>
                        )}
                      </Box>
                      <Typography
                        sx={{
                          fontSize: '8px',
                          color: reservType?.name === item?.name ? '#FEF5D8' : '#969BA0',
                          mt: 1,
                        }}
                      >
                        {item?.info}
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            backgroundColor: reservType?.name === item?.name ? '#fff' : '#FFCC00',
                            color: reservType?.name === item?.name ? '#FFCC00' : 'black',
                            width: '60px',
                            height: '30px',
                            fontSize: '10px',
                            borderRadius: '7px',
                          }}
                        >
                          Choose
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))
                        }
    
      </Box>
    </Popover>
  );
};

export default ReservationType;
