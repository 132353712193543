import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  widgetConfig:null,
  WidgetSetting: [],
  blockedDates: [],
  widgetSetupId: "",
  defaultLanguage: "",
  key: "",
  buttonPosition: "",
  resType: "",
    widgetPosition: "",
    floating : "",
  languageData:[],
  buttonText:'',
  languageLoaded: false, // New flag to track if language data is loaded
};
export const addGuestSlice = createSlice({
  name: "widgetSettings",
  initialState,
  reducers: {
    updateWidgetConfig: (state, action) => {
      state.widgetConfig = action.payload;
    },
    updateWidgetSetting: (state, action) => {
      state.WidgetSetting = action.payload;
    },
    updateBlockedDates: (state, action) => {
      state.blockedDates = action.payload;
    },
    updateWidgetSetupId: (state, action) => {
      state.widgetSetupId = action.payload;
    },
    updateDefaultLanguage: (state, action) => {
      state.defaultLanguage = action.payload;
    },
    updateKey: (state, action) => {
      state.key = action.payload;
    },
    updateButtonPosition: (state, action) => {
      state.buttonPosition = action.payload;
    },
    updateResType: (state, action) => {
      state.resType = action.payload;
    },
    updateWidgetPosition: (state, action) => {
      state.widgetPosition = action.payload;
      },
      updateFloating: (state, action) => {
          state.floating = action.payload;
      },
      updateLanguageData: (state, action) => {
        state.languageData = action.payload;
      },
      updateButtonText: (state, action) => {
        state.buttonText = action.payload;
      },
      setLanguageLoaded(state, action) {
        state.languageLoaded = action.payload;
      },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateWidgetConfig,
  updateWidgetSetting,
  updateWidgetSetupId,
  updateDefaultLanguage,
  updateKey,
  updateButtonPosition,
  updateResType,
  updateWidgetPosition,
    updateBlockedDates,
    updateFloating,
    updateLanguageData,
    updateButtonText,
    setLanguageLoaded
} = addGuestSlice.actions;

export default addGuestSlice.reducer;
