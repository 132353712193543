import {
  Box,
  Button,
  Popover,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import restype from "../../assets/restype.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setReservationGroupDatas,
  updateReservationGroup,
} from "../../store/slice/Addguest.slice";
import { getReservationGroup } from "../../api/HorecaWidget.service";
import dayjs from "dayjs";

const ReservationGroup = ({ isOpen, handleClose, Ref }) => {
  const theme = useTheme();

  const matchesXs = useMediaQuery(theme.breakpoints.down("xs")); // For small devices
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm")); // For tablets and up
  const {
    reservType,
    selectedDate,
    time,
    reservationGroupData,
    reservGroup,
    isBackToHoreca,
    defaultLanguage,
    languageWords,
  } = useSelector((store) => store?.addGuest);
  const { key } = useSelector((store) => store?.widgetSettings);
  const dispatch = useDispatch();
  const fetchReservationGroupDetails = async () => {
    const parsedDate = dayjs(selectedDate, "DD-MM-YYYY");
    const formattedDate = parsedDate.format("YYYY-MM-DD");
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date();
    date.setHours(hours, minutes, seconds, 0); // Set hours, minutes, and seconds
    const formattedTime = date.toTimeString().slice(0, 8); // Extracts HH:mm:ss
    const payload = {
      idreservationtype: parseInt(reservType.idreservationtype) ?? 0,
      date: "2024-11-10",
      key: key,
      time: formattedTime,
      lang: defaultLanguage,
    };
    try {
      const response = await getReservationGroup(payload);
      dispatch(setReservationGroupDatas(response));
      console.log(response, "reservation group ");
    } catch (error) {
      console.log("error fetching reservation group : ", error?.message);
    }
  };

  useEffect(() => {
    if (Object.keys(reservType).length > 0 && time && !isBackToHoreca) {
      fetchReservationGroupDetails(); // Fetch reservation group details when reservation type, selected date and time are available
    }
  }, [reservType, time, isBackToHoreca, defaultLanguage]);
  useEffect(() => {
    if (reservationGroupData?.length == 1) {
      dispatch(updateReservationGroup(reservationGroupData[0])); // Update reservation group details when reservation group data is available
    }
  }, [reservationGroupData]);

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: matchesSm ? "93%" : "430px",
          maxWidth: "430px",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: theme?.resType?.bgColor,
          width: { xs: "100%", sm: "440px", md: "440px" }, // Responsive width
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {reservationGroupData?.map((item, i) => (
          <Box
            key={i}
            sx={{
              display: "flex",
              gap: "0.5rem",
              backgroundColor:
                reservGroup?.idarrangementtype === item?.idarrangementtype
                  ? theme?.resType?.itemBgActive
                  : theme?.resType?.itemBgColor,
              border: `1px solid ${theme?.resType?.borderColor}`,
              color:
                reservGroup?.idarrangementtype === item?.idarrangementtype
                  ? theme?.resType?.textColorActive
                  : theme?.resType?.itemTextColor,
              p: 1,
              borderRadius: "8px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: theme?.resType?.itemBgHover,
                color: theme?.resType?.textHover,
              },
            }}
            onClick={() => {
              dispatch(updateReservationGroup(item));
              handleClose();
            }}
          >
            <Box sx={{width:"100%"}}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  mb: 2,
                }}
              >
                {item?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "8px",

                  flexGrow: 1,
                }}
              >
                {item?.info}
              </Typography>
              <Box
                sx={{
                  display: "flex",

                  justifyContent: "flex-end",
                
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor:
                      reservGroup?.idarrangementtype === item?.idarrangementtype
                        ? theme?.resType?.chooseBgActive
                        : theme?.resType?.chooseBg,
                    color:
                      reservGroup?.idarrangementtype === item?.idarrangementtype
                        ? theme?.resType?.chooseTextActive
                        : theme?.resType?.chooseText,
                    "&:hover": {
                      backgroundColor: theme?.resType?.chooseBgHover,
                      color: theme?.resType?.chooseTextHover,
                    },
                    width: "60px",
                    height: "30px",
                    fontSize: "10px",
                    borderRadius: "7px",
                    ml: 1, // Added margin for spacing
                  }}
                >
                  {languageWords?.choose}
                </Button>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default ReservationGroup;
