import {
    Box,
    FormControl,
    MenuItem,
    Popover,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect } from "react";
  import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
  import { useDispatch, useSelector } from "react-redux";
  import { checkGuestLimit, setGuestCount, updateTotalCount } from "../../store/slice/Addguest.slice";
  import { useTranslation } from "react-i18next";
  
  const Addguest = ({isOpen,handleClose,Ref}) => {
    const { t, i18n } = useTranslation();
    const {adult,baby,child,kid,toddler,isGreaterThan10 } = useSelector((store) => store.addGuest); 
    const dispatch = useDispatch()
  
    useEffect(()=>{
      dispatch(updateTotalCount(adult+baby+child+toddler+kid))
  
    },[adult,baby,child,kid,toddler,])
    return (
      <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
       <Box
        sx={{ backgroundColor: "#fff", width: "208px", pt: 2 }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb:2,
              mx:3
            }}
          >
           
            <FormControl fullWidth sx={{ width: "100%", height: "32px" }}>
            {isGreaterThan10?.adult ?
                        <TextField
                        name="adult"
                        value={adult} // Show value adjusted for base of 10
                        type='number'
                        // Prevent the Select from closing
                        onClick={(e)=> e.stopPropagation()}
                        onFocus={(e)=> e.stopPropagation()}
                        sx={{
                          width: "100%",
                          height: "30px",
                          "& .MuiOutlinedInput-input": {
                            padding: 1,
                            color:'#9F9F9F',
                          },
                        }}
                        size="small"
                        onChange={(e) => {
                         // e.preventDefault()
                          const inputValue = e.target.value;
                          const newValue = Number(inputValue);
              
                          // Update state only if the input is a valid number
                          if (!isNaN(newValue) && inputValue.trim() !== "") {   
                            dispatch(setGuestCount({
                              guestType:"adult", count: newValue
                            }))
                          } else if (inputValue === "") {
                            dispatch(setGuestCount({
                              guestType:"adult", count: 0
                            }))
                          }
                        }}
                      />
                      :
                      <Select
                      id="demo-simple-select"
                      value={adult}
                      size="small"
                      IconComponent={KeyboardArrowDownIcon} // Custom arrow icon
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#9F9F9F", // Remove label border space
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#9F9F9F", // Remove hover border
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#9F9F9F", // Remove focus border
                        },
                        "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#9F9F9F", // Ensure outline is transparent
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: 1, // Remove padding if needed
                          color:'#9F9F9F',
                          fontSize:"12px"
                        },
                      }}
                      onChange={(event) => dispatch(setGuestCount({
                        guestType:"adult", count: Number(event.target.value)
                      }))}
                    >
                      {new Array(10).fill(null).map((_, index) => (
                        <MenuItem key={index} value={index + 1} sx={{color:'#9F9F9F',
                        fontSize:"12px"}}>
                          {index + 1}
                        </MenuItem>
                      ))}
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mx: 0.5,
                        }}
                      >
                        <Typography
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent the Select from closing
                            dispatch(checkGuestLimit({
                              guestType:'adult', isGreaterThan10:!isGreaterThan10?.adult
                            }))
                          }}
                        >
                          +
                        </Typography>
                      </MenuItem>
                    </Select>
                  }
           
            </FormControl>
          </Box>
       
        </Box>
      </Box>
     
    </Popover>
     
    );
  };
  
  export default Addguest;