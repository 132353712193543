import { Box, Typography } from "@mui/material";
import React from "react";
import clock from "../../assets/clock.png";
import user from "../../assets/users.png";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import widgetSettingSlice from "../../store/slice/widgetSetting.slice";

const YourBookingCard = ({bowling={}}) => {

        
    return (
    <Box
      sx={{
        minHeight: "282px",
        border: "1px dashed #92FC8B",
        backgroundColor: "#F3FFF2",
        borderRadius: "16px",
        p: 2,
        width:"100%"
      }}
    >
      <Typography
        sx={{ color: "#92FC8B", fontSize:{md:"24px",sm:"20px",xs:'16px'},fontWeight:"medium" }}
            >
               Your Booking
          </Typography>
          <Box sx={{ mt: 2 }}>
        <Typography
          sx={{ color: "#000000", fontWeight: "medium", fontSize: {md:"22px",sm:"19px",xs:'16px'} }}
        >
                  Marathon run
        </Typography>
        <Typography sx={{ color: "#969BA0", fontSize: "10px" }}>
        New York City, United State of America
        </Typography>
      </Box>
     
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          mt: 4,
          mb: 2,
        }}
      >
        <Box sx={{ width: "24px", height: "24px" }}>
          <img
            style={{ width: "100%", height: "100%" }}
            src={clock}
            alt="time"
          />
        </Box>
        <Typography sx={{ color: "#969BA0", fontSize: "14px" }}>
        5th Sep 2024
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <Box sx={{ width: "24px", height: "24px" }}>
          <img
            style={{ width: "100%", height: "100%" }}
            src={user}
            alt="time"
          />
        </Box>
        <Typography sx={{ color: "#969BA0", fz: "14px" }}>
        2 Adults
        </Typography>
      </Box>
     
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 4,
        }}
      >
        <Typography
          sx={{ fontSize: "16px", color: "#92FC8B", fontWeight: "bold" }}
        >
        Marathon run
        </Typography>
        <Typography
          sx={{ fontSize: "16px", color: "#000000", fontWeight: "bold" }}
                >
                    € 250
        </Typography>
      </Box>
   
    </Box>
  );
};

export default YourBookingCard;