import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { countries, languageToCountryMap } from '../../utils/countryList'; // Import your country list here
import { InputLabel, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

export default function CountrySelect({ style = {}, ...props }) {
  const [defaultCountry, setDefaultCountry] = React.useState(null);
  const { defaultLanguage } = useSelector((store) => store.addGuest);

  const [isFocused, setIsFocused] = React.useState(false); // Track if focused manually
  const theme = useTheme();
  const defaultCountryLabel = (props?.value && typeof props.value === 'string' ? props?.value?.trim() : '') || languageToCountryMap[defaultLanguage];


  React.useEffect(() => {
    if (defaultCountryLabel) {
      const country = countries.find((c) => c.label === defaultCountryLabel);
      if (country) {
        setDefaultCountry(country);
      }
    }
  }, [defaultCountryLabel]);

  return (
    <Box>
      <InputLabel
        sx={{
          mb: 2,
          fontWeight: 'bold',
          fontSize: '15px',
          color: theme?.details?.formLabelColor,
        }}
      >
        {props?.label}
      </InputLabel>
      <Autocomplete
        id="country-select-demo"
        sx={{ width: '100%' }}
        options={countries}
        autoHighlight
        getOptionLabel={(option) => option.label}
        value={defaultCountry}
        open={isFocused} // Open only when manually focused
        onFocus={() => setIsFocused(true)} // Set focused state on direct focus
        onBlur={() => setIsFocused(false)} // Reset focus state on blur
        onChange={(event, newValue) => {
          setDefaultCountry(newValue); // Update selected country
          props?.onChange(props?.name, newValue?.label);
        }}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.label} ({option.code}) +{option.phone}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            name={props?.name}
            placeholder="Select country"
            autoComplete={`new-${props?.name}`} 
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '40px',
                borderRadius: '8px',
                backgroundColor: '#FDFDFD',
                '& fieldset': {
                  borderColor: '#EBEBEB',
                },
                '&:hover fieldset': {
                  borderColor: '#EBEBEB',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#0B5753',
                },
                '& input': {
                  outline: 'none',
                },
                width: style.width || '100%',
              },
            }}
          />
        )}
      />
    </Box>
  );
}
