import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Grid,
} from "@mui/material";
import React from "react";
import Rating from "@mui/material/Rating";
import { useDispatch, useSelector } from "react-redux";
import { setAllowPost, setPostAnoumous } from "../../store/slice/feedback.slice";

const YourDetails = () => {
  const {addFields,isAllowPost,postAnoumous,basicLanguages}=useSelector((store)=>store?.feedback)
  const dispatch=useDispatch()

  

  const averageRatingValue =
    addFields?.reduce((acc, field) => {
      console.log("acc:",acc,field?.rating,2)
      return acc + field?.rating * 2;
    }, 0)
    const averageRating = addFields?.length == 1 ? averageRatingValue : Math.round(averageRatingValue / addFields?.length)
  return (
    <Box sx={{ padding: { xs: 2, sm: 3, md: 4 } }}>
      {" "}
 
        <Box >
          <Box>
            <Typography
              sx={{
                color: "#0B5753",
                fontSize: { xs: "20px", sm: "24px", md: "28px" },
                fontWeight: "medium",
              }}
            >
              {basicLanguages?.feedbackHeader}
            </Typography>
            <Typography
              sx={{
                color: "#969BA0",
                fontSize: { xs: "12px", sm: "14px", md: "15px" },
                fontWeight: "regular",
                mt: 2,
              }}
            >
              {basicLanguages?.yourDetailLoremIpsum}
            </Typography>
          </Box>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6} md={6}>
              {" "}
              {/* Full width on xs, half width on sm and md */}
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #EBEBEB",
                  backgroundColor: "#FDFDFD",
                  p: 2,
                  height: "100%",
                }}
              >
                <Box sx={{ my: 1 }}>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "18px", sm: "20px" },
                      fontWeight: "medium",
                    }}
                  >{`${averageRating}/10`}</Typography>
                  {/* <Typography
                    sx={{
                      color: "#F37120",
                      fontSize: { xs: "16px", sm: "18px" },
                      fontWeight: "medium",
                    }}
                  >
                    {item?.label}
                  </Typography> */}
                </Box>
                <Box>
                  <Rating
                    name="read-only"
                    value={averageRating / 2}
                    precision={0.5}
                    readOnly
                    sx={{ gap: { xs: 2, sm: 3, md: 5 } }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              {" "}
              {/* Full width on xs, half width on sm and md */}
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #EBEBEB",
                  backgroundColor: "#FDFDFD",
                  p: 2,
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "20px", sm: "24px" },
                    fontWeight: "medium",
                    mb: 2,
                  }}
                >
                  {basicLanguages['your details']}
                </Typography>
                <Box sx={{ mb: 1, display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: "medium",
                      flexGrow: 1,
                    }}
                  >
                    {basicLanguages?.Name}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#969BA0",
                      fontSize: { xs: "12px", sm: "15px" },
                      fontWeight: "regular",
                      textAlign: "left",
                    }}
                  >
                    Martin
                  </Typography>
                </Box>
                <Box sx={{ mb: 1, display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: "medium",
                      flexGrow: 1,
                    }}
                  >
                    {basicLanguages?.Email}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#969BA0",
                      fontSize: { xs: "12px", sm: "15px" },
                      fontWeight: "regular",
                      textAlign: "left",
                    }}
                  >
                    martin@email.com
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: "medium",
                      flexGrow: 1,
                    }}
                  >
                   {basicLanguages?.Phone}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#969BA0",
                      fontSize: { xs: "12px", sm: "15px" },
                      fontWeight: "regular",
                      textAlign: "left",
                    }}
                  >
                    (012) 1234-567
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
    
      <Box sx={{ mt: 2 }}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox size="small" value={isAllowPost} name='isAllowPost' checked={isAllowPost} onChange={()=>dispatch(setAllowPost(!isAllowPost))} />}
            label={
              <Typography
                sx={{
                  color: "#969BA0",
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: "1.5rem",
                }}
              >
                {basicLanguages?.postFeedback}
              </Typography>
            }
          />
          <FormControlLabel
            control={<Checkbox size="small"  value={postAnoumous} name='postAnoumous' checked={postAnoumous} onChange={()=>dispatch(setPostAnoumous(!postAnoumous))} />}
            label={
              <Typography
                sx={{
                  color: "#969BA0",
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: "1.5rem",
                }}
              >
                {basicLanguages?.PostAnonymously}
              </Typography>
            }
          />
        </FormGroup>
      </Box>
    </Box>
  );
};

export default YourDetails;
