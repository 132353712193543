// src/ThemeProviderWrapper.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const ThemeProviderWrapper = ({ children }) => {
  const { widgetConfig } = useSelector((store) => store?.widgetSettings);

  // Transform the theme data from the Redux state
  const transformedThemeData = widgetConfig?.settings?.css?.reduce(
    (acc, { key, value }) => {
      acc[key] = value;
      return acc;
    },
    {}
  );
  console.log(
    transformedThemeData,
    "tansformed theme a"
  );

  // Create MUI theme based on the transformed theme data
  const theme = createTheme({
    
    widgetButton: {
      textColor: transformedThemeData?.ButtonTextColor,
      bgColor: transformedThemeData?.ButtonBackgroundColor,
      closeBg:transformedThemeData?.CloseButtonBackgroundColor,
      closeHover:transformedThemeData?.CloseButtonBackgroundColorHover,
      closeText:transformedThemeData?.CloseButtonTextColor,
      closeTextHover: transformedThemeData?.CloseButtonTextColorHover,
    },
    widget: {
      mainBg: transformedThemeData?.MainBackgroundColor,
      langBgColor: transformedThemeData?.MainLanguageBackgroundColor,
      langBgHover: transformedThemeData?.MainLanguageBackgroundColorHover,
      langActiveBgColor:
        transformedThemeData?.MainLanguageBackgroundColorActive,
      langTextColor: transformedThemeData?.MainLanguageTextColor,
      langTextHover: transformedThemeData?.MainLanguageTextColorHover,
      langCheckboxColor: transformedThemeData?.MainLanguageCheckboxColor,
      langCheckboxActive: transformedThemeData?.MainLanguageCheckboxColorActive,
      inputBorderColor: transformedThemeData?.InputFieldBorderColor,
      inputBgColor: transformedThemeData?.InputFieldBackgroundColor,
      inputTextColor: transformedThemeData?.InputFieldTextColor,
      bookNowBtnBg: transformedThemeData?.BookNowButtonBackgroundColor,
      bookNowbtnBgActive:
        transformedThemeData?.BookNowButtonBackgroundColorActive,
      booknowBtnTextColor: transformedThemeData?.BookNowButtonTextColor,
      booknowBtnTextColorActive:
        transformedThemeData?.BookNowButtonTextColorActive,
      backButtonBgColor: transformedThemeData?.TopBackButtonBackgroundColor,
      backBtnBgHover: transformedThemeData?.TopBackButtonBackgroundColorHover,
      backBtnTextColor: transformedThemeData?.BackButtonBackgroundTextColor,
      backBtnBgTextHover: transformedThemeData?.BackButtonBackgroundTextHover,
      stepScreenBgColor: transformedThemeData?.StapsScreenBackgroundColor,
      stepScreenBorder: transformedThemeData?.StapsScreenBackgroundBorderColor,
      stepCircleBgColor: transformedThemeData?.StepsCircleBackgroundColor,
      stepCircleActiveBg:
        transformedThemeData?.StepsCircleBackgroundColorActive,
      stepCircleTextColor: transformedThemeData?.StepsCircleTextColor,
      stepCircleTextActive: transformedThemeData?.StepsCircleTextColorActive,
      stepTextColor: transformedThemeData?.StepsTextColor,
      stepTextColorActive: transformedThemeData?.StepsTextColorActive,

      successHeader:transformedThemeData?.SuccesHeaderColor,
      successText:transformedThemeData?.SuccesTextColor,
      successTextBold:transformedThemeData?.SuccesTextBoldColor,
      successResNumber:transformedThemeData?.SuccesReservationNumberTextColor,
      successBtnBgColor:transformedThemeData?.SuccessButtonBackgroundColor,
      successBtnBgHover:transformedThemeData?.SuccessButtonBackgroundColorHover,
      successBtnText:transformedThemeData?.SuccessButtonTextColor,
      successBtnTextHover:transformedThemeData?.SuccessButtonTextColorHover,
      failureHeader:transformedThemeData?.FailedHeaderColor,
      failureTextColor:transformedThemeData?.FailedTextColor

    },
    guest: {
      bgColor: transformedThemeData?.GuestBackgroundColor,
      headingColor: transformedThemeData?.GuestHeaderTextColor,
      textColor: transformedThemeData?.GuestHeaderTextColor,
      dropdownBorderColor: transformedThemeData?.GuestDropDownBorderColor,
      dropdownBgColor: transformedThemeData?.GuestDropDownBackgroundColor,
      dropdownBgHover: transformedThemeData?.GuestDropDownBackgroundColorHover,
      dropdownTextColor: transformedThemeData?.GuestDropDownTextColor,
      dropdownTextColorHover: transformedThemeData?.GuestDropDownTextColorHover,
      plusColor: transformedThemeData?.GuestDropDownPlusColor,
      plusHover: transformedThemeData?.GuestDropDownPlusColorHover,
    },
    calender: {
      bgColor: transformedThemeData?.CalendarBackgroundColor,
      arrowColor: transformedThemeData?.CalendarArrowColor,
      arrowColorActive: transformedThemeData?.CalendarArrowColorActive,
      arrowColorHover: transformedThemeData?.CalendarArrowColorHover,
      yearTextColor: transformedThemeData?.CalendarYearTextColor,
      monthTextColor: transformedThemeData?.CalendarMonthTextColor,
      daysTextColor: transformedThemeData?.CalendarDaysTextColor,
      dateTextColor: transformedThemeData?.CalendarDateTextColor,
      dateTextColorHover: transformedThemeData?.CalendarDateTextColorHover,
      dateTextColorActive: transformedThemeData?.CalendarDateTextColorActive,
      dateBorerColor: transformedThemeData?.CalendarDateBorderColor,
      dateBgColr: transformedThemeData?.CalendarDateBackgroundColor,
      dateBgHover: transformedThemeData?.CalendarDateBackgroundColorHover,
      dateBgColorActive:
        transformedThemeData?.CalendarDateBackgroundColorActive,
      inActiveBorderColor:
        transformedThemeData?.CalendarDateInactiveBorderColor,
      inactiveBgColor:
        transformedThemeData?.CalendarDateInactiveBackgroundColor,
      inActiveTextColor: transformedThemeData?.CalendarDateInactiveTextColor,
     
    },
    resType: {
      bgColor: transformedThemeData?.ReservationTypesBackgoundColor,
      borderColor: transformedThemeData?.ReservationTypesItemBorderColor,
      itemBgColor: transformedThemeData?.ReservationTypesItemBackgroundColor,
      itemBgHover:
        transformedThemeData?.ReservationTypesItemBackgroundColorHover,
      itemBgActive:
        transformedThemeData?.ReservationTypesItemBackgroundColorActive,
      itemTextColor: transformedThemeData?.ReservationTypesItemTextColor,
      textHover: transformedThemeData?.ReservationTypesItemTextColorHover,
      textColorActive:
        transformedThemeData?.ReservationTypesItemTextColorActive,

      chooseBg:transformedThemeData?.ReservationTypesItemButtonBackgroundColor,
      chooseBgActive:transformedThemeData?.ReservationTypesItemButtonBackgroundColorActive,
      chooseBgHover:transformedThemeData?.ReservationTypesItemButtonBackgroundColorHover,    
      chooseText:transformedThemeData?.ReservationTypesItemButtonTextColor,
      chooseTextActive:transformedThemeData?.ReservationTypesItemButtonTextColorActive,
      chooseTextHover:transformedThemeData?.ReservationTypesItemButtonTextColorHover
    },
    time: {
      bgColor: transformedThemeData?.TimesBackgroundColor,
      itemBG: transformedThemeData?.TimesTimeBackgroundColor,
      itemBgHover: transformedThemeData?.TimesTimeBackgroundColorHover,
      itemBGActive: transformedThemeData?.TimesTimeBackgroundColorActive,
      itemBorder: transformedThemeData?.TimesTimeBorderColor,
      itemTextColor: transformedThemeData?.TimesTimeTextColor,
      itemTextHover: transformedThemeData?.TimesTimeTextColorHover,
      itemTextActive: transformedThemeData?.TimesTimeTextColorActive,
    },
    product: {
      bgColor: transformedThemeData?.ProductItemBackgroundColor,
      bgActive: transformedThemeData?.ProductItemBackgroundColorActive,
      headerColor: transformedThemeData?.ProductItemHeaderColor,
      headerActive: transformedThemeData?.ProductItemHeaderColorActive,
      itemText: transformedThemeData?.ProductItemTextColor,
      itemTextActive: transformedThemeData?.ProductItemTextColorActive,
      priceColor: transformedThemeData?.ProductItemPriceColor,
      priceColorActive: transformedThemeData?.ProductItemPriceColorActive,
      addMin: transformedThemeData?.ProductItemAddMinColor,
      addMinTextColor: transformedThemeData?.ProductItemAddMinTextColor,
      addBtnBg: transformedThemeData?.ProductItemAddButtonBackgroundColor,
      addBtnBgHover:
        transformedThemeData?.ProductItemAddButtonBackgroundColorHover,
      addBtnActive:
        transformedThemeData?.ProductItemAddButtonBackgroundColorActive,
      addBtnTextColor: transformedThemeData?.ProductItemAddButtonTextColor,
      addBtnTextHover: transformedThemeData?.ProductItemAddButtonTextColorHover,
      addBtnTextActive:
        transformedThemeData?.ProductItemAddButtonTextColorActive,
    },
    details: {
      detailsBgColor: transformedThemeData?.DetailsBackgroundColor,
      detailsBorderColor: transformedThemeData?.DetailsBorderColor,
      detailsHeaderColor: transformedThemeData?.DetailsHeaderColor,
      detailsCompanyNameColor: transformedThemeData?.DetailsCompanyNameColor,
      detailsAddressColor: transformedThemeData?.DetailsAdressColor,
      detailsDateIconColor: transformedThemeData?.DetailsDateIconColor,
      detailsDateTextColor: transformedThemeData?.DetailsDateTextColor,
      detailsGuestsIconColor: transformedThemeData?.DetailsGuestsIconColor,
      detailsGuestsTextColor: transformedThemeData?.DetailsGuestsTextColor,
      detailsProductsIconColor: transformedThemeData?.DetailsProductsIconColor,
      detailsProductsHeaderTextColor:
        transformedThemeData?.DetailsProductsHeaderTextColor,
      detailsProductsTextColor: transformedThemeData?.DetailsProductsTextColor,
      detailsReservationTypeTextColor:
        transformedThemeData?.DetailsReservationTypeTextColor,
      detailsPriceColor: transformedThemeData?.DetailsPriceColor,
      detailsTransactionColor: transformedThemeData?.DetailsTransactionColor,
      detailsVatColor: transformedThemeData?.DetailsVatColor,
      detailsTotalColor: transformedThemeData?.DetailsTotalColor,
      formHeaderColor: transformedThemeData?.FormHeaderColor,
      formLabelColor: transformedThemeData?.FormLabelColor,
      formCheckBoxColor: transformedThemeData?.FormCheckBoxColor,
      formRadioColor: transformedThemeData?.FormRadioColor,
      formLinkColor: transformedThemeData?.FormLinkColor,
      formLinkColorHover: transformedThemeData?.FormLinkColorHover,
      backButtonBgColor: transformedThemeData?.BackButtonBackgroundColor,
      backButtonBgColorHover:
        transformedThemeData?.BackButtonBackgroundColorHover,
      backButtonTextColor: transformedThemeData?.BackButtonTextColor,
      backButtonTextColorHover: transformedThemeData?.BackButtonTextColorHover,
      nextButtonBgColor: transformedThemeData?.NextButtonBackgroundColor,
      nextButtonBgColorHover:
        transformedThemeData?.NextButtonBackgroundColorHover,
      nextButtonTextColor: transformedThemeData?.NextButtonTextColor,
      nextButtonTextColorHover: transformedThemeData?.NextButtonTextColorHover,
      confirmBgColor: transformedThemeData?.ConfirmBackgroundColor,
      confirmBgColorHover: transformedThemeData?.ConfirmBackgroundColorHover,
      confirmTextColor: transformedThemeData?.ConfirmTextColor,
      confirmTextColorHover: transformedThemeData?.ConfirmTextColorHover,
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeProviderWrapper;
