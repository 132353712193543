import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  setGuestDetailsData,
  setTransactionDetails,
  toggleBookNowOpen,
} from "../../store/slice/Addguest.slice";
import { Paper, StepConnector, useMediaQuery, useTheme } from "@mui/material";
import ConfirmandPay from "../horecaWidget/ConfirmandPay";
import backicon from "../../assets/backwidget.png";
import nexticon from "../../assets/next.png";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  createReservation,
  getGuestDetails,
  transactionCostForReservation,
} from "../../api/HorecaWidget.service";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Snackbar from '@mui/material/Snackbar';
import dayjs from "dayjs";
import ExtraTicket from "./ExtraTicket";
import YourDetailsTicket from "./YourDetailsTicket";
import CombinationTicket from "./CombinationTicket";
dayjs?.extend(customParseFormat); // Extend for custom date format parsing

const DottedStepConnector = (props) => (
  <StepConnector
    {...props}
    sx={{
      "& .MuiStepConnector-line": {
        borderColor: "#000000", // Line color
        borderStyle: "dashed", // Dashed line style (simulates dotted with spacing)
        borderWidth: 1, // Line thickness
      },
    }}
  />
);



export default function BookNowFormTicket() {
  const [steps, setSteps] = React.useState([
    "Availability",
    "Extra",
    "Combination",
    "Your Detail",
    "Confirm & Pay",
  ]);
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const {
    WidgetSetting,
    key,
    widgetSetupId,
    buttonPosition,
    widgetPosition,
    resType,
    defaultLanguage
  } = useSelector((store) => store?.widgetSettings);
    const { reservType,  GuestDetailsData, extraProductData,reservGroup,adult,child,kid,baby,toddler,selectedDate,time,totalCount,selectedExtraProduct,transactionDetails,guestFields,addFields,reservationLang} = useSelector(
    (store) => store.addGuest
    );
 // console.log(GuestDetailsData,"extraProductData")
  const reservationRequestMin=WidgetSetting?.find((item)=>item?.tag == 'reservationRequest');
  
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(1);
  const [completed, setCompleted] = React.useState({ 0: true });
  const { isBookNowOpen } = useSelector((store) => store.addGuest);
  const dispatch = useDispatch();
  const totalSteps = () => steps.length;
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;



  const handleClosebar = () => {
    setState({ ...state, open: false });
  };

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    if(activeStep === 1){
        handleClosebar();
        navigate(-1);
      }else{
    
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    });
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // Modal open/close functions
  const handleClickOpen = () => {
    dispatch(toggleBookNowOpen());
  };

  const handleClose = () => {
    dispatch(toggleBookNowOpen());
  };

  const fetchGuestDetails = async () => {
    try {
      const response = await getGuestDetails(
        key,
        reservType?.idreservationtype,
        defaultLanguage
      );
      console.log("response for field details",response)
      dispatch(setGuestDetailsData(response));
    } catch (error) {
      console.error(error);
    }
  };
  const creatBookingReservation = async () => {
    const parsedDate = dayjs(selectedDate, 'DD-MM-YYYY', true);
    // Check if date is valid
    if (!parsedDate?.isValid()) {
      console.error('Invalid date:', selectedDate);
      return;
    }
    // Format the valid date into 'YYYY-MM-DD'
    const formattedDate = parsedDate.format('YYYY-MM-DD');
 
    const upsaleproduct=selectedExtraProduct?.map((item)=>{
  return {
    idupsaleproduct: item?.idproduct,
    quantity: item?.quantity,
      price: item.price * item?.quantity,
    supportingproducts:null
  }
    })
    const payload = {
      idreservationtype: reservType?.idreservationtype,
      idarrangementtype: reservGroup?.idarrangementtype,
      field1: parseInt(adult),
      field2: parseInt(child),
      field3: parseInt(baby),
      field4: parseInt(toddler),
      field5: parseInt(kid),
        arrivaldate: formattedDate,
        arrivaltime: `${time};${reservType?.duration};${reservType.durationgroups}`,
      key: key,
      language: reservationLang,
      newsletter: false,
      reservationRequest: false,
      guestFields:guestFields || [],
      fields: addFields,
      app: false,
      horecaProductReservation: {
        idhorecaproduct: reservType?.idproducthoreca ?? 0,
        numberofguests: totalCount,
          price: resType?.totalCost * totalCount ?? 0,
      },
      upsaleProductReservations: upsaleproduct || [],
      transactionCostReservation: {
        idproduct: transactionDetails?.idproduct ,
        idreservation: reservType?.idreservationtype,
        price: transactionDetails?.pricePerReservation,
      },
      baseUrl: "",
      isGroupReservation: false,
      reservationRequestMin:parseInt(reservationRequestMin?.data) ?? 0,
      MainModuleId: 1,
    };
      try {

      const response = await createReservation(payload);
          if (response?.resource) {
              window.parent.postMessage({
                  redirectUrl: response._links.checkout.href
              }, '*');

              //window.location = response._links.checkout.href;
    //    setState({
    //      open: true,
    //vertical: 'top',
    //horizontal: 'center',
    //    }); 
    //    handleNext()
      }
    } catch (error) {
      console.error(error);
    }
  };
  const createInitialValuesAndValidationSchema = (data) => {
    const initialValues = {};
    const validationShape = {};

    data?.forEach((field) => {
        initialValues[field.name] = ""; // For text fields or any other type
      // Set validation schema
      if (field.required) {
        validationShape[field.name] =
          Yup.string().required("Required");
      } else {
        validationShape[field.name] = Yup.string();
      }
    });

    // Return both initialValues and validationSchema
    return {
      initialValues,
      validationSchema: Yup.object().shape(validationShape),
    };
  };
  const fetchTransactionDetails=async()=>{
    try {
      const response = await transactionCostForReservation(
        key,
        reservType?.idreservationtype
      );
      console.log('transaction details',response)
      dispatch(setTransactionDetails(response))     
    } catch (error) {
      console.log("error in transaction cost:",error?.message)
      throw new Error(error?.message)     
    }
  }


  const { initialValues, validationSchema } =
    createInitialValuesAndValidationSchema(GuestDetailsData);

  React.useEffect(() => {
    fetchGuestDetails();
    fetchTransactionDetails();
  }, []);


  return (
    <>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
        // Handle form submission
      }}
    >
      {({ values, handleChange, handleSubmit, touched, errors }) => (
        <Box>
          <IconButton
            sx={{
              backgroundColor: "#0B5753", // Initial background color
              m: 2, // Margin
              "&:hover": {
                backgroundColor: "lightgrey", // Background color on hover
              },
            }}
            aria-label="back navigation"
            size="large"
            onClick={() => navigate(-1)}
          >
            <NavigateBeforeIcon sx={{ color: "#fff" }} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
              mb: 2,
              p: 2,
            }}
          >
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "#fff",
                px: 2,
                pt: 5,
                py: 3,
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%", // Take full width
                  overflow: "hidden", // Prevent overflow
                  px: 2,
                  mb: 5,
                }}
              >
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  connector={<DottedStepConnector />}
                  sx={{
                    flexWrap: { xs: "wrap", sm: "nowrap" }, // Wrap steps on small screens
                    justifyContent: { xs: "center", sm: "flex-start" }, // Center on xs and align to start on sm+
                  }}
                >
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                      <StepButton
                        color="inherit"
                        sx={{
                          ...(activeStep === index && {
                            "& .MuiStepLabel-label": {
                              color: "#000000",
                              fontWeight: "bold",
                              fontSize: "15px",
                            }, // Highlighted label color
                          }),
                          // Adjust the width and font size for smaller screens
                          width: { xs: "100%", sm: "auto" }, // Full width on xs
                          fontSize: { xs: "12px", sm: "inherit" }, // Smaller font size on xs
                          //border: isSmallScreen ? "none" : `1px dashed ${activeStep === index ? "#0B5753" : "#EBEBEB"}`, // Remove border on small screens
                        }}
                        onClick={handleStep(index)}
                      >
                        {isSmallScreen ? "" : t(`stepForm.${label}`)}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <div>
                {allStepsCompleted() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you're finished
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Box>
                      {/* Switch statement for rendering components based on active step */}
                      {(() => {
                        switch (activeStep) {
                          case 0:
                            const queryParams = new URLSearchParams({
                              widgetsetupid: widgetSetupId,
                              key: key,
                              resType: resType,
                              buttonposition: buttonPosition,
                            }).toString();
                            navigate(`/?${queryParams}`, {
                              state: { openWidgetScreen: true },
                            });
                            break;

                          case 1:
                            return <ExtraTicket />;
                          case 2:
                            return <CombinationTicket/>; // Placeholder for Extra component
                          case 3:
                            return (
                              <YourDetailsTicket
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                            ); // Placeholder for Combination component
                          case 4:
                            return <ConfirmandPay />; // Placeholder for Confirm & Pay component
                          default:
                            return <div>No page found</div>; // In case of unexpected value
                        }
                      })()}
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        variant="contained"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{
                          mr: 1,
                          backgroundColor: "#969BA0",
                          color: "white",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img src={backicon} alt="back-img" />
                          Previous
                        </Box>
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {activeStep !== 4 && (
                        <Button
                          onClick={() => {
                            // if (activeStep === 3) {
                            //   handleSubmit(); // Submit the form when on step 3
                            //   creatBookingReservation()
                            // } else {
                              handleNext(); // Proceed to the next step otherwise
                            // }
                          }}
                          sx={{
                            mr: 1,
                            backgroundColor: "#0B5753",
                            color: "white",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img src={nexticon} alt="next-img" />
                            {activeStep == 3 ? "Confirm & pay" : "Next"}
                          </Box>
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Paper>
          </Box>
        </Box>
      )}
    </Formik>
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClosebar}
        message="Reservation details saved successfully !"
        key={vertical + horizontal}
      />
    </Box>
    </>
  );
}
