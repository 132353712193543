import { Box, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';

const PaymentCard = () => {

  return (
    <Box sx={{
        width:"100%",
    minHeight:"206px",
    border: "1px dashed #0B5753",
    backgroundColor: "#fff",
    borderRadius: "16px",
    mt:1,
    p: 2,}}
    >
        <Typography sx={{ color: "#13B8B6", fontWeight: "medium", fontSize: {md:"24px",sm:"20px",xs:'16px'} }}>Payment</Typography>
        <Box>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:'center',mt:2,mb:2}}>
                <Typography sx={{color:"#000000",fontSize:'14px',fontWeight:"600"}}>Total</Typography>
                  <Typography sx={{ color: "#000000", fontSize: '14px', fontWeight: "600" }}>€250</Typography>
            </Box>
            <Box  sx={{display:"flex",justifyContent:"space-between",alignItems:'center',mb:2}}>
            <Typography sx={{color:"#000000",fontSize:'14px',fontWeight:"600"}}>VAT 21%</Typography>
                  <Typography sx={{ color: "#000000", fontSize: '14px', fontWeight: "600" }}>€ 50</Typography>
            </Box>
            <Box sx={{borderTop:'1px solid #969BA0',display:'flex',justifyContent:'space-between',pt:2}}>
                <Typography sx={{fontSize:'18px',color:"#13B8B6",fontWeight:"medium"}}>Total Including VAT</Typography>
                  <Typography sx={{ fontSize: '18px', color: "#13B8B6", fontWeight: "medium" }}>€ 300</Typography>
            </Box>
        </Box>

       
      </Box>
  )
}

export default PaymentCard