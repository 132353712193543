import {
    Box,
    FormControl,
    FormControlLabel,
    FormHelperText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Grid2 from "@mui/material/Grid2";
  import TextInput from "../../components/Input/TextInput";
  import CountrySelect from "../../components/SelectComponent/SelectCountry";
  import { MuiPhoneComponent } from "../../components/Input/MuiPhoneComponent";
  import YourBookingCard from "./YourBookingCard";
  import PaymentCard from "./PaymentCard";
  import { getGuestDetails } from "../../api/HorecaWidget.service";
  import { useDispatch, useSelector } from "react-redux";
  import {
    setAddFields,
    setGuestDetailsData,
    setGuestFields,
    setReservationLang,
  } from "../../store/slice/Addguest.slice";
  import { Formik, Form, Field } from "formik";
  import TextAreaComponent from "../../components/Input/TextAreaComponent";
  import SelectComponent from "../../components/SelectComponent/SelectComponent";
  import FormGroup from "@mui/material/FormGroup";
  
  import Checkbox from "@mui/material/Checkbox";
  import DatePickerComponent from "../../components/SelectComponent/DatePicker";
  
  const Yourdetails = ({ values, handleChange, errors, touched }) => {
    const dispatch = useDispatch();
    const { WidgetSetting, key, defaultLanguage } = useSelector(
      (store) => store?.widgetSettings
    );
    const { reservType, GuestDetailsData, guestFields,addFields,reservationLang} = useSelector(
      (store) => store.addGuest
      );
    useEffect(() => {
      const guestFields = GuestDetailsData?.map((field) => ({
          idGuestDetails: parseInt(field?.idGuestDetails),
        data: values[field?.name] || "", // Get the corresponding form value or empty string
      }));
      dispatch(setGuestFields(guestFields));
    }, [values]);
  
    return (
      <Form>
        <Box sx={{ mt: 5, p: 2 }}>
          <Grid2 container spacing={2}>
            <Grid2 size={{ md: 8, sm: 12, xs: 12 }}>
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#000000",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Contact Data
                </Typography>
              </Box>
              {GuestDetailsData?.map((field, i) => {
                switch (field?.type) {
                  case "radio":
                    return (
                      <Box sx={{ mt: 1 }} key={i}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={field?.name}
                            value={values.salutation}
                            onChange={(e)=>handleChange(e.target.value)}
                          >
                            <FormControlLabel
                              value="Mrs"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color:
                                      values.salutation === "Mrs"
                                        ? "#000000"
                                        : "#969BA0", // Conditional styling
                                    "&.Mui-checked": {
                                      color: "#0B5753", // Style when checked
                                    },
                                  }}
                                />
                              }
                              label={
                                <span
                                  style={{
                                    color:
                                      values.salutation === "Mrs"
                                        ? "#000000"
                                        : "#969BA0", // Conditional styling for label
                                    fontSize: "16px",
                                  }}
                                >
                                  Mrs
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="Mr"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color:
                                      values.salutation === "Mr"
                                        ? "#000000"
                                        : "#969BA0", // Conditional styling
                                    "&.Mui-checked": {
                                      color: "#0B5753", // Style when checked
                                    },
                                  }}
                                />
                              }
                              label={
                                <span
                                  style={{
                                    color:
                                      values.salutation === "Mr"
                                        ? "#000000"
                                        : "#969BA0", // Conditional styling for label
                                    fontSize: "16px",
                                  }}
                                >
                                  Mr
                                </span>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    );
                  case "text":
                    if (field?.infoEN === "Place") {
                      return (
                        <Box sx={{ mt: 2 }} key={i}>
                          <CountrySelect
                            style={{ width: "100%" }}
                            label={
                             field?.name
                            }
                            name={ field?.name}
                          />
                        </Box>
                      );
                    }
                    return (
                      <Grid2 size={{ xs: 12, md: 6 }} key={i} mt={1}>
                            <TextInput
                          label={field?.name}
                          name={field?.name}
                          value={values[field?.name]}
                          onChange={handleChange}
                          error={Boolean(
                            errors[field?.name] && touched[field?.name]
                          )} // Only show error when touched
                          helperText={touched[field?.name] && errors[field?.name]} // Show error message if field is touched and there is an error
                        />
                      </Grid2>
                    );
                  case "date":
                    return (
                      <Grid2 size={{ xs: 12, md: 6 }} key={i} mt={1}>
                        <DatePickerComponent
                          label={field?.info}
                          name={field?.name}
                         
                          // value={field?.name}
                         
                         
                        />
                      </Grid2>
                    );
                  
                  case "Email":
                    return (
                      <Grid2 size={{ xs: 12, md: 6 }} key={i} mt={1}>
                        <TextInput
                          label={
                            field?.name
                          }
                          name={field?.name}
                          value={values[field?.name]}
                          onChange={handleChange}
                          error={Boolean(
                            errors[field?.name] && touched[field?.name]
                          )} // Only show error when touched
                          helperText={touched[field?.name] && errors[field?.name]} // Show error message if field is touched and there is an error
                        />
                      </Grid2>
                    );
                  case "tel":
                    return (
                      <Box sx={{ mt: 2 }} key={i}>
                        <MuiPhoneComponent
                          style={{ width: "100%" }}
                          label={
                           field?.name
                          }
                          name={field?.name}
                          value={values[field?.name]}
                          onChange={handleChange}
                          //error={Boolean(
                          //  errors[field?.name] && touched[field?.name]
                          //)} // Only show error when touched
                          //helperText={touched[field?.name] && errors[field?.name]}
                        />
                      </Box>
                    );
                  default:
                    return null;
                }
              })}
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#000000",
                    fontSize: "20px",
                    fontWeight: "bold",
                    mt: 2,
                  }}
                >
                  Extra Information
                </Typography>
                <TextAreaComponent
                  label="DO You have questions or suggestions?"
                  name={'suggestions'}
                  setField={setAddFields}
                />
                <Box>
                  <Typography
                    sx={{ mt: 2, mb: 1, fontSize: "10px", color: "#969BA0" }}
                  >
                    In Which Language do you prefer to receive your booking
                    information?
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 2,
                      "& .MuiOutlinedInput-root": {
                        height: "40px", // Set height
                        borderRadius: "8px", // Set border radius
                        backgroundColor: "#FDFDFD", // Background color
                        "& fieldset": {
                          borderColor: "#EBEBEB", // Show red border if error
                        },
                        "&:hover fieldset": {
                          borderColor: "#EBEBEB", // Border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#EBEBEB", // Change border color when focused
                        },
                        "& input": {
                          outline: "none", // Remove outline
                        },
                      },
                    }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={reservationLang}
                      onChange={(e)=>dispatch(setReservationLang(e.target.value))}
                      placeholder="choose language"
                    >
                      <MenuItem value={'EN'}>English</MenuItem>
                      <MenuItem value={'NL'}>Dutch</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label={
                        <Typography
                          sx={{
                            color: "#969BA0",
                            fontSize: "14px",
                            lineHeight: "1.5rem",
                          }}
                        >
                          Lorem ipsum dolor sit amet consectetur. Molestie eu
                          massa nunc consectetur eget erat nec. Molestie mattis
                          adipiscing sit condimentum. Pellentesque donec suscipit
                          amet rhoncus eget ac purus. Nec diam mauris et aliquet
                          sit commodo sagittis.
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label={
                        <Typography
                          sx={{
                            color: "#969BA0",
                            fontSize: "14px",
                            lineHeight: "1.5rem",
                          }}
                        >
                          Lorem ipsum dolor sit amet consectetur. Molestie eu
                          massa nunc consectetur eget erat nec.
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
              </Box>
            </Grid2>
  
            <Grid2 size={{ md: 4, sm: 12, xs: 12 }}>
              <Box>
                <YourBookingCard />
                <PaymentCard />
              </Box>
            </Grid2>
          </Grid2>
  
          {/* Submit button is handled on the parent stepper */}
        </Box>
      </Form>
    );
  };
  
  export default Yourdetails;
  