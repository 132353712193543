import { Box, Button, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid2 from "@mui/material/Grid2";
import YourBookingCard from "../horecaWidget/YourBookingCard";
import PaymentCard from "../horecaWidget/PaymentCard";
import bowling from "../../assets/bowling.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchCombinationTicketWidget } from "../../api/HorecaWidget.service";
import dayjs from "dayjs";
import { setCombinationWidgetData, setSelectedCombination } from "../../store/slice/Addguest.slice";

const CombinationTicket = () => {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const {
        totalCount,
        selectedDate,
        reservType,
        time,
        reservGroup,
        adult,
        child,
        kid,
        baby,
        toddler,
        reservationGroupData,
        selectedExtraProduct,
     //   combinationWidgetData
    } = useSelector((store) => store.addGuest);
    const { WidgetSetting, key, defaultLanguage } = useSelector(
        (store) => store?.widgetSettings
    );
    const combinationWidgetData=[
        {
            idCombinationTickets: 1,
            reservationtype: '2 Hours bowling b',
            description: 'Bowling',
            price: 10,
            image: bowling,
            fromTime: '03:00:00',
            toTime: '05:00:00'
        },
        {
            idCombinationTickets: 2,
            reservationtype: '3 Hours bowling b',
            description: 'Bowling',
            price: 15,
            image: bowling,
            fromTime: '05:00:00',
            toTime: '07:00:00'
        }
    ]

    // const fetchCombinationTicketWidgetDetails = async () => {
    //     setLoading(true)
    //     const starttime = time
    //     const secondsToAdd = reservType.duration; // Seconds to add (1 hour)
    //     // Create a Day.js object for the arrival time
    //     const times = dayjs(`2024-01-01T${starttime}`); // Use a dummy date
    //     // Add seconds to the arrival time
    //     const departureTime = times.add(secondsToAdd, 'second').format('HH:mm:ss');
    //     try {
    //         const response = await 
    //         (
    //             parseInt(reservType.idreservationtype),
    //             key,
    //             dayjs(selectedDate, 'DD-MM-YYYY', true),
    //             time,
    //             departureTime,
    //             parseInt(totalCount),
    //             defaultLanguage || 'EN'
    //             )

    //         // if response is null
    //         if (response == null) {

    //             // next step
              
    //         }

    //         dispatch(setCombinationWidgetData(response))
    //         setLoading(false)
    //     } catch (error) {
    //         console.log("Error fetching combination widget", error?.message);
    //         throw new Error(error?.message)
    //         setLoading(false)
    //     }
    // }
    // useEffect(() => {
    //     fetchCombinationTicketWidgetDetails()
    // }, [])
    return (
        <Box sx={{ p: 2 }}>
            <Grid2 container spacing={2}>
                <Grid2 size={{ md: 7, sm: 12, xs: 12 }} sx={{ mt: 3 }}>
    
                            <>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: "#000000",
                                            fontSize: { md: "28px", sm: "19px", xs: "16px" },
                                        }}
                                    >
                                        Bowling
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: "12px", color: "#969BA0", mt: 2, mb: 2 }}
                                    >
                                        Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc
                                        consectetur eget erat nec. Molestie mattis adipiscing sit
                                        condimentum
                                    </Typography>
                                </Box>
                                {
                                    combinationWidgetData?.map((item) => (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: { xs: "column", sm: "row" }, // Stack vertically on xs and horizontally on sm+
                                                gap: "1rem",
                                                border: "1px solid #EBEBEB",
                                                p: 2,
                                                borderRadius: "5px",
                                                backgroundColor: "#FDFDFD",
                                                mb: 2,
                                                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                                                width: "100%", // Full width for all screen sizes
                                            }}
                                            key={item?.idCombinationTickets}

                                        >
                                            <Box
                                                sx={{
                                                    width: { md: "550px", sm: "300px", xs: "100%" }, // Responsive width for different screen sizes
                                                    height: { md: "165px", sm: "150px", xs: "200px" }, // Adjust height for different screen sizes
                                                    borderRadius: "5px",
                                                    overflow: "hidden", // Prevent image overflow
                                                }}
                                            >
                                                <img
                                                    src={item?.image}
                                                    alt="extra-img"
                                                    style={{ width: "100%", height: "100%", objectFit: "cover" }} // Ensure proper scaling of the image
                                                />
                                            </Box>
                                            <Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        mb: 2,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: "#000000",
                                                            fontSize: { md: "20px", sm: "14px" },
                                                            fontWeight: "semiBold",
                                                        }}
                                                    >
                                                        {item?.reservationtype}
                                                        <small
                                                            style={{
                                                                color: "#969BA0",
                                                                fontSize: "10px",
                                                                marginLeft: "0.6rem",
                                                            }}
                                                        >
                                                            before dinner
                                                        </small>
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "#0B5753",
                                                            fontSize: { md: "20px", sm: "14px" },
                                                            fontWeight: "semiBold",
                                                        }}
                                                    >
                                                        €{item?.price}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                                    <Box>
                                                        <Typography sx={{ fontSize: "12px", color: "#969BA0" }}>
                                                            From
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "12px",
                                                                color: "#969BA0",
                                                                backgroundColor: "#F7FFFF",
                                                                border: "1px dashed #0B5753",
                                                                borderRadius: "5px",
                                                                p: 0.6,
                                                            }}
                                                        >
                                                            {item?.fromTime.substring(0, 5)}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ fontSize: "12px", color: "#969BA0" }}>
                                                            To
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "12px",
                                                                color: "#969BA0",
                                                                backgroundColor: "#F7FFFF",
                                                                border: "1px dashed #0B5753",
                                                                borderRadius: "5px",
                                                                p: 0.6,
                                                            }}
                                                        >
                                                            {item?.toTime.substring(0, 5)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Typography sx={{ color: "#969BA0", fontSize: "12px", mt: 2 }}>
                                                    Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc
                                                    consectetur eget erat nec. Molestie mattis adipiscing sit
                                                    condimentum. Pellentesque donec suscipit amet rhoncus eget ac
                                                    purus. Nec diam mauris et aliquet sit commodo sagittis.
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                        mt: 2,
                                                        gap: "1rem",
                                                    }}
                                                >
                                                    <Button
                                                        sx={{
                                                            backgroundColor: "#0B5753",
                                                            fontSize: "12px",
                                                            color: "#fff",
                                                            width: "100px",
                                                        }}
                                                        onClick={() => dispatch(setSelectedCombination(item))}
                                                    >
                                                        Add
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>

                                    ))
                                }


                            </>

                     

                </Grid2>
                <Grid2 size={{ md: 5, sm: 12, xs: 12 }} sx={{ mt: 3 }}>
                    <YourBookingCard
                        bowling={{
                            name: "bowling",
                            value: "€40,00",
                        }}
                    />
                    <PaymentCard />
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default CombinationTicket;