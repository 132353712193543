import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useRef, useState } from "react";
  import Grid from "@mui/material/Grid";
  import SelectComponent from "../../components/SelectComponent/SelectComponent";
  import Addguest from "./Addguest";
  import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
  import lang from "../../assets/lang.png";
  import DateComponent from "./DateComponent";
  import ReservationType from "./ReservationType";
  import TimeComponent from "../horecaWidget/TimeComponent";
  import ReservationGroup from "../horecaWidget/ReservationGroup";
  import { useDispatch, useSelector } from "react-redux";
  import { setReservationTypeDatas, toggleBookNowOpen } from "../../store/slice/Addguest.slice";
  import BookNowForm from "../horecaWidget/BooknowForm";
  import { useLocation, useNavigate, useParams } from "react-router-dom";
  import BGIMG from "../../assets/BG.png";
  import {
    getCalenderDatas,
    getLanguageDetails,
    getReservationType,
    getReservationTypeBasedOnProducts,
    getWidgetSetUpPosition,
    getWidgetSettings,
      getWidgetTitleandInfo,
  } from "../../api/HorecaWidget.service";
  import { CheckCircleTwoTone } from "@mui/icons-material";
  import CheckBoxIcon from "@mui/icons-material/CheckBox";
  import { updateBlockedDates, updateDefaultLanguage, updateFloating, updateWidgetSetting } from "../../store/slice/widgetSetting.slice";
  import dayjs from "dayjs";
  import { useTranslation } from "react-i18next";
  import customParseFormat from 'dayjs/plugin/customParseFormat';
import PickupTime from "./PickupTime";
import ReturnTime from "./ReturnTime";
  dayjs?.extend(customParseFormat); // Extend for custom date format parsing
  
  
  let loadSettings = null;
  let widgetIdSettings = null;
  
  const RentalWidget = ({
    dynamicPosition,
    widgetSetupId,
    buttonPosition,
      widgetPosition,
      floating
  }) => {
  
    const {totalCount,selectedDate,reservType,time,reservGroup,adult,child,kid,baby,toddler,reservationGroupData
  } = useSelector((store) => store.addGuest); 
    const {
      WidgetSetting,
      key
    }=useSelector((store) => store?.widgetSettings)
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const reservationRequestMin = WidgetSetting?.find((item) => item?.tag == 'reservationRequest');
    const navigate = useNavigate();
    const { isBookNowOpen } = useSelector((store) => store.addGuest);
    const dispatch = useDispatch();
    const[reservTypeLoading,setReserveTypeLoading]=useState(false)
    const [isAddGuest, setIsAddGuest] = useState(false);
    const fromDateRef = useRef(null);
    const toDateRef = useRef(null);
    const guestRef = useRef(null);
    const reservationType = useRef(null);
    const fromtimeRef = useRef(null);
    const totimeRef = useRef(null);
    const reservationGroupRef = useRef(null);
    const [isFromDateDropdownOpen, setIsFromDateDropdownOpen] = useState(false);
    const [isToDateDropdownOpen, setIsToDateDropdownOpen] = useState(false);
    const [isReservationType, setIsReservationType] = useState(false);
    const [isFromTimeComponent, setIsFromTimeComponent] = useState(false);
    const [isToTimeComponent, setIsToTimeComponent] = useState(false);
    const [openWidget, setOpenWidget] = useState(location?.state?.openWidgetScreen ?? false);
    //language data
    const [languageData, setLanguageData] = useState([]);
      const [defaultLanguage, setDefaultLanguage] = useState({});
  
    const getWidgetPositionStyle = (position, btn) => {
      switch (position) {
        case "Top-Left":
          return { top: "0", left: "0", transform: "translate(0, 0)" };
        case "Top-Middle":
          return { top: "0", left: "50%", transform: "translateX(-50%)" };
        case "Top-Right":
          return { top: "0", right: "0", transform: "translate(0, 0)" };
        case "Middle-Left":
          return { top: "50%", left: "0", transform: "translateY(-50%)" };
        case "Middle-Center":
          return { top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
        case "Middle-Right":
          return { top: "50%", right: "0", transform: "translateY(-50%)" };
        case "Bottom-Left":
          return { bottom: "0", left: "0", transform: "translate(0, 0)" };
        case "Bottom-Middle":
          return { bottom: "0", left: "50%", transform: "translateX(-50%)" };
        case "Bottom-Right":
          return { bottom: "0", right: "0", transform: "translate(0, 0)" };
        default:
          // Center by default if position not recognized
          if (btn) {
            return { bottom: "0", right: "0", transform: "translate(0, 0)" };
          } else {
            return {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            };
          }
      }
    };
  
    // Handle language selection
    const handleLanguageChange = (event) => {
      const selectedLangCode = event.target.value;
      const selectedLanguage = languageData.find(
        (item) => item.langcode === selectedLangCode
      );
      dispatch(updateDefaultLanguage(selectedLanguage?.langcode))
      setDefaultLanguage(selectedLanguage); // Update state with selected language
      i18n.changeLanguage(selectedLanguage?.langcode);
    };
  
    const fetchLanguageDetails = async () => {
      try {
        const response = await getLanguageDetails();
        setLanguageData(response);
        if (response) {
          const defaultLanguage = response.find((item) => item?.defaultLanguage);
          setDefaultLanguage(defaultLanguage);
          // if (defaultLanguage) {
          //   fetchWidgetSettings(defaultLanguage?.langcode);
          // }
        }
      } catch (error) {
        console.error("Error fetching language details widget:", error?.message);
      }
    };
    const fetchWidgetSettings = async (language) => {
      try {
          const response = await getWidgetSettings(language);
          loadSettings = response;
          dispatch(updateWidgetSetting(response))
      } catch (error) {
        console.error("Error fetching widget settings:", error?.message);
      }
      };
  
  
      const fetchWidgetTitleandInfo = async (widgetSetupId, language) => {
          try {
              const response = await getWidgetTitleandInfo(widgetSetupId, language); 
             widgetIdSettings = response;
          } catch (error) {
              console.error("Error fetching widget settings:", error?.message);
          }
      };
  
  
    const getUnavailableCalenderDetails=async()=>{
       try {
           const response = await getCalenderDatas(key, totalCount, widgetSetupId, reservationRequestMin?.data);
         dispatch(updateBlockedDates(response))
         return response;
       } catch (error) {
         console.error('Error fetching unavailable dates', error);     
       }
     }
  
  const fetchReservationType = async () => {
    setReserveTypeLoading(true); // Start loading
    const parsedDate = dayjs(selectedDate, 'DD-MM-YYYY', true);
    
    // Check if date is valid
    if (!parsedDate.isValid()) {
      setReserveTypeLoading(false); // Stop loading on error
      return;
    }
  
    // Format the valid date into 'YYYY-MM-DD'
    const formattedDate = parsedDate.format('YYYY-MM-DD');
    const payload = {
      idwidgetsetup: parseInt(widgetSetupId),
      amount: parseInt(totalCount),
      date:  formattedDate,
      key: key,
      field2: false,
      field1GuestCount: parseInt(adult),
      field2GuestCount: parseInt(child),
      field3GuestCount: parseInt(kid),
      field4GuestCount: parseInt(baby),
      field5GuestCount: parseInt(toddler),
      reservationRequestMin: parseInt(reservationRequestMin?.data) ?? 0,
      lang: defaultLanguage?.langcode,
    };
  
    try {
      const response = await getReservationType(payload);
  
      if (!response || response.length === 0) {
        throw new Error("No data found in response");
      }
  
      // Iterate over the response data
      const promises = response.map(async (item) => {
        const prodId = item?.idproducthoreca; // Assuming 'idproducthoreca' is the identifier
  
        // Get cost data for the product using prodId
        const productData = await getReservationTypeBasedOnProducts(key, prodId);
  
        // Calculate the total cost for this item using the guest counts
        const totalCost = 
          (productData?.price1 || 0) * parseInt(adult) +
          (productData?.price2 || 0) * parseInt(child) +
            (productData?.price3 || 0) * parseInt(kid) +
            (productData?.price4 || 0) * parseInt(baby) +
          (productData?.price5 || 0) * parseInt(toddler);
  
        // Return the item with the total cost added
        return {
          ...item, // Copy the original item fields
          totalCost, // Add the calculated total cost
        };
      });
  
      // Wait for all promises to complete
      const updatedResponse = await Promise.all(promises);
  
      // Dispatch the updated response with the total costs
      dispatch(setReservationTypeDatas(updatedResponse));
  
    } catch (error) {
      console.log('Error fetching reservation type:', error?.message);
    } finally {
      setReserveTypeLoading(false); // Stop loading after success or error
    }
  };
  
  
  
    useEffect(() => {
      fetchLanguageDetails();
    }, []);
  
    useEffect(()=>{
      if(defaultLanguage.langcode){
        fetchWidgetSettings(defaultLanguage.langcode);
      }
    }, [defaultLanguage.langcode])
  
  
      useEffect(() => {
          if (defaultLanguage.langcode) {
              fetchWidgetTitleandInfo(widgetSetupId, defaultLanguage.langcode);
          }
      }, [defaultLanguage.langcode])
  
  
    useEffect(()=>{
      if(WidgetSetting.length > 0 && totalCount > 0){
        getUnavailableCalenderDetails();
      }
    },[WidgetSetting,totalCount])
  
    useEffect(()=>{
      fetchReservationType()
    },
        [totalCount, adult, child, kid, toddler, defaultLanguage.langcode, selectedDate])
  
  
  
      const send = (frameHeight, frameWidth, top, right, bottom, left, floating, redirectUrl) => {
          window.parent.postMessage({
              reservationIframeHeight: frameHeight,
              reservationIframeWidth: frameWidth,
              reservationIframePositionTop: top,
              reservationIframePositionRight: right,
              reservationIframePositionBottom: bottom,
              reservationIframePositionLeft: left,
              reservationIframePositionFloating: floating,
              redirectUrl: redirectUrl
          }, '*');
      }
  
  
      if (floating === "relative") {
          return (
              send("100vh", "100%", "", "0px", "0px", "", floating),
              <Box
              className="test"
sx={{
  position: "absolute",
  ...getWidgetPositionStyle(widgetPosition, false), // This dynamically changes based on the selected position
  width: "100%", // Adjust dimensions as necessary
  height: "auto", // Auto height for dynamic content
  display: "flex",
                      justifyContent: "center",
                      bottom: "-100px",
                      backgroundColor: "black",
                      top: "unset",
  alignItems: "flex-end",
}}
>
<Box
  sx={{
    width: "100%",
    maxWidth: "1567px",
    padding: { xs: 2, sm: 3 },
  }}
>
  <Box
    sx={{
      display: "flex",
      justifyContent: "flex-end",
      mb: 2,
    }}
  >
    <FormControl
      variant="outlined"
      sx={{
        minWidth: 120,
        backgroundColor: "#fff",
        width: { xs: "100px", md: "120px" },
        height: "45px",
        borderRadius: "5px",
      }}
    >
      <Select
        value={defaultLanguage?.langcode || ""}
        onChange={handleLanguageChange} // Set the onChange handler here
        IconComponent={() => (
          <KeyboardArrowDownIcon sx={{ color: "black", mr: 1 }} />
        )}
        displayEmpty
        renderValue={() => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={lang}
              alt={"lang-img"}
              style={{ width: "40px", marginRight: "10px" }}
            />
          </Box>
        )}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "& .MuiOutlinedInput-input": {
            padding: 1,
            color: "#9F9F9F",
            fontSize: "12px",
          },
        }}
      >
        {languageData?.map((item, index) => (
          <MenuItem key={item?.idlanguage} value={item?.langcode}>
            {item?.langcode == defaultLanguage?.langcode ? (
              <>
                {item?.language}
                <CheckBoxIcon
                  sx={{ color: "green", marginRight: "8px", ml: 2 }}
                />
              </>
            ) : (
              item?.language
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>

  <Grid container spacing={2} sx={{ mb: 3 }}>
    <Grid item xs={12} sm={6} md={4} lg={2}>
      <SelectComponent
        label={t('main.field1')}
        value={totalCount}
        onClick={() => {
          setIsAddGuest(!isAddGuest)
        }}
        ref={guestRef}
      />
      <Addguest
        isOpen={isAddGuest}
        handleClose={() => setIsAddGuest(false)}
        Ref={guestRef.current}
      />
    </Grid>

    <Grid item xs={12} sm={6} md={4} lg={2}>
      <SelectComponent
        label={'From Date'}
        value={"DD-MM-YYYY"}
        onClick={() => setIsFromDateDropdownOpen(!isFromDateDropdownOpen)}
        ref={fromDateRef}
      //  disabled={totalCount > 0 ? false :true}
      />
       <DateComponent
        isOpen={isFromDateDropdownOpen}
        handleClose={() => setIsFromDateDropdownOpen(false)}
        Ref={fromDateRef.current}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={2}>
      <SelectComponent
        label={'To Date'}
        value={"DD-MM-YYYY"}
        onClick={() => setIsFromDateDropdownOpen(!isFromDateDropdownOpen)}
        ref={fromDateRef}
       // disabled={totalCount > 0 ? false :true}
      />
      <DateComponent
        isOpen={isFromDateDropdownOpen}
        handleClose={() => setIsFromDateDropdownOpen(false)}
        Ref={fromDateRef.current}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={1}>
      <SelectComponent
        label={'PickupTime'}
        value={'08:00'}
        onClick={() => setIsFromTimeComponent(!isFromTimeComponent)}
        ref={fromtimeRef}
       // disabled={totalCount > 0 ? false :true}
      />
       <PickupTime
        isOpen={isFromTimeComponent}
        handleClose={() => setIsFromTimeComponent(false)}
        Ref={fromtimeRef.current}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={1}>
      <SelectComponent
        label={'ReturnTime'}
        value={'10:00'}
        onClick={() => setIsToTimeComponent(!isToTimeComponent)}
        ref={totimeRef}
       // disabled={totalCount > 0 ? false :true}
      />
       <ReturnTime
        isOpen={isToTimeComponent}
        handleClose={() => setIsToTimeComponent(false)}
        Ref={totimeRef.current}
      />
   
    </Grid>

    <Grid item xs={12} sm={6} md={4} lg={2}>
      <SelectComponent
        label={'Reservation Type'}
        value={ 'Type'}
        onClick={() => setIsReservationType(!isReservationType)}
        ref={reservationType}
       // disabled={selectedDate.trim() != ""  ? false :true}
      />
     <ReservationType
        isOpen={isReservationType}
        handleClose={() => setIsReservationType(false)}
        Ref={reservationType.current}
        isLoading={reservTypeLoading}

      />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={2}>
      <Button
        variant="contained"
        fullWidth
        sx={{
          height: "70px",
          backgroundColor:'#81D93E',
          color: "#fff",
          fontWeight: "bold",
          display: "flex", // Ensure flexbox is used
          flexDirection: "column", // Align content in a column (Book Now on top, €25,00 below)
          justifyContent: "center", // Center the text vertically
          alignItems: "center", // Center the text horizontally
        }}
        onClick={() => {
            navigate(`/bookNowRental`)
        }}
      >
        {t('main.BtnBookNow')}
       <Box component="span" fontSize="13px" fontWeight="200">
          €{250}
          <Typography sx={{fontSize:"8px",fontWeight:'light'}}>Excluding deposit</Typography>
        </Box>
      </Button>
    </Grid>
  </Grid>

  {isBookNowOpen && <BookNowForm />}
</Box>
</Box>
          )
      }
  
      
  
      // Widget
      return (
          send("100px", "120px", "", "0px", "0px", "", floating)
       ,
      <Box
        sx={{
          width: "100%",
          height: "100vh",
                      display: "flex",
          justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "",
          position: "relative",
        }}
              >
                      <Button
                          sx={{
                              position: "absolute",
                              ...getWidgetPositionStyle(buttonPosition, true),
                              backgroundColor: "#000",
                              zIndex: "99999",
                              m: 2,
                          }}
                          onClick={() =>
                              setOpenWidget(!openWidget)
                          }
                      >
                         Reserve
                      </Button>
                  
                 {openWidget && (
                      send("100vh", "100%", "", "0px", "0px", "", floating),
                      <Box
                          className="test"
            sx={{
              position: "absolute",
              ...getWidgetPositionStyle(widgetPosition, false), // This dynamically changes based on the selected position
              width: "100%", // Adjust dimensions as necessary
              height: "auto", // Auto height for dynamic content
              display: "flex",
                                  justifyContent: "center",
                                  bottom: "-100px",
                                  backgroundColor: "black",
                                  top: "unset",
              alignItems: "flex-end",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "1567px",
                padding: { xs: 2, sm: 3 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: 2,
                }}
              >
                <FormControl
                  variant="outlined"
                  sx={{
                    minWidth: 120,
                    backgroundColor: "#fff",
                    width: { xs: "100px", md: "120px" },
                    height: "45px",
                    borderRadius: "5px",
                  }}
                >
                  <Select
                    value={defaultLanguage?.langcode || ""}
                    onChange={handleLanguageChange} // Set the onChange handler here
                    IconComponent={() => (
                      <KeyboardArrowDownIcon sx={{ color: "black", mr: 1 }} />
                    )}
                    displayEmpty
                    renderValue={() => (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={lang}
                          alt={"lang-img"}
                          style={{ width: "40px", marginRight: "10px" }}
                        />
                      </Box>
                    )}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: 1,
                        color: "#9F9F9F",
                        fontSize: "12px",
                      },
                    }}
                  >
                    {languageData?.map((item, index) => (
                      <MenuItem key={item?.idlanguage} value={item?.langcode}>
                        {item?.langcode == defaultLanguage?.langcode ? (
                          <>
                            {item?.language}
                            <CheckBoxIcon
                              sx={{ color: "green", marginRight: "8px", ml: 2 }}
                            />
                          </>
                        ) : (
                          item?.language
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
  
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <SelectComponent
                    label={t('main.field1')}
                    value={totalCount}
                    onClick={() => {
                      setIsAddGuest(!isAddGuest)
                    }}
                    ref={guestRef}
                  />
                  <Addguest
                    isOpen={isAddGuest}
                    handleClose={() => setIsAddGuest(false)}
                    Ref={guestRef.current}
                  />
                </Grid>
  
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <SelectComponent
                    label={'From Date'}
                    value={"DD-MM-YYYY"}
                    onClick={() => setIsFromDateDropdownOpen(!isFromDateDropdownOpen)}
                    ref={fromDateRef}
                  //  disabled={totalCount > 0 ? false :true}
                  />
                   <DateComponent
                    isOpen={isFromDateDropdownOpen}
                    handleClose={() => setIsFromDateDropdownOpen(false)}
                    Ref={fromDateRef.current}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <SelectComponent
                    label={'To Date'}
                    value={"DD-MM-YYYY"}
                    onClick={() => setIsFromDateDropdownOpen(!isFromDateDropdownOpen)}
                    ref={fromDateRef}
                   // disabled={totalCount > 0 ? false :true}
                  />
                  <DateComponent
                    isOpen={isFromDateDropdownOpen}
                    handleClose={() => setIsFromDateDropdownOpen(false)}
                    Ref={fromDateRef.current}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <SelectComponent
                    label={'PickupTime'}
                    value={'08:00'}
                    onClick={() => setIsFromTimeComponent(!isFromTimeComponent)}
                    ref={fromtimeRef}
                   // disabled={totalCount > 0 ? false :true}
                  />
                   <PickupTime
                    isOpen={isFromTimeComponent}
                    handleClose={() => setIsFromTimeComponent(false)}
                    Ref={fromtimeRef.current}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={1}>
                  <SelectComponent
                    label={'ReturnTime'}
                    value={'10:00'}
                    onClick={() => setIsToTimeComponent(!isToTimeComponent)}
                    ref={totimeRef}
                   // disabled={totalCount > 0 ? false :true}
                  />
                   <ReturnTime
                    isOpen={isToTimeComponent}
                    handleClose={() => setIsToTimeComponent(false)}
                    Ref={totimeRef.current}
                  />
               
                </Grid>
  
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <SelectComponent
                    label={'Reservation Type'}
                    value={ 'Type'}
                    onClick={() => setIsReservationType(!isReservationType)}
                    ref={reservationType}
                   // disabled={selectedDate.trim() != ""  ? false :true}
                  />
                 <ReservationType
                    isOpen={isReservationType}
                    handleClose={() => setIsReservationType(false)}
                    Ref={reservationType.current}
                    isLoading={reservTypeLoading}
  
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      height: "70px",
                      backgroundColor:'#81D93E',
                      color: "#fff",
                      fontWeight: "bold",
                      display: "flex", // Ensure flexbox is used
                      flexDirection: "column", // Align content in a column (Book Now on top, €25,00 below)
                      justifyContent: "center", // Center the text vertically
                      alignItems: "center", // Center the text horizontally
                    }}
                    onClick={() => {
                        navigate(`/bookNowRental`)
                    }}
                  >
                    {t('main.BtnBookNow')}
                   <Box component="span" fontSize="13px" fontWeight="200">
                      €{250}
                      <Typography sx={{fontSize:"8px",fontWeight:'light'}}>Excluding deposit</Typography>
                    </Box>
                  </Button>
                </Grid>
              </Grid>
  
              {isBookNowOpen && <BookNowForm />}
            </Box>
          </Box>
        )}
           </Box>
          
  
    );
  };
  
  
  
  
  
  
  export default RentalWidget;
  