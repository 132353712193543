import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Grid, Popover } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import dayjs from 'dayjs';
import 'dayjs/locale/en';  // Ensure locale is available for formatting
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedDate } from '../../store/slice/Addguest.slice';
import { useTranslation } from 'react-i18next';

const CalendarTicket = ({ isOpen, handleClose, Ref }) => {
  const {selectedDate}=useSelector((store)=>store?.addGuest)
  const availabilityDate = [
    { "availability": "2024-10-20", "price": 250 },
    { "availability": "2024-10-23", "price": 300 },
    { "availability": "2024-10-25", "price": 200 },
    { "availability": "2024-10-28", "price": 275 },
    { "availability": "2024-11-01", "price": 320 },
    { "availability": "2024-11-05", "price": 290 },
    { "availability": "2024-11-10", "price": 310 }
  ];

  const { t } = useTranslation();
  const today = dayjs();  // Get the current date
  const [currentDate, setCurrentDate] = useState(today);
  const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  const daysInMonth = currentDate.daysInMonth();
  const firstDayOfMonth = currentDate.startOf('month').day(); // Day of the week

  const isPastDay = (day) => currentDate.date(day).isBefore(today, 'day');
  const isSelected = (day) => currentDate.date(day).isSame(currentDate, 'day');
  
  const dispatch = useDispatch();

  const handlePrevMonth = () => {
    setCurrentDate(currentDate.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setCurrentDate(currentDate.add(1, 'month'));
  };

  const handleDateSelect = (day) => {
    console.log("day",day)
    const selectedDate = currentDate.date(day).format('DD-MM-YYYY');
    dispatch(updateSelectedDate(selectedDate));
    handleClose();
  };

  // Function to check if a day has availability
  const getAvailabilityForDay = (day) => {
    return availabilityDate.find((item) => dayjs(item.availability).isSame(currentDate.date(day), 'day'));
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ width: { md: '430px', sm: "100%", xs: "100%" }, mx: { sm: "1rem" }, textAlign: 'center', backgroundColor: "#fff", px: 2, paddingBottom: 2 }}>
        {/* Header with arrows and year/month */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
          <IconButton onClick={handlePrevMonth}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {currentDate.format('YYYY')}
          </Typography>
          <IconButton onClick={handleNextMonth}>
            <ArrowForward />
          </IconButton>
        </Box>

        {/* Display Year and Month */}
        <Typography sx={{ marginBottom: 1, color: "#208EF3", fontSize: "24px", fontWeight: 'bold' }}>
          {t(`months.${currentDate.format('MMMM')}`)}
        </Typography>

        {/* Days of the week */}
        <Grid container spacing={1} mt={3}>
          {daysOfWeek.map((day, index) => (
            <Grid item xs={1.71} key={index}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{day}</Typography>
            </Grid>
          ))}
        </Grid>

        {/* Dates Grid */}
        <Grid container spacing={1} sx={{ marginTop: 1 }}>
          {/* Empty cells for days before the first day of the month */}
          {[...Array(firstDayOfMonth)].map((_, index) => (
            <Grid item xs={1.71} key={index} />
          ))}

          {/* Render all days of the month */}
          {[...Array(daysInMonth)].map((_, dayIndex) => {
            const day = dayIndex + 1;
            const isPast = isPastDay(day);
            const isSelectedDay = isSelected(day);
            const availability = getAvailabilityForDay(day); // Get availability for this day

            const isActive = !isPast && availability;

            return (
              <Grid item xs={1.71} key={dayIndex}>
                <Box
                  onClick={() => handleDateSelect(day)}
                  sx={{
                    border: isActive ? '2px dashed #208EF3' : '2px dotted',
                    borderColor: isPast || !isActive ? '#969BA0' : '#208EF3',
                    color: isSelectedDay && isActive ? 'white' : isPast || !isActive ? 'grey' : 'black',
                    backgroundColor: isSelectedDay && isActive ? '#208EF3' : '#FDFDFD' ,
                    cursor: isActive ? 'pointer' : 'default',
                    borderRadius: '8px',
                    padding: 1,
                    textAlign: 'center',
                    transition: 'background-color 0.2s ease',
                    width: "52px",
                    height: "52px",
                    fontWeight: isActive ? 'bold' : 'normal',
                    lineHeight: '1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <Typography variant="body2" sx={{ color: isPast || !isActive ? "#969BA0" : isSelectedDay && isActive ? "white" : '#208EF3', fontSize: "18px" }}>
                    {day}
                  </Typography>
                  {availability && (
                    <Typography variant="caption" sx={{ fontSize: "10px", color: isActive ? "#208EF3" : '#969BA0' }}>
                      €{availability.price}
                    </Typography>
                  )}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Popover>
  );
};

export default CalendarTicket;
