import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import creditCardImg from "../../assets/Credit.png";
import arrowIcon from "../../assets/Arrowdark.png";
import arrowIcon1 from "../../assets/Arrowlight.png";
import IdealIcon from "../../assets/Ideal.png";
import BanchetIcon from "../../assets/Banchet.png";
import cashIcon from "../../assets/cash.png";
import { useNavigate } from "react-router-dom";

// Custom Payment Card component
const CustomPaymentCard = ({
  cardName,
  img,
  icon,
  isSelected,
  onSelect,
  color,
  fw,
}) => {
  return (
    <Box
      onClick={onSelect} // Handle click to select the card
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: { md: "556px", sm: "100%", xs: "100%" }, // Full width for small and extra-small screens
        height: "60px",
        border: `1px ${isSelected ? "dashed" : "solid"} ${
          isSelected ? "#0B5753" : "#EBEBEB"
        }`,
        borderRadius: "5px",
        p: 2,
        backgroundColor: isSelected ? "#F7FFFF" : "#FDFDFD",
        mb: 2,
        cursor: "pointer",
        transition: "all 0.3s ease",
      }}
    >
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <Box>
          <img src={img} alt="card-icon" />
        </Box>
        <Typography
          sx={{
            fontWeight: fw,
            color: isSelected ? "#0B5753" : color,
            transition: "color 0.3s ease",
          }}
        >
          {cardName}
        </Typography>
      </Box>
      <img src={isSelected ? arrowIcon : icon} alt="arrow-icon" />
    </Box>
  );
};

// Confirm and Pay component
const ConfirmandPay = ({textcolor='black'}) => {
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState("Credit Card"); // State to track selected card

  const handleCardSelect = (cardName) => {
    setSelectedCard(cardName); // Update selected card
    navigate('/PaymentSuccess');
  };

  return (
    <Box sx={{ width: "100%" }}> {/* Ensure parent container takes full width */}
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: "medium",
              color: textcolor,
              fontSize: "24px",
              py: 1,
              textAlign: "center",
            }}
          >
            Dinner
          </Typography>
          <Typography
            sx={{
              fontWeight: "medium",
              color: "#000000",
              fontSize: "20px",
              pb: 2,
              textAlign: "center",
            }}
          >
            Restaurant Name
          </Typography>
          <Typography
            sx={{
              fontWeight: "medium",
              color: "#000000",
              fontSize: "16px",
              pb: 2,
              textAlign: "center",
            }}
          >
            €250,00
          </Typography>
        </Box>
      </Box>
      <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: { md: "center", xs: "flex-start" }, // Center content on larger screens, left-align on small
          alignItems: "center",
          flexDirection: "column", // Stack cards vertically
          width: { md: "556px", sm: "100%", xs: "100%" }, // Full width on small and extra-small
        }}
      >
        <CustomPaymentCard
          cardName="Credit Card"
          img={creditCardImg}
          icon={arrowIcon1}
          isSelected={selectedCard === "Credit Card"}
          onSelect={() => handleCardSelect("Credit Card")}
          color="#969BA0"
          fw="regular"
        />
        <CustomPaymentCard
          cardName="Bank Contact"
          img={BanchetIcon}
          icon={arrowIcon1}
          isSelected={selectedCard === "Bank Contact"}
          onSelect={() => handleCardSelect("Bank Contact")}
          color="#969BA0"
          fw="regular"
        />
        <CustomPaymentCard
          cardName="Ideal"
          img={IdealIcon}
          icon={arrowIcon1}
          isSelected={selectedCard === "Ideal"}
          onSelect={() => handleCardSelect("Ideal")}
          color="#969BA0"
          fw="regular"
        />
        <CustomPaymentCard
          cardName="Cash"
          img={cashIcon}
          icon={arrowIcon1}
          isSelected={selectedCard === "Cash"}
          onSelect={() => handleCardSelect("Cash")}
          color="#969BA0"
          fw="regular"
        />
      </Box>
      </Box>
    </Box>
  );
};

export default ConfirmandPay;

