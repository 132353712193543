import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import clock from "../../assets/clock.png";
import { GetOpeningHours } from "../../api/HorecaWidget.service";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SetUpateOpeningHours } from "../../store/slice/Addguest.slice";

const OpeningHoursCalendar = () => {
 const {OpeningHoursWidget}=useSelector((store)=>store?.addGuest)
  const dispatch=useDispatch()
  const { key } = useParams(); // Retrieve the key from the URL
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // For xs and sm
  const isMediumOrLargeScreen = useMediaQuery(theme.breakpoints.up("md")); // For md and lg

  const [selectedYear, setSelectedYear] = useState(2024);
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month()); // Initial current month
  const [selectedDate, setSelectedDate] = useState({
    year: 2024,
    month: dayjs().month(),
    day: null,
  }); // Selected date
  const [selectedOpeningHours,seSelecteOpeningHours] = useState({})
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;



  // Function to get number of days in the current month
  const daysInMonth = (monthIndex, year) => {
    return dayjs(`${year}-${monthIndex + 1}-01`).daysInMonth();
  };

  // Handlers for changing the month and year
  const handlePreviousMonth = () => {
    console.log("selectedMonth",selectedMonth)
    
    if (selectedMonth === 0) {
      setSelectedMonth(11); // Move to December of previous year
      setSelectedYear(selectedYear - 1);
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
  };


  const handleNextMonth = () => {
    if (selectedMonth === 11) {
      setSelectedMonth(0); // Move to January of next year
      setSelectedYear(selectedYear + 1);
    } else {
      setSelectedMonth(selectedMonth+1);
    }
  };

  // Handler for date selection, only for the selected month and year
  const handleDateClick = (day, monthIndex, year) => {
    setSelectedDate({ year, month: monthIndex, day });
  };
  const getDayWithSuffix = (day) => {
    if (day > 3 && day < 21) return `${day}th`; // For 11th to 20th
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  const formatDate = ({ year, month, day }) => {
    const date = dayjs(`${year}-${month}-${day}`);
    const dayWithSuffix = getDayWithSuffix(day);
    const formattedMonth = date.format("MMMM");
    return `${dayWithSuffix} ${formattedMonth} ${year}`;
  };

const renderCalendar = (monthIndex, year) => {
    // Helper function to format date as 'YYYY-MM-DD' for matching
    const formatDateForComparison = (day) => {
      return `${year}-${(monthIndex + 1).toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
    };
  
    // Get the day of the week the first day of the month falls on (adjusted so Monday is the first day)
    let firstDayOfMonth = new Date(year, monthIndex, 1).getDay();
    firstDayOfMonth = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1; // Adjust so Monday is 0, Sunday is 6
  
    // Calculate the number of days in the month
    const totalDaysInMonth = daysInMonth(monthIndex, year);
  
    return (
      <Box
        sx={{
          width: { md: "348px", sm: "100%" },
          minHeight: "450px",
          border: "1px solid #EBEBEB",
          padding: "16px",
          borderRadius: "8px",
          marginBottom: 2,
          backgroundColor: "#FFFFFF",
          mx: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: 1,
            color: "#F37120",
            fontSize: "20px",
            fontWeight: "medium",
          }}
        >
          {months[monthIndex]} {year}
        </Typography>
  
        {/* Days of the Week Row */}
        <Grid container spacing={3}>
          {["M", "T", "W", "T", "F", "S", "S"].map((day, index) => (
            <Grid item xs={1.71} md={1.71} key={index}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "medium",
                  color: "#000000", // Optional color for day labels
                  fontSize: "16px",
                }}
              >
                {day}
              </Typography>
            </Grid>
          ))}
        </Grid>
  
        {/* Dates Grid */}
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          {/* Add empty grid items to align the first day of the month */}
          {Array.from({ length: firstDayOfMonth }).map((_, index) => (
            <Grid item xs={1.71} md={1.71} key={`empty-${index}`} />
          ))}
  
          {/* Render the actual days of the month */}
          {Array.from({ length: totalDaysInMonth }, (_, day) => {

            const formattedDate = formatDateForComparison(day + 1);
            const isOpeningDate = OpeningHoursWidget?.some(
              (item) => dayjs(item.openingDate).format('YYYY-MM-DD') == formattedDate
            );
            const currentDate = new Date();
            const isPast =
              new Date(year, monthIndex, day + 1) <
              new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate()
              );
            const dayStyles = isOpeningDate
              ? {
                  border: "1px dashed #F37120",
                  backgroundColor: "#FFF8F6",
                  color: "#F37120",
                  cursor: "pointer",
                }
                :
              {
                  border: "1px dashed #969BA0" ,
                  backgroundColor:"#FDFDFD",
                  color:  "#969BA0",
                  cursor:  "default",
                }
          
            return (
              <Grid item xs={1.71} md={1.71} key={day}>
                <Box
                  onClick={(event) => {
                    if (!isOpeningDate || isPast) {
                      return;
                    }
                    setAnchorEl(event.currentTarget);
                    handleDateClick(day + 1, monthIndex + 1, year);
                  }} // `day + 1` for 1-based days
                  sx={{
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    ...dayStyles,
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: isOpeningDate && !isPast ? "#f0f0f0" : "",
                    },
                  }}
                >
                  {day + 1}
                </Box>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  PaperProps={{
                    sx: {
                      boxShadow: "none", // Removes any shadow
                      backgroundColor: "#fff", // Removes any background color, if necessary
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: "129px",
                      px: 2,
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 1,
                        gap: "1rem",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#F37120",
                          fontSize: "16px",
                          fontWeight: "medium",
                        }}
                      >
                        {formatDate(selectedDate)}
                      </Typography>
                      <IconButton
                        aria-label="close"
                        size="small"
                        sx={{
                          border: "1px solid #969BA0 ",
                          borderRadius: "5px",
                          width: 17,
                          height: 17,
                        }}
                      >
                        <CloseIcon
                          fontSize="inherit"
                          color="#969BA0"
                          onClick={handleClose}
                        />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{ color: "#969BA0", fontWeight: "medium", fontSize: "8px" }}
                        >
                          Open From
                        </Typography>
                        <Box sx={{ display: "flex", gap: "0.3rem", alignItems: "center" }}>
                          <Box sx={{ width: "16px", height: "16px" }}>
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={clock}
                              alt="time"
                            />
                          </Box>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: "medium",
                              fontSize: "10px",
                              mt: 0.3,
                            }}
                          >
                            {selectedOpeningHours?.openTime?.slice(0,5)}

                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{ color: "#969BA0", fontWeight: "medium", fontSize: "8px" }}
                        >
                          Open To
                        </Typography>
                        <Box sx={{ display: "flex", gap: "0.3rem", alignItems: "center" }}>
                          <Box sx={{ width: "16px", height: "16px" }}>
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={clock}
                              alt="time"
                            />
                          </Box>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontWeight: "medium",
                              fontSize: "10px",
                              mt: 0.3,
                            }}
                          >
                            {selectedOpeningHours?.closeTime?.slice(0,5)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Button sx={{ backgroundColor: "#F37120", color: "#fff", mt: 2 }} onClick={()=>alert('Horeca widget')}>
                        Booking Reservation
                      </Button>
                    </Box>
                  </Box>
                </Popover>
 

              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const getMaxOpeningDate = (openingHoursWidget) => {
    const latestOpeningDate = openingHoursWidget
      .map(widget => dayjs(widget.openingDate))
      .reduce((maxDate, currentDate) => currentDate.isAfter(maxDate) ? currentDate : maxDate, dayjs(0)); // Start with the earliest date
    
    return { month: latestOpeningDate.month(), year: latestOpeningDate.year() }; // month() returns 0-based index
  };
  const { month: maxMonth, year: maxYear } = getMaxOpeningDate(OpeningHoursWidget);
  const isForwardButtonDisabled = selectedYear > maxYear || (selectedYear === maxYear && selectedMonth >= maxMonth);
  

  // Array of months to display based on screen size
  const monthsToShow = isSmallScreen
  ? [{ month: selectedMonth, year: selectedYear }]
  : [
      { month: selectedMonth, year: selectedYear },
      {
        month: (selectedMonth + 1) % 12, // Move to the next month
        year: selectedMonth === 11 ? selectedYear + 1 : selectedYear, // Handle year transition when moving from December
      },
      {
        month: (selectedMonth + 2) % 12, // Move to the month after next
        year: selectedMonth >= 10 ? selectedYear + 1 : selectedYear, // Handle year transition for November and December
      },
    ];
  
      
      const fetchOpeningHours=async()=>{
        try {
            const response =await GetOpeningHours(key);
           
            dispatch(SetUpateOpeningHours(response))
            
        } catch (error) {
            console.error("Error fetching opening hours:", error.message);   
        }
    }
    useEffect(()=>{
        let tempOpeningHours= [...OpeningHoursWidget]
        const selectDate=selectedDate?.day <=9 ? `${selectedDate.year}-${selectedDate?.month}-0${selectedDate?.day}` : `${selectedDate.year}-${selectedDate?.month}-${selectedDate?.day}`
        const indviudualOpeningHours=tempOpeningHours?.filter((item)=>dayjs(item?.openingDate).format('YYYY-MM-DD') == selectDate )[0]
        seSelecteOpeningHours(indviudualOpeningHours)
    },[selectedDate])
    useEffect(()=>{
        fetchOpeningHours()
    },[])

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper
        elevation={1}
        sx={{
          backgroundColor: "#EBEBEB",
          mx: 2,
          width: "1170px",
          my: 3,
        }}
      >
        <Box sx={{ textAlign: "center", padding: "16px" }}>
          {/* Heading */}
          <Typography
            sx={{
              marginBottom: 2,
              fontSize: "24px",
              fontWeight: "medium",
              color: "#000000",
            }}
          >
            Opening Hours Calendar
          </Typography>

          {/* Year & Month Navigation */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <IconButton aria-label="back" onClick={handlePreviousMonth}   disabled={
    selectedYear < dayjs().year() ||
    (selectedYear === dayjs().year() && selectedMonth <= dayjs().month())
  }
>
              <ArrowBack color="#969BA0" />
            </IconButton>
            <Typography variant="h6" sx={{ margin: "0 16px" }}>
              {selectedYear}
            </Typography>

            <IconButton aria-label="forward" onClick={handleNextMonth} disabled={isForwardButtonDisabled} >
              <ArrowForward color={isForwardButtonDisabled ? "disabled" : "#969BA0"}  />
            </IconButton>
          </Box>

          {/* Filter Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 2,
              gap: "1rem",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "#969BA0",
                backgroundColor: "#FDFDFD",
                borderRadius: "5.5px",
                textTransform: "none",
                border: "1px dashed #969BA0",
              }}
             // onClick={() => handleFilterChange("Closed")}
            >
              Close
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "#F37120",
                backgroundColor: "#FFF8F6",
                borderRadius: "5.5px",
                textTransform: "none",
                border: "1px dashed #F37120",
              }}
              //onClick={() => handleFilterChange("Availability")}
            >
              Available
            </Button>
          </Box>

          {/* Calendar View */}
          <Grid container spacing={2}>
          {monthsToShow.map(({ month, year }, idx) => (
              <Grid item xs={12} md={4} key={idx}>
                {renderCalendar(month, year)}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default OpeningHoursCalendar;
