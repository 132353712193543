import * as React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import GradeRoundedIcon from "@mui/icons-material/GradeRounded";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const labels = {
  0.5: "Poor",
  1: "Poor",
  1.5: "Poor",
  2: "Poor",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function RatingComponent({ data, name, setField }) {
  const dispatch = useDispatch();
  const { addFields } = useSelector((store) => store?.feedback);
  const [value, setValue] = React.useState(0);
  const [hover, setHover] = React.useState(-1);

  useEffect(() => {
    const existingField = addFields.find((field) => field.name === name);
    if (existingField) {
      setValue(existingField.rating);
    }
  }, [addFields, name]);

  const handleChange = (newValue) => {
    setValue(newValue);

    const updatedFields = addFields.map((field) => {
      if (field.name === name) {
        return { ...field, rating: newValue, label: labels[newValue] };
      }
      return field;
    });

    if (!addFields.some((field) => field.name === name)) {
      updatedFields.push({
        idFields: updatedFields.length + 1,
        name,
        rating: newValue,
        label: labels[newValue]
      });
    }

    dispatch(setField(updatedFields));
  };

  return (
    <Box sx={{ width: "100%", mx: "auto" }}>
      <Rating
        name={name}
        value={value}
        precision={0.5}
        getLabelText={getLabelText}
        onChange={(event, newValue) => handleChange(newValue)}
        onChangeActive={(event, newHover) => setHover(newHover)}
        icon={<GradeRoundedIcon style={{ width: 42, height: 42 }} fontSize="inherit" />}
        emptyIcon={<StarOutlineRoundedIcon style={{ opacity: 0.7, width: 42, height: 42 }} fontSize="inherit" />}
        sx={{
          mt: 3,
          gap: 0.5, // Reduced gap for closer stars
        }}
      />
      {value !== null && (
        <Box sx={{ display: "flex", justifyContent: hover <= 2.5 ? "flex-start" : "flex-end", alignItems: "center" }}>
          <Typography sx={{ color: "#969BA0", fontSize: "14px" }}>
            {labels[hover !== -1 ? hover : value]}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

