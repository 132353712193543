import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Grid, Popover, Checkbox, FormControlLabel } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedDate } from '../../store/slice/Addguest.slice';
import { useTranslation } from 'react-i18next';

const Calendar = ({ isOpen, handleClose, Ref }) => {
  const { t } = useTranslation();
  const { blockedDates } = useSelector((store) => store?.widgetSettings);
  const { selectedDates } = blockedDates ?? '';
  const today = dayjs();  // Get the current date
  const [currentDate, setCurrentDate] = useState(today);
  const [isMultipleDays, setIsMultipleDays] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);

  const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const daysInMonth = currentDate.daysInMonth();
  const firstDayOfMonth = currentDate.startOf('month').day(); // Day of the week

  const isToday = (day) => dayjs().isSame(currentDate.date(day), 'day');
  const isPastDay = (day) => currentDate.date(day).isBefore(today, 'day');
  const isSelected = (day, date) => currentDate.date(day).isSame(date, 'day');
  const dispatch = useDispatch();

  const handlePrevMonth = () => {
    setCurrentDate(currentDate.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setCurrentDate(currentDate.add(1, 'month'));
  };

  const handleDateSelect = (day) => {
    const selectedDate = currentDate.date(day);

    if (!isPastDay(day)) {
      if (isMultipleDays) {
        if (!selectedFromDate || (selectedFromDate && selectedToDate)) {
          // Reset the dates and start selecting again if both dates are already selected
          setSelectedFromDate(selectedDate);
          setSelectedToDate(null);
        } else if (selectedDate.isAfter(selectedFromDate)) {
          setSelectedToDate(selectedDate);
        } else {
          // If the selected date is before the "From" date, reset the "From" date
          setSelectedFromDate(selectedDate);
          setSelectedToDate(null);
        }
      } else {
        setSelectedFromDate(selectedDate);
        setSelectedToDate(null);
        dispatch(updateSelectedDate(selectedDate.format('DD-MM-YYYY')));
        handleClose();
      }
    }
  };

  const handleMultipleDaysChange = (event) => {
    setIsMultipleDays(event.target.checked);
    setSelectedFromDate(null); // Reset dates when switching between single and multiple day mode
    setSelectedToDate(null);
  };

  const resetDates = () => {
    setSelectedFromDate(null);
    setSelectedToDate(null);
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ width: { md: '430px', sm: '100%', xs: '100%' }, mx: { sm: '1rem' }, textAlign: 'center', backgroundColor: '#fff', px: 2, paddingBottom: 2 }}>
        {/* Header with arrows and year/month */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
          <IconButton onClick={handlePrevMonth}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {currentDate.format('YYYY')}
          </Typography>
          <IconButton onClick={handleNextMonth}>
            <ArrowForward />
          </IconButton>
        </Box>

        {/* Display Year and Month */}
        <Typography sx={{ marginBottom: 1, color: '#81D93E', fontSize: '24px', fontWeight: 'bold' }}>
          {t(`months.${currentDate.format('MMMM')}`)}
        </Typography>

        {/* Days of the week */}
        <Grid container spacing={1} mt={3}>
          {daysOfWeek.map((day, index) => (
            <Grid item xs={1.71} key={index}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{day}</Typography>
            </Grid>
          ))}
        </Grid>

        {/* Dates Grid */}
        <Grid container spacing={1} sx={{ marginTop: 1 }}>
          {/* Empty cells for days before the first day of the month */}
          {[...Array(firstDayOfMonth)].map((_, index) => (
            <Grid item xs={1.71} key={index} />
          ))}

          {/* Render all days of the month */}
          {[...Array(daysInMonth)].map((_, dayIndex) => {
            const day = dayIndex + 1;
            const isPast = isPastDay(day);
            const isSelectedDay = isSelected(day, selectedFromDate) || isSelected(day, selectedToDate);
            const isActive = !isPast;  // Disable only past days

            return (
              <Grid item xs={1.71} key={dayIndex}>
                <Box
                  onClick={() => isActive && handleDateSelect(day)}  // Only allow clicks on active days
                  sx={{
                    border: '2px dotted',
                    borderColor: isPast ? '#969BA0' : '#81D93E',
                    color: isSelectedDay && isActive ? 'white' : isPast ? 'grey' : 'black',
                    backgroundColor: isSelectedDay && isActive ? '#81D93E' : isPast ? '#FDFDFD' : '#F7FFFF',
                    cursor: isActive ? 'pointer' : 'default',
                    borderRadius: '8px',
                    padding: 1,
                    textAlign: 'center',
                    transition: 'background-color 0.2s ease',
                    width: '40px',
                    height: '40px',
                    fontWeight: isActive ? 'bold' : 'normal',
                    lineHeight: '1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="body2" sx={{ color: isPast ? '#969BA0' : isSelectedDay && isActive ? '#fff' : '#3E4954', fontSize: '22px' }}>
                    {day}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {/* Checkbox for booking multiple days */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 2 }}>
        <FormControlLabel
          control={<Checkbox checked={isMultipleDays} onChange={handleMultipleDaysChange}  sx={{
            color: '#81D93E',  // Unchecked color
            '&.Mui-checked': {
              color: '#81D93E',  // Checked color
            },
          }}/>}
          label={t('Book for multiple days')}
          sx={{ marginTop: 2 }}
        />

        </Box>
   

 

        {/* Reset button */}
        {isMultipleDays && (selectedFromDate || selectedToDate) && (
          <Box sx={{ marginTop: 2 }}>
            <Typography
              variant="body2"
              onClick={resetDates}
              sx={{ cursor: 'pointer', color: '#007BFF', textDecoration: 'underline' }}
            >
              {t('Reset Dates')}
            </Typography>
          </Box>
        )}
      </Box>
    </Popover>
  );
};

export default Calendar;
