import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useRef, useState } from "react";
  import Grid from "@mui/material/Grid";
  import SelectComponent from "../../components/SelectComponent/SelectComponent";
  import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
  import lang from "../../assets/lang.png";
  import { useDispatch, useSelector } from "react-redux";
  import { useLocation, useNavigate, useParams } from "react-router-dom";
  import CheckBoxIcon from "@mui/icons-material/CheckBox";
  import {
    updateBlockedDates,
    updateDefaultLanguage,
    updateFloating,
    updateWidgetSetting,
  } from "../../store/slice/widgetSetting.slice";
  import dayjs from "dayjs";
  import { useTranslation } from "react-i18next";
  import customParseFormat from "dayjs/plugin/customParseFormat";
  import calenderImg from "../../assets/salon-calender.png";
import DateComponent from "./DateComponent";
import TimeComponent from "./TimeComponent";


import GenderComponent from "./GenderComponent";
import TreatmentType from "./TreatmentType";
import StylishComponent from "./StylishComponent";
  dayjs?.extend(customParseFormat); // Extend for custom date format parsing
  
  let loadSettings = null;
  let widgetIdSettings = null;
  
  const SalonWidget = ({
    dynamicPosition,
    widgetSetupId,
    buttonPosition,
    widgetPosition,
    floating,
  }) => {
    const {
      totalCount,
      selectedDate,
      reservType,
      time,
      reservGroup,
      adult,
      child,
      kid,
      baby,
      toddler,
      reservationGroupData,
    } = useSelector((store) => store.addGuest);
    const { WidgetSetting, key } = useSelector((store) => store?.widgetSettings);
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const reservationRequestMin = WidgetSetting?.find(
      (item) => item?.tag == "reservationRequest"
    );
    const navigate = useNavigate();
    const { isBookNowOpen } = useSelector((store) => store.addGuest);
    const dispatch = useDispatch();
    const [reservTypeLoading, setReserveTypeLoading] = useState(false);
    const [isAddGuest, setIsAddGuest] = useState(false);
    const[isLesson,setIsLesson]=useState(false);
    const genderRef=useRef()
    const treatmentRef = useRef(null);
    const stylishRef = useRef(null);
    const calenderRef = useRef(null);
    const timeRef = useRef(null);
    const [isGender, setIsGenderOpen] = useState(false);
    const [isTreatment, setIsTreatmentOpen] = useState(false);
    const [isTimeComponent, setIsTimeComponent] = useState(false);
    const [isDate, setIsDateOpen] = useState(false);
    const [isStylish, setIsStylishOpen] = useState(false);
    const [openWidget, setOpenWidget] = useState(
      location?.state?.openWidgetScreen ?? false
    );
    //language data
    const [languageData, setLanguageData] = useState([]);
    const [defaultLanguage, setDefaultLanguage] = useState({});
  
    const getWidgetPositionStyle = (position, btn) => {
      switch (position) {
        case "Top-Left":
          return { top: "0", left: "0", transform: "translate(0, 0)" };
        case "Top-Middle":
          return { top: "0", left: "50%", transform: "translateX(-50%)" };
        case "Top-Right":
          return { top: "0", right: "0", transform: "translate(0, 0)" };
        case "Middle-Left":
          return { top: "50%", left: "0", transform: "translateY(-50%)" };
        case "Middle-Center":
          return { top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
        case "Middle-Right":
          return { top: "50%", right: "0", transform: "translateY(-50%)" };
        case "Bottom-Left":
          return { bottom: "0", left: "0", transform: "translate(0, 0)" };
        case "Bottom-Middle":
          return { bottom: "0", left: "50%", transform: "translateX(-50%)" };
        case "Bottom-Right":
          return { bottom: "0", right: "0", transform: "translate(0, 0)" };
        default:
          // Center by default if position not recognized
          if (btn) {
            return { bottom: "0", right: "0", transform: "translate(0, 0)" };
          } else {
            return {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            };
          }
      }
    };
  
    // Handle language selection
    const handleLanguageChange = (event) => {
      const selectedLangCode = event.target.value;
      const selectedLanguage = languageData.find(
        (item) => item.langcode === selectedLangCode
      );
      dispatch(updateDefaultLanguage(selectedLanguage?.langcode));
      setDefaultLanguage(selectedLanguage); // Update state with selected language
      i18n.changeLanguage(selectedLanguage?.langcode);
    };



    const send = (
      frameHeight,
      frameWidth,
      top,
      right,
      bottom,
      left,
      floating,
      redirectUrl
    ) => {
      window.parent.postMessage(
        {
          reservationIframeHeight: frameHeight,
          reservationIframeWidth: frameWidth,
          reservationIframePositionTop: top,
          reservationIframePositionRight: right,
          reservationIframePositionBottom: bottom,
          reservationIframePositionLeft: left,
          reservationIframePositionFloating: floating,
          redirectUrl: redirectUrl,
        },
        "*"
      );
    };
  
    // if (floating === "relative") {
    //   return (
    //     send("100vh", "100%", "", "0px", "0px", "", floating),
    //     (
    //         <Box
    //         className="test"
    //         sx={{
    //           position: "absolute",
    //           ...getWidgetPositionStyle(widgetPosition, false), // This dynamically changes based on the selected position
    //           width: "100%", // Adjust dimensions as necessary
    //           height: "auto", // Auto height for dynamic content
    //           display: "flex",
    //           justifyContent: "center",
    //           bottom: "-100px",
    //           backgroundColor: "black",
    //           top: "unset",
    //           alignItems: "flex-end",
    //         }}
    //       >
    //         <Box
    //           sx={{
    //             width: "100%",
    //             maxWidth: "1567px",
    //             padding: { xs: 2, sm: 3 },
    //           }}
    //         >
    //           <Box
    //             sx={{
    //               display: "flex",
    //               justifyContent: "flex-end",
    //               mb: 2,
    //             }}
    //           >
    //             <FormControl
    //               variant="outlined"
    //               sx={{
    //                 minWidth: 120,
    //                 backgroundColor: "#fff",
    //                 width: { xs: "100px", md: "120px" },
    //                 height: "45px",
    //                 borderRadius: "5px",
    //               }}
    //             >
    //               <Select
    //                 value={defaultLanguage?.langcode || ""}
    //                 onChange={handleLanguageChange} // Set the onChange handler here
    //                 IconComponent={() => (
    //                   <KeyboardArrowDownIcon sx={{ color: "black", mr: 1 }} />
    //                 )}
    //                 displayEmpty
    //                 renderValue={() => (
    //                   <Box sx={{ display: "flex", alignItems: "center" }}>
    //                     <img
    //                       src={lang}
    //                       alt={"lang-img"}
    //                       style={{ width: "40px", marginRight: "10px" }}
    //                     />
    //                   </Box>
    //                 )}
    //                 sx={{
    //                   "& .MuiOutlinedInput-notchedOutline": {
    //                     borderColor: "transparent",
    //                   },
    //                   "&:hover .MuiOutlinedInput-notchedOutline": {
    //                     borderColor: "transparent",
    //                   },
    //                   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //                     borderColor: "transparent",
    //                   },
    //                   "& .MuiOutlinedInput-input": {
    //                     padding: 1,
    //                     color: "#9F9F9F",
    //                     fontSize: "12px",
    //                   },
    //                 }}
    //               >
    //                 {languageData?.map((item, index) => (
    //                   <MenuItem key={item?.idlanguage} value={item?.langcode}>
    //                     {item?.langcode == defaultLanguage?.langcode ? (
    //                       <>
    //                         {item?.language}
    //                         <CheckBoxIcon
    //                           sx={{
    //                             color: "green",
    //                             marginRight: "8px",
    //                             ml: 2,
    //                           }}
    //                         />
    //                       </>
    //                     ) : (
    //                       item?.language
    //                     )}
    //                   </MenuItem>
    //                 ))}
    //               </Select>
    //             </FormControl>
    //           </Box>
    //           <Box
    //             sx={{
    //               display: "flex",
    //               justifyContent: "center",
    //               alignItems: "center",
              
    //             }}
    //           >
    //             <Grid container spacing={2} sx={{ mb: 3 }} justifyContent={'center'} alignItems={'center'}>
    //             <Grid item xs={12} sm={6} md={4} lg={2} >
    //                 <SelectComponent
    //                   label={'Event'}
    //                   value={'Marathon Run'}
    //                   onClick={() => {
    //                    setIsEvent(!isEvent);
    //                   }}
    //                   ref={eventRef}
    //                  borderColor={'#92FC8B'}
    //                 />
    //                 <EventComponent
    //                   isOpen={isEvent}
    //                   handleClose={() => setIsEvent(false)}
    //                   Ref={eventRef.current}
    //                 />
    //               </Grid>
    //               <Grid item xs={12} sm={6} md={4} lg={2}>
    //                 <SelectComponent
    //                   label={"Reservation Type"}
    //                   value={"Running"}
    //                   onClick={() =>
    //                     setIsTypeDropdownOpen(!isTypeDropdownOpen)
    //                   }
    //                   ref={typeRef}
    //                  // disabled={totalCount > 0 ? false : true}
    //                  borderColor={'#92FC8B'}
    //                 />
    //                 <ReservationType
    //               isOpen={isTypeDropdownOpen}
    //               handleClose={() =>  setIsTypeDropdownOpen(false)}
    //               Ref={typeRef.current}
    //             />
    //               </Grid>
    //               <Grid item xs={12} sm={6} md={4} lg={2} >
    //                 <SelectComponent
    //                   label={t("main.field1")}
    //                   value={totalCount}
    //                   onClick={() => {
    //                     setIsAddGuest(!isAddGuest);
    //                   }}
    //                   ref={guestRef}
    //                   borderColor={'#92FC8B'}
    //                 />
    //                 <Addguest
    //                   isOpen={isAddGuest}
    //                   handleClose={() => setIsAddGuest(false)}
    //                   Ref={guestRef.current}
    //                 />
    //               </Grid>

            

    //               <Grid item xs={12} sm={6} md={4} lg={2}>
    //                 <SelectComponent
    //                   label={"Date"}
    //                   value={"30 Sep 2024 "}
    //                   onClick={() => setIsCalenderType(!isCalenderType)}
    //                   ref={calenderType}
                    
    //                   icon={calenderImg}
    //                   isIcon={true}
    //                   borderColor={'#92FC8B'}
    //                 />
    //                 <DateComponent
    //               isOpen={isCalenderType}
    //               handleClose={() => setIsCalenderType(false)}
    //               Ref={calenderType.current}
    //               //isLoading={reservTypeLoading}

    //             />

    //               </Grid>
    //               <Grid item xs={12} sm={6} md={4} lg={2}>
    //                 <SelectComponent
    //                   label={"Time"}
    //                   value={"10:00 PM"}
    //                   onClick={() => setIsTimeComponent(!isTimeComponent)}
    //                   ref={timeRef}
    //                 //  disabled={reservType && typeof reservType === 'object' && Object.keys(reservType).length > 0 ? false : true}
    //                  // icon={calenderImg}
    //                  // isIcon={true}
    //                  borderColor={'#92FC8B'}
    //                 />
    //                 <TimeComponent
    //               isOpen={isTimeComponent}
    //               handleClose={() => setIsTimeComponent(false)}
    //               Ref={timeRef.current}
    //               //isLoading={reservTypeLoading}

    //             />
    //               </Grid>
    //               <Grid item xs={12} sm={6} md={4} lg={2}>
    //                 <Button
    //                   variant="contained"
    //                   fullWidth
    //                   sx={{
    //                     height: "70px",
    //                     backgroundColor:'#92FC8B',
                         
    //                     color: "#076D00",
    //                     fontWeight: "bold",
    //                     display: "flex", // Ensure flexbox is used
    //                     flexDirection: "column", // Align content in a column (Book Now on top, €25,00 below)
    //                     justifyContent: "center", // Center the text vertically
    //                     alignItems: "center", // Center the text horizontally
    //                   }}
    //                   onClick={() => {
                        
    //                       navigate(`/bookNowEvents`);
                        
                        
    //                   }}
    //                 >
    //                   {t("main.BtnBookNow")}
    //                   <Box component="span" fontSize="13px" fontWeight="200" color='#076D00'>
    //                     €250
    //                   </Box>
    //                 </Button>
    //               </Grid>
    //             </Grid>
    //           </Box>
    //         </Box>
    //       </Box>
    //     )
    //   );
    // }
  
    // Widget
    return (
      send("100px", "120px", "", "0px", "0px", "", floating),
      (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "",
            position: "relative",
          }}
        >
          <Button
            sx={{
              position: "absolute",
              ...getWidgetPositionStyle(buttonPosition, true),
              backgroundColor: "#000",
              zIndex: "99999",
              m: 2,
            }}
            onClick={() => setOpenWidget(!openWidget)}
          >
            Reserve
          </Button>
  
          {openWidget &&
            (send("100vh", "100%", "", "0px", "0px", "", floating),
            (
              <Box
                className="test"
                sx={{
                  position: "absolute",
                  ...getWidgetPositionStyle(widgetPosition, false), // This dynamically changes based on the selected position
                  width: "100%", // Adjust dimensions as necessary
                  height: "auto", // Auto height for dynamic content
                  display: "flex",
                  justifyContent: "center",
                  bottom: "-100px",
                  backgroundColor: "black",
                  top: "unset",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "1567px",
                    padding: { xs: 2, sm: 3 },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mb: 2,
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      sx={{
                        minWidth: 120,
                        backgroundColor: "#fff",
                        width: { xs: "100px", md: "120px" },
                        height: "45px",
                        borderRadius: "5px",
                      }}
                    >
                      <Select
                        value={defaultLanguage?.langcode || ""}
                        onChange={handleLanguageChange} // Set the onChange handler here
                        IconComponent={() => (
                          <KeyboardArrowDownIcon sx={{ color: "black", mr: 1 }} />
                        )}
                        displayEmpty
                        renderValue={() => (
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              src={lang}
                              alt={"lang-img"}
                              style={{ width: "40px", marginRight: "10px" }}
                            />
                          </Box>
                        )}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: 1,
                            color: "#9F9F9F",
                            fontSize: "12px",
                          },
                        }}
                      >
                        {languageData?.map((item, index) => (
                          <MenuItem key={item?.idlanguage} value={item?.langcode}>
                            {item?.langcode == defaultLanguage?.langcode ? (
                              <>
                                {item?.language}
                                <CheckBoxIcon
                                  sx={{
                                    color: "green",
                                    marginRight: "8px",
                                    ml: 2,
                                  }}
                                />
                              </>
                            ) : (
                              item?.language
                            )}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                  
                    }}
                  >
                    <Grid container spacing={2} sx={{ mb: 3 }} justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={12} sm={6} md={4} lg={2} >
                        <SelectComponent
                          label={'Gender'}
                          value={'Male'}
                          onClick={() => {
                           setIsGenderOpen(!isGender);
                          }}
                          ref={genderRef}
                        
                        />
                        <GenderComponent
                          isOpen={isGender}
                          handleClose={() => setIsGenderOpen(false)}
                          Ref={genderRef.current}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <SelectComponent
                          label={"Treatment"}
                          value={"Haircut"}
                          onClick={() =>
                            setIsTreatmentOpen(!isTreatment)
                          }
                          ref={treatmentRef}
                         // disabled={totalCount > 0 ? false : true}
                        
                        />
                        <TreatmentType
                      isOpen={isTreatment}
                      handleClose={() =>  setIsTreatmentOpen(false)}
                      Ref={treatmentRef.current}
                    />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2} >
                        <SelectComponent
                          label={'Stylish'}
                          value={'Jhon'}
                          onClick={() => {
                            setIsStylishOpen(!isStylish)
                            
                          }}
                          ref={stylishRef}
                         
                        />
                        <StylishComponent
                          isOpen={isStylish}
                          handleClose={() => setIsStylishOpen(false)}
                          Ref={stylishRef.current}
                        />
                      </Grid>
  
                
  
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <SelectComponent
                          label={"Date"}
                          value={"Dec 30,2024"}
                          onClick={() => setIsDateOpen(!isDate)}
                          ref={calenderRef}
                          icon={calenderImg}
                          isIcon={true}   
                        />
                        <DateComponent
                      isOpen={isDate}
                      handleClose={() => setIsDateOpen(false)}
                      Ref={calenderRef.current}
                      //isLoading={reservTypeLoading}
    
                    />
 
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <SelectComponent
                          label={"Time"}
                          value={"10:00 PM"}
                          onClick={() => setIsTimeComponent(!isTimeComponent)}
                          ref={timeRef}
                        //  disabled={reservType && typeof reservType === 'object' && Object.keys(reservType).length > 0 ? false : true}
                         // icon={calenderImg}
                         // isIcon={true}
                        
                        />
                        <TimeComponent
                      isOpen={isTimeComponent}
                      handleClose={() => setIsTimeComponent(false)}
                      Ref={timeRef.current}
                      //isLoading={reservTypeLoading}
    
                    />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{
                            height: "70px",
                            backgroundColor:'#A57939',
                             
                            color: "#fff",
                            fontWeight: "bold",
                            display: "flex", // Ensure flexbox is used
                            flexDirection: "column", // Align content in a column (Book Now on top, €25,00 below)
                            justifyContent: "center", // Center the text vertically
                            alignItems: "center", // Center the text horizontally
                          }}
                          onClick={() => {
                            
                              navigate(`/bookSalon`);
                            
                            
                          }}
                        >
                          {t("main.BtnBookNow")}
                          <Box component="span" fontSize="13px" fontWeight="200" color='#fff'>
                            €250
                          </Box>
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      )
    );
  };
  
  export default SalonWidget;
