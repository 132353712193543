import { Box, InputLabel, TextField, useTheme } from '@mui/material';
import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';

const TextAreaComponent = ({ label = '', name, style = {}, placeText = '', setField, required }) => {

 console.log("name",name)
  const theme=useTheme()
  const [value, setValue] = useState('');

  // Debounced handleChange function
  const debouncedSetField = useCallback(
    debounce((name, newValue) => {
      setField(name, newValue);
    }, 300), // Adjust debounce time as needed
    []
  );

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    debouncedSetField(name, newValue);
  };

  return (
    <Box>
      {label?.trim() !='' && <InputLabel sx={{ mt: 2, mb: 1, fontSize: "10px", color: theme?.details?.formLabelColor }}>
        {label}
      </InputLabel>}
      <TextField
        name={name}
        variant="outlined"
        value={value}
        fullWidth
        placeholder={placeText}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            backgroundColor: '#FDFDFD',
            '& fieldset': {
              borderColor: '#EBEBEB',
            },
            '&:hover fieldset': {
              borderColor: '#EBEBEB',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#0B5753',
            },
            '& input': {
              outline: 'none',
            },
            width: style.width || '100%',
            height: style.height || '150px',
          },
        }}
        multiline
        rows={4}
        onChange={handleChange}
      />
    </Box>
  );
};

export default TextAreaComponent;
