import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Popover, Box, Typography, Grid, MenuItem, Select, TextField, IconButton, InputAdornment, Grid2 } from '@mui/material';
import restype from "../../assets/cycleimg.png";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddIcon from '@mui/icons-material/Add';
import { updateReservationType } from '../../store/slice/Addguest.slice';


const ReservationType = ({ Ref, isOpen, handleClose }) => {
  const { reservType } = useSelector((store) => store?.addGuest);
  const dispatch = useDispatch();

  const reservationTypeDatas = [
    {
      name: 'City bike',
      date: '26-07-2024 to 26-07-2024',
      fromTime: '10:00AM',
      toTime: '06:00PM',
      deposit: '€10,00',
      info: 'Lorem ipsum dolor sit amet consectetur.',
      price: "€100,00",
      img: restype,
    },
    {
      name: 'Mountain bike',
      date: '26-07-2024 to 26-07-2024',
      fromTime: '10:00AM',
      toTime: '06:00PM',
      deposit: '€10',
      info: 'Lorem ipsum dolor sit amet consectetur.',
      price: "€100,00",
      img: restype,
    },
    {
      name: 'Road bike',
      date: '26-07-2024 to 26-07-2024',
      fromTime: '10:00AM',
      toTime: '06:00PM',
      deposit: '€10',
      info: 'Lorem ipsum dolor sit amet consectetur.',
      price: "€100,00",
      img: restype,
    }
  ];

  // Initialize state to handle quantity for each reservation type
  const [quantities, setQuantities] = useState(
    reservationTypeDatas.reduce((acc, item) => ({ ...acc, [item.name]: 1 }), {})
  );

  // To handle switching between select and text input for quantities
  const [customQuantity, setCustomQuantity] = useState({});

  const handleQuantityChange = (value, name) => {
    setQuantities((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCustomQuantityChange = (e, name) => {
    setCustomQuantity((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          width: { xs: '100%', sm: '100%', md: '440px' },
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        {reservationTypeDatas.map((item, i) => (
          <Box
            key={i}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'column', md: 'row' },
              gap: '0.5rem',
              backgroundColor: reservType?.name === item?.name ? '#81D93E' : '#FFFFFF',
              border: '1px solid #EBEBEB',
              p: 1,
              borderRadius: '8px',
              cursor: 'pointer',
              width: '100%',
            }}
            onClick={() => {
              dispatch(updateReservationType(item));
             
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={4}>
                <Box
                  sx={{
                    width: '100%',
                    height: { xs: 'auto', md: '110px' },
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={restype}
                    alt="res-type-img"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Box sx={{ p: 1, width: '100%' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography
                      sx={{
                        color: reservType?.name === item?.name ? '#FEF5D8' : '#000000',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      {item?.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: reservType?.name === item?.name ? '#FEF5D8' : '#000000',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      {item?.price}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: "1rem", alignItems: 'center', mt: 1 }}>
                    <CalendarTodayIcon fontSize="small" sx={{ color: reservType?.name === item?.name ? "#fff" : "#81D93E" }} />
                    <Typography sx={{ fontWeight: "bold", fontSize: "10px", color: reservType?.name === item?.name ? '#fff' : 'black' }}>Date : {item?.date}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: "1rem", alignItems: 'center', mt: 1 }}>
                    <AccessTimeIcon fontSize="small" sx={{ color: reservType?.name === item?.name ? "#fff" : "#81D93E" }} />
                    <Typography sx={{ fontWeight: "bold", fontSize: "10px", color: reservType?.name === item?.name ? '#fff' : 'black' }}>From : {item?.fromTime} To: {item?.toTime}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: "1rem", alignItems: 'center', mt: 1 }}>
                    <AttachMoneyIcon fontSize="small" sx={{ color: reservType?.name === item?.name ? "#fff" : "#81D93E" }} />
                    <Typography sx={{ fontWeight: "bold", fontSize: "10px", color: reservType?.name === item?.name ? '#fff' : 'black' }}>Deposit  <span style={{ color: "#81D93E", marginLeft: '0.6rem' }}>{item?.deposit}</span> </Typography>
                  </Box>

                  {/* Add quantity selection here */}
                  <Grid2 container spacing={2}>
                    <Grid2 size={{md:8}}>
                      <Typography
                        sx={{
                          fontSize: '8px',
                          color: reservType?.name === item?.name ? '#FEF5D8' : '#969BA0',
                          mt: 1,
                        }}
                      >
                        {item?.info}
                      </Typography>
                    </Grid2>
                    <Grid2 size={{md:4}}>
                      {customQuantity[item.name] ? (
                        <TextField
                        fullWidth
                        type="number"
                        sx={{
                          width: "70px",
                          height: "25px",
                          
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "8px", // Add border-radius
                            border: "none", // Remove the border
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none", // Remove the border for outlined variant
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: 1,
                            color: reservType?.name === item?.name ? "black" : "#fff",
                            backgroundColor: reservType?.name === item?.name ? '#fff' : '#81D93E',
                            outline: "none",
                          },
                        }}
                        value={quantities[item.name]}
                        onChange={(e) => handleQuantityChange(e.target.value, item.name)}
                      />
                      
                      ) : (
                        <Select
                        sx={{
                          width: "70px",
                          height: "30px",
                          backgroundColor: reservType?.name === item?.name ? '#fff' : '#81D93E',
                          color: reservType?.name === item?.name ? "black" : "#fff",
                          border: "none", // Removes the border
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none", // Removes the border for outlined variant as well
                          },
                          "& .MuiSelect-icon": {
                            color: reservType?.name === item?.name ? "black" : "#fff", // Changes the color of the dropdown icon to white
                          },
                        }}
                        value={quantities[item.name]}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === 11) {
                            setCustomQuantity((prev) => ({ ...prev, [item.name]: true }));
                          } else {
                            handleQuantityChange(value, item.name);
                          }
                        }}
                        fullWidth
                      >
                        {Array.from({ length: 10 }, (_, i) => (
                          <MenuItem key={i + 1} value={i + 1}>
                            {i + 1}
                          </MenuItem>
                        ))}
                        <MenuItem value={11}>
                          <IconButton size="small">
                            <AddIcon /> More
                          </IconButton>
                        </MenuItem>
                      </Select>
                      )}
                    </Grid2>
                  </Grid2>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default ReservationType;
