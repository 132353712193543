import React from "react";
import { Button, TextField, Grid, InputLabel, useTheme } from "@mui/material";

const NumberInputComponent = ({ label, name, setField, value }) => {
  const theme = useTheme();
  // Handle increment and decrement
  const handleIncrement = () => setField(name, value + 1);
  const handleDecrement = () => setField(name, Math.max(0, value - 1)); // Prevents negative numbers

  return (
    <>
      <InputLabel
        sx={{
          mb: 2,
          fontWeight: "bold",
          fontSize: "15px",
          color: theme?.details?.formLabelColor,
          mt: 1,
        }}
        htmlFor={name} // Changed to use 'name' for accessibility
      >
        {label}
      </InputLabel>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Button variant="outlined" sx={{backgroundColor:'#fff',outline:'none',color:"grey",border:"1px solid grey"}} onClick={handleDecrement} >
            -
          </Button>
        </Grid>
        <Grid item>
          <TextField
            //   label={label}
            value={value}
            name={name}
            InputProps={{ readOnly: true }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item>
          <Button  onClick={handleIncrement} sx={{backgroundColor:'#fff',outline:'none',color:"grey",border:"1px solid grey"}}>
            +
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default NumberInputComponent;
