import axios from "axios"
import apiEndpoints from "../config/apiEndpoints"

export const getLanguageDetails=async(key)=>{
    try {
        const response =await axios.get(apiEndpoints.GET_LANGUAGE(key))
        return response.data; 
    } catch (error) {
        console.error("Error fetching language details", error?.message);
        return null;     
    }
}

export const getWidgetSettings=async(language,key)=>{
    try {
        const response =await axios.get(apiEndpoints.GET_WIDGET_SETTING(language,key))
        return response.data; 
    } catch (error) {
        console.error("Error fetching Widget setting", error?.message);
        return null;     
    }
}


// GET_WIDGET_TITLE_AND_INFO
export const getWidgetTitleandInfo = async (setupID, language) => {
    try {
        const response = await axios.get(apiEndpoints.GET_WIDGET_TITLE_AND_INFO(setupID, language))
        return response.data;
    } catch (error) {
        console.error("Error fetching WIDGET_TITLE_AND_INFO", error?.message);
        return null;
    }
}

export const getWidgetSetUpPosition=async(ID)=>{
    try {
        const response =await axios.get(apiEndpoints.GET_WIDGET_POSITION(ID))
        return response.data; 
    } catch (error) {
        console.error("Error fetching Widget setup position", error?.message);
        return null;     
    }
}

export const getCalenderDatas=async(
    key,
    noOfGuests,
    widgetId,
    reservationRequestMin,
)=>{
    try {
        const response =await axios.get(apiEndpoints.GET_CALENDER_DETAILS(
            key,
            noOfGuests,
            widgetId,
            reservationRequestMin = 0,
        ))
        return response.data; 
    } catch (error) {
        console.error("Error fetching calender details:", error?.message);
        return null;     
    }
}

export const getReservationType=async(data)=>{
    try {
        const response = await axios.post(apiEndpoints.GET_RESERVATION_TYPE, data)
        return response.data; 
    } catch (error) {
        console.error("Error fetching reservation type:", error?.message);
        return null;     
    }
}

export const getReservationGroup=async(data)=>{
    try {
        const response = await axios.post(apiEndpoints.GET_RESERVATION_GROUP_TYPE, data)
        return response.data; 
    } catch (error) {
        console.error("Error fetching reservation type:", error?.message);
        return null;     
    }
}

export const getFreeTables=async(data)=>{
    try {
        const response = await axios.post(apiEndpoints.GET_FREE_TABLES, data)
        return response.data; 
    } catch (error) {
        console.error("Error fetching Free tables data:", error?.message);
        return null;     
    }
}

export const getReservationTypeBasedOnProducts=async(
    key,
    id
)=>{
    try {
        const response =await axios.get(apiEndpoints.GET_PRODUCTS_RESERVATION_TUPE(
            key,
            id
        ))
        return response.data; 
    } catch (error) {
        console.error("Error fetching Product reservation details:", error?.message);
        return null;     
    }
}

export const getGuestDetails=async(key,ID,lang)=>{
    try {
        const response =await axios.get(apiEndpoints.GET_GUEST_DETAILS(key,ID,lang))
        return response.data; 
    } catch (error) {
        console.error("Error fetching guest details", error?.message);
        return null;     
    }
}
export const getExtraFieldDetails=async(resID,lang,key)=>{
    try {
        const response =await axios.get(apiEndpoints.GET_EXTRA_INFO_FIELDS(resID,lang,key));
        if(response?.status == 200){
            return response.data;
        }
        else{
            throw new Error("Failed to fetch extra fields details");
            return null;
        }
    } catch (error) {
        console.error("Error fetching Extra fields details details", error?.message);
        return null;     
    }
}

export const getExtraProductDetails=async(ID,lang)=>{
    try {
        const response = await axios.get(apiEndpoints.GET_EXTRA_PRODUCTS_DETAILS(ID,lang))
        return response.data; 
    } catch (error) {
        console.error("Error fetching extra product details", error?.message);
        return null;     
    }
}

export const transactionCostForReservation=async(key,ID,lang)=>{
    try {
        const response = await axios.get(apiEndpoints.TRANSACTIONCOST_RESTYPE(key,ID,lang))
        return response.data; 
    } catch (error) {
        console.error("Error fetching extra product details", error?.message);
        return null;     
    }
}

export const createReservation=async(data)=>{
    try {
        const response = await axios.post(apiEndpoints.CREATE_RESERVATION,data)
        return response; 
    } catch (error) {
        console.error("Error in creating reservation", error?.message);
        return null;     
    }
}

export const getReservationDetails=async(ID)=>{
    try {
        const response = await axios.get(apiEndpoints.GET_RESERVATION_DETAILS(ID))
        return response.data; 
    } catch (error) {
        console.error("Error fetching reservation details", error?.message);
        return null;     
    }
}

export const cancelReservation=async(key)=>{
    console.log("key in service",key)
    try {
        const response = await axios.delete(apiEndpoints.CANCEL_RESERVATION(key))
        return response; 
    } catch (error) {
        console.error("Error in cancelling the reservation", error?.message);
        return null;     
    }
}

export const fetchCombinationWidget=async(  
    idreservationtype,
    key,
    arrivaldate,
    arrivalTime,
    departureTime,
    totalPeople,
    lang)=>{
    try {
        const response = await axios.get(apiEndpoints.COMBINATION_WIDGET(
            idreservationtype,
            key,
            arrivaldate,
            arrivalTime,
            departureTime,
            totalPeople,
            lang
        ))
        return response.data; 
    } catch (error) {
        console.error("Error fetching extra product details", error?.message);
        return null;     
    }
}
export const GetOpeningHours = async (key) => {
    console.log("key",key)
    if (!key) {
        console.error("Key is required to fetch opening hours.");
        return null;
    }

    try {
        const response = await axios.get(apiEndpoints.GET_OPENING_HOURS(key));
        if(response?.status == 200){
            return response.data;
        }    
    } catch (error) {
        console.error("Error fetching opening hours:", error.response ? error.response.data : error.message);
        return null; // Return null in case of an error
    }
};

export const FetchWidgetConfigDetails=async(key,id,lang)=>{
    try {
        const response=await axios.get(apiEndpoints.GET_WIDGETS_CONFIG(key,id,lang))
        if(response?.status == 200){
            return response.data;
        }
        
    } catch (error) {
        console.error("Error fetching widget config details:", error.response ? error.response.data : error.message);
        return null; // Return null in case of an error
        
    }
}

export const getReservationPdf = async (key, secretkey) => {
    try {
        const response = await axios.get(apiEndpoints.GET_RESERVATION_PDF(key, encodeURIComponent(secretkey)), {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        // Check if the response contains the expected base64 PDF data (with data URL format)
        if (response?.status === 200 && response?.data) {
            const pdfData = response.data?.pdf;

            // Check if it's a valid data URL for PDF (starts with "data:application/pdf;base64,")
            if (pdfData.startsWith('data:application/pdf;base64,')) {
                // Strip the prefix and return just the base64 string
                const base64String = pdfData.split(',')[1];
                return {pdf:base64String,reservationNumber:response?.data?.reservationNumber};
            } else {
                console.error('Unexpected PDF format:', pdfData);
                return null;
            }
        } else {
            console.error('Unexpected response format or missing data:', response);
            return null;
        }
    } catch (error) {
        console.error('Error fetching reservation pdf:', error?.message || error);
        return null;
    }
};






