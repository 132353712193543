import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid2,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import clock from "../../assets/clock.png";
import user from "../../assets/users.png";
import { cancelReservation, getReservationDetails, getWidgetSettings } from "../../api/HorecaWidget.service";
import { useDispatch, useSelector } from "react-redux";
import { setReservationDetail } from "../../store/slice/Addguest.slice";
import {useNavigate, useParams} from 'react-router-dom'
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { updateWidgetSetting } from "../../store/slice/widgetSetting.slice";

const CancelReservation = () => {
    const { key } = useParams(); // Retrieve the key from the URL
    const navigate=useNavigate()
    const [isLoading,setIsLoading]=useState()
    const {ReservationDetail}=useSelector((store)=>store?.addGuest)
    const {
      WidgetSetting,
  } = useSelector((store) => store?.widgetSettings);
   const [isChecked,setIsChecked]=useState(true)
    const DATE= dayjs(ReservationDetail?.arrivalDate).format('DD-MM-YYYY');
    const originalDate = dayjs(DATE, 'DD-MM-YYYY');
    console.log("original date",originalDate)

    const formattedDate = originalDate?.format('Do [of] MMMM YYYY');
    const dispatch=useDispatch()
    const fetchReservation=async()=>{
        try {
            const response =await getReservationDetails(key)
            dispatch(setReservationDetail(response))
            console.log(response)
        } catch (error) {
            console.error("Error fetching reservation details:", error?.message);
            
        }
    }
    const fetchWidgetSettings = async (language) => {
      try {
          const response = await getWidgetSettings(language);
        //  loadSettings = response;
          dispatch(updateWidgetSetting(response))
      } catch (error) {
        console.error("Error fetching widget settings:", error?.message);
      }
      };

    const handleCancelReservation=async()=>{
      setIsLoading(true)

        try {
            const response =await cancelReservation(key)
            if(response?.status == 200 ){
              navigate('/ReservationCancelSuccess')
            }
            else{
              alert("Error canceling reservation")
            }
            setIsLoading(false)
            
        } catch (error) {
            console.error("Error canceling reservation:", error?.message);     
            setIsLoading(false) 
        }
    }

    useEffect(()=>{
        fetchReservation()
        fetchWidgetSettings("en")
    },[])

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        m: 2,
      }}
    >
      <Paper
        elevation={1}
        sx={{
          backgroundColor: "#fff",
          px: { md: 2, sm: 1, xs: 1 },
          pt: 5,
          py: 3,
          width: { md: 1197, sm: "100%", xs: "100%" },
          height: "auto",
        }}
      >
        <Box>
          <Typography
            sx={{ color: "#0B5753", fontWeight: "medium", fontSize: "28px" }}
          >
            Cancel Reservation
          </Typography>
          <Typography
            sx={{ color: "#969BA0", fontWeight: "regular", fontSize: "16px" }}
          >
            Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc
            consectetur eget erat nec. Lorem ipsum dolor sit amet consectetur.
            Molestie eu massa nunc consectetur eget erat nec.
          </Typography>
        </Box>
        <Grid2 container spacing={2} mt={2}>
          <Grid2 size={{ md: 6, sm: 12 }}>
            <Box
              sx={{
                minHeight: "210px",
                border: "1px dashed #0B5753",
                backgroundColor: "#F7FFFF",
                borderRadius: "16px",
                p: 2,
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#0B5753",
                  fontSize: { md: "24px", sm: "20px", xs: "16px" },
                  fontWeight: "medium",
                }}
              >
                Your Booking
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "medium",
                    fontSize: { md: "22px", sm: "19px", xs: "16px" },
                  }}
                >
                 {WidgetSetting?.find((item) => item?.tag == 'companyName')?.data}
                </Typography>
                <Typography sx={{ color: "#969BA0", fontSize: "10px" }}>
                {WidgetSetting?.find((item) => item?.tag == 'companyAddress')?.data}, {WidgetSetting?.find((item) => item?.tag == 'companyCity')?.data}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  mt: 4,
                  mb: 2,
                }}
              >
                <Box sx={{ width: "24px", height: "24px" }}>
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={clock}
                    alt="time"
                  />
                </Box>
                <Typography sx={{ color: "#969BA0", fontSize: "14px" }}>
                 {formattedDate} {ReservationDetail?.arrivalTime?.substring(0, 5)}
                </Typography>
                <Box
                  sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <Box sx={{ width: "24px", height: "24px" }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={user}
                      alt="time"
                    />
                  </Box>
                  <Typography sx={{ color: "#969BA0", fz: "14px" }}>
                  {ReservationDetail?.adults > 0 && `${ReservationDetail?.adults} Adult`}
      {ReservationDetail?.kid > 0 && `, ${ReservationDetail?.kid} Kid`}
      {ReservationDetail?.child > 0 && `, ${ReservationDetail?.child} Child`}
      {ReservationDetail?.toddler > 0  && `, ${ReservationDetail?.toddler} Toddler`}
      {ReservationDetail?.baby > 0  && `, ${ReservationDetail?.baby} Baby`}

                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 4,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#0B5753",
                    fontWeight: "bold",
                  }}
                >
                 {ReservationDetail?.reservationType?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                                  € {parseFloat(ReservationDetail?.reservationType?.maxamount).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Grid2>
          <Grid2 size={{ md: 6, sm: 12 }}>
            <Box
              sx={{
                width: "100%",
                minHeight: "240px",
                border: "1px dashed #0B5753",
                backgroundColor: "#F7FFFF",
                borderRadius: "16px",

                p: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#0B5753",
                  fontWeight: "medium",
                  fontSize: { md: "24px", sm: "20px", xs: "16px" },
                }}
              >
                Payment
              </Typography>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                                      € {parseFloat((ReservationDetail?.reservationType?.maxamount / 121) * 100).toFixed(2)} 
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    VAT 21%
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                                      € {parseFloat((ReservationDetail?.reservationType?.maxamount/121)*21).toFixed(2)} 
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderTop: "1px solid #969BA0",
                    display: "flex",
                    justifyContent: "space-between",
                    pt: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#0B5753",
                      fontWeight: "medium",
                    }}
                  >
                    Total Including VAT
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: "#0B5753",
                      fontWeight: "medium",
                    }}
                  >
                                      € {parseFloat(ReservationDetail?.reservationType?.maxamount ).toFixed(2)} 
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid2>
        </Grid2>
        <Grid2 container spacing={2} mt={3}>
          <Grid2 size={{ md: 6, sm: 12 }}>
            <Box
              sx={{
                width: "100%",
                minHeight: "210px",
                border: "1px dashed #EBEBEB",
                backgroundColor: "#FDFDFD",
                borderRadius: "16px",
                p: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: "28px",
                  fontWeight: "medium",
                }}
              >
                Your Details
              </Typography>
              <Box sx={{ mt: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "medium",
                    }}
                  >
                    Name
                  </Typography>
                  <Typography
                    sx={{
                      color: "#969BA0",
                      fontSize: "15px",
                      fontWeight: "regular",
                    }}
                  >
                  {ReservationDetail?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "medium",
                    }}
                  >
                    Email
                  </Typography>
                  <Typography
                    sx={{
                      color: "#969BA0",
                      fontSize: "15px",
                      fontWeight: "regular",
                    }}
                  >
                    {ReservationDetail?.email}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "2rem", alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontSize: "16px",
                      fontWeight: "medium",
                    }}
                  >
                    Phone
                  </Typography>
                  <Typography
                    sx={{
                      color: "#969BA0",
                      fontSize: "15px",
                      fontWeight: "regular",
                    }}
                  >
                    {ReservationDetail?.phone}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid2>
          <Grid2 size={{ md: 6, sm: 12 }}>
            <Typography
              sx={{
                color: "#000000",
                fontSize: "20px",
                fontWeight: "medium",
                mb: 1,
              }}
            >
              Reason for Cancellation
            </Typography>
            <TextField
              name={"reason"}
              variant="outlined"
              value={""} // Controlled input value
              fullWidth
              placeholder={"reason ... "}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  backgroundColor: "#FDFDFD",
                  "& fieldset": {
                    borderColor: "#EBEBEB",
                  },
                  "&:hover fieldset": {
                    borderColor: "#EBEBEB",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#0B5753",
                  },
                  "& input": {
                    outline: "none",
                  },
                  width: "100%",
                  height: "150px",
                },
              }}
              multiline
              rows={4}
              // onChange={handleChange} // Update local state and dispatch action
            />
          </Grid2>
        </Grid2>
        <Grid2 container sx={{ mt: 2 }}>
          <Grid2 size={{ md: 10 }}>
          <FormControlLabel
            control={<Checkbox size="small" name='isChecked' value={isChecked} onChange={()=>setIsChecked(!isChecked)} checked={isChecked}/>}
            label={
              <Typography
              sx={{ color: "#969BA0", fontWeight: "regular", fontSize: "16px" }}
            >
              Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc
              consectetur eget erat nec. Lorem ipsum dolor sit amet consectetur.
              Molestie eu massa nunc consectetur eget erat nec.
            </Typography>
            }
          />
       
          </Grid2>
          <Grid2 size={{ md: 2, sm: 12 }}>
            <Button
              sx={{ backgroundColor: isChecked ? "#0B5753" : 'lightgrey', color:isChecked ? "#FFFFFF" : 'grey', py: 1 }}
              onClick={handleCancelReservation}
              disabled={!isChecked}
            >
              {  
                isLoading ? <CircularProgress 
                size={24} // Adjust size in pixels (example: 24px)
                sx={{ color: "#fff" }} // Custom color for loader
              />
              :'Cancel Reservation'
              }
             
            </Button>
          </Grid2>
        </Grid2>
      </Paper>
    </Box>
  );
};

export default CancelReservation;
